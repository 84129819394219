import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './registerServiceWorker'
import { TroisJSVuePlugin } from 'troisjs';

import mobileApp from './mobileApp/App.vue'
import mobileRouter from './mobileApp/router'

import desktopApp from './desktopApp/App.vue'
import desktopRouter from './desktopApp/router'

import AvatarContainer from './desktopApp/components/uikit/Avatar/AvatarContainer.vue'

import FormBlock from './desktopApp/components/uikit/Block/FormBlock.vue'

import ButtonComponent from './desktopApp/components/uikit/Button/ButtonComponent.vue'
import IconButton from './desktopApp/components/uikit/Button/IconButton.vue'
import AddButton from './desktopApp/components/uikit/Button/EventButton/AddButton.vue'
import RemoveButton from './desktopApp/components/uikit/Button/EventButton/RemoveButton.vue'

import DoubleInput from './desktopApp/components/uikit/Input/DoubleInput.vue'
import InputComponent from './desktopApp/components/uikit/Input/InputComponent.vue'
import InputHorizontal from './desktopApp/components/uikit/Input/InputHorizontal.vue'
import InputUpload from './desktopApp/components/uikit/Input/InputUpload.vue'
import InputUploadOnlyFile from './desktopApp/components/uikit/Input/InputUploadOnlyFile.vue'

import TableBody from './desktopApp/components/uikit/TableComponent/table/TableBody.vue'
import TableCell from './desktopApp/components/uikit/TableComponent/table/TableCell.vue'
import TableComponent from './desktopApp/components/uikit/TableComponent/table/TableComponent.vue'
import TableFooter from './desktopApp/components/uikit/TableComponent/table/TableFooter.vue'
import TableHeader from './desktopApp/components/uikit/TableComponent/table/TableHeader.vue'
import TableHeaderCell from './desktopApp/components/uikit/TableComponent/table/TableHeaderCell.vue'
import TableHeaderRow from './desktopApp/components/uikit/TableComponent/table/TableHeaderRow.vue'
import TableRow from './desktopApp/components/uikit/TableComponent/table/TableRow.vue'
import TableWrapper from './desktopApp/components/uikit/TableComponent/table/TableWrapper.vue'

import CurrencyComponent from './desktopApp/components/uikit/Text/CurrencyComponent.vue'
import DateComponent from './desktopApp/components/uikit/Text/DateComponent.vue'
import InfoBlock from './desktopApp/components/uikit/Text/InfoBlock.vue'
import InfoLine from './desktopApp/components/uikit/Text/InfoLine.vue'
import InfoRow from './desktopApp/components/uikit/Text/InfoRow.vue'
import VerticalTextBlock from './desktopApp/components/uikit/Text/VerticalTextBlock.vue'

import SelectComponent from './desktopApp/components/uikit/Select/SelectComponent.vue'

import RadarChart from './desktopApp/components/charts/RadarChart.vue'

import StarsComponent from './desktopApp/components/uikit/Indicators/StarsComponent.vue'

import IphoneSwitch from './desktopApp/components/uikit/Switch/IphoneSwitch.vue'

import TextArea from './desktopApp/components/uikit/TextArea/TextArea.vue'

const pinia = createPinia()

if (window.innerWidth < 968) {
    const app = createApp(mobileApp)
    app.use(mobileRouter).use(pinia).mount('#app')
} else {
    const app = createApp(desktopApp)

    app.component('AvatarContainer', AvatarContainer)
    app.component('FormBlock', FormBlock)
    app.component('ButtonComponent', ButtonComponent)
    app.component('IconButton', IconButton)
    app.component('AddButton', AddButton)
    app.component('RemoveButton', RemoveButton)
    app.component('DoubleInput', DoubleInput)
    app.component('InputComponent', InputComponent)
    app.component('InputHorizontal', InputHorizontal)
    app.component('InputUpload', InputUpload)
    app.component('TableBody', TableBody)
    app.component('TableCell', TableCell)
    app.component('TableComponent', TableComponent)
    app.component('TableFooter', TableFooter)
    app.component('TableHeader', TableHeader)
    app.component('TableHeaderCell', TableHeaderCell)
    app.component('TableHeaderRow', TableHeaderRow)
    app.component('TableRow', TableRow)
    app.component('TableWrapper', TableWrapper)
    app.component('CurrencyComponent', CurrencyComponent)
    app.component('DateComponent', DateComponent)
    app.component('InfoBlock', InfoBlock)
    app.component('InfoLine', InfoLine)
    app.component('InfoRow', InfoRow)
    app.component('VerticalTextBlock', VerticalTextBlock)
    app.component('StarsComponent', StarsComponent)
    app.component('RadarChart', RadarChart)
    app.component('SelectComponent', SelectComponent)
    app.component('IphoneSwitch', IphoneSwitch)
    app.component('InputUploadOnlyFile', InputUploadOnlyFile)
    app.component('TextArea', TextArea)

    app.use(desktopRouter).use(pinia).use(TroisJSVuePlugin).mount('#app')
}

