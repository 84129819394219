<template>
    <div class="absolute h-[120%] w-full top-0 z-[999] bg-[#252526]">
        <div @click="$emit('close')" class="flex text-[#BD9135] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>
        <div class="text-white font-bold text-center text-[21px] px-[10px]">Додавання конспекту до тренування на {{dateFormatted}}</div>
        
        <div v-if="!isProgramToViewVisible" class=" mt-5 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-[94%] ">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 w-[5%] py-2 text-center font-bold text-sm"></th>
                        <th class="px-3 py-2 text-start font-bold text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="program in programs" @click="() => { programToView = program; isProgramToViewVisible = true }" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <td class="px-3 py-2 text-center font-medium text-sm">
                            <div class="rounded-full border-[1px] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                <span>{{ program.name[0] }}</span>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start font-medium text-sm">{{ program.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <TrainingProgramView v-else @addSummary="summary => $emit('addSummary', summary)" :program="programToView" @close="isProgramToViewVisible = false" />
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import TrainingProgramView from './TrainingProgramView.vue'

    export default {
        name: 'add-summary-to-training-form',

        props: {
            date: {
                type: Date,
                required: true
            },
            programs: {
                type: Array,
                required: true
            }
        },

        setup(props, { emit }) {
            const dateFormatted = ref(props.date.split('-').reverse().join('.'))
            const allTrainingProgram = ref([])

            const screenWidth = window.innerWidth

            const programToView = ref({})
            const isProgramToViewVisible = ref(false)
            const setProgramToView = (program) => {
                console.log(program)
                programToView.value = program
                isProgramToViewVisible.value = true
            }

            onMounted(() => {
                console.log(dateFormatted.value)
            })

            return {
                dateFormatted,
                allTrainingProgram,
                setProgramToView,
                programToView,
                isProgramToViewVisible,
                screenWidth
            }
        },

        components: {
            TrainingProgramView
        }
    }
</script>

<style lang="scss" scoped>

</style>