<template>
    <div class="pb-[15px]">
        <div>
            <div @click="$emit('close')" class="flex text-[#BD9135] items-center ml-3 mt-2">
                <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                <div class="ml-2">
                    Назад
                </div>
            </div>
        </div>

        <div class="pb-[12px] border-b-[1px] border-[#BD9135] mt-[10px]">
            <div class="text-[#BD9135] font-bold text-center">{{ selectedSummary.name }}</div>
            <!-- <div class="text-[#BD9135] text-center">#18 тренування</div> -->
        </div>

        <div v-if="startedTraining" class="rounded-lg px-[10px] w-full h-[20px] flex overflow-hidden mt-[20px]">
            <div 
                v-if="startedTraining"
                v-for="el in elements" 
                class="h-full px-[5px] text-center text-xs truncate" 
                :style="{width: el.width + '%'}"
                :class="{ 'text-white': currentElementIndex === elements.indexOf(el) && startedTraining, 'text-gray-400 opacity-30': currentElementIndex !== elements.indexOf(el) || !startedTraining }"    
            >{{ el.name }}</div>
        </div>
        <div v-if="!startedTraining" class="rounded-lg px-[10px] w-full h-[20px] flex overflow-hidden mt-[20px]">
            <div 
                v-if="!startedTraining"
                v-for="el in elements" 
                class="h-full px-[5px] text-center text-xs truncate" 
                :style="{width: el.width + '%'}"
                :class="{ 'text-white': currentElementIndexForView === elements.indexOf(el), 'text-gray-400 opacity-30': currentElementIndexForView !== elements.indexOf(el) }"    
            >{{ el.name }}</div>
        </div>

        <div class="rounded-lg w-[95%] mx-auto h-[20px] relative flex overflow-hidden mt-[20px]">
            <div class="w-[0] h-[120%] absolute bg-[#DA4167]" :style="{width: trainingProccessProcent + '%'}"></div>
            <div v-for="el in elements" class="bg-[#BD9135] h-full border-r-[1px] border-[#000]" :style="{width: el.width + '%'}"></div>
        </div>

        <div class="rounded-lg px-[10px] w-full h-[20px] items-center flex overflow-hidden mt-[20px]">
            <div class="h-[1px] w-[33%] bg-gray-400"></div>
            <div class="h-full w-[33%] mt-[10px] text-gray-400 text-center text-xs truncate">{{ parsedDuration }}</div>
            <div class="h-[1px] w-[33%] bg-gray-400"></div>
        </div>


        <div v-if="startedTraining" class="px-[10px]">
            <StandardCarryingForm 
                class="z-[9999]" 
                v-if="standardCarryingFlag" 
                @close="standardCarryingFlag = false" 
                @startTesting="e => setCurrentStudentTesting(e)" 
                @restartTesting="e => setCurrentStudentRestartTesting(e)"
                :standard="standardCarryingObject"  
                :players="currentStandartPlayers"
            />
            <StudentStandartCarrying 
                class="z-[99999]" 
                v-if="currentStudentTestingFlag" 
                @close="currentStudentTestingFlag = false" 
                :student="currentStudentTestingObject"
                :standard="standardCarryingObject"
                @tested="e => setStudentTested(e)"      
            />
            <UpdateStudentStandartCarrying 
                class="z-[99999]" 
                v-if="updateStudentTestingFlag" 
                @close="updateStudentTestingFlag = false" 
                :student="currentStudentTestingObject"
                :standard="standardCarryingObject"
                :previousResult="previuosTestValue"
                :previuosTestObject="previuosTestObject"
                @update="e => updateStudentTested(e)"
            />
            <div v-else class="mt-[20px] px-[10px]">
                <div class="mx-auto border-[#000] border-opacity-10 bg-[#47484966] py-[10px] mt-[15px] rounded-[10px]">
                    <div class="font-bold text-white uppercase text-center">{{ elements[currentElementIndex].name }}</div>
                    <button v-if="!showElementInfoFull" @click="showElementInfoFull = true" class="bg-[#BD9135] mt-3 mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                        <div class="w-full text-[#252526] font-semibold">Розгорнути</div>

                        <svg width="8" height="10" transform="rotate(90)" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#252526"/>
                        </svg>
                    </button>

                    <div v-if="showElementInfoFull" class="text-white mt-3 px-[15px] mb-[10px]">
                    
                        <div>
                            {{ elements[currentElementIndex].info }}
                        </div>

                        <div v-if="elements[currentElementIndex].approaches_number" class="mt-[10px] flex">
                            <div class="font-bold text-[#BD9135] mr-[10px]">Кількість підходів:</div>
                            <div>{{elements[currentElementIndex].approaches_number}}</div>
                        </div>
                        <div v-if="elements[currentElementIndex].approaches_break" class="mt-[10px] flex">
                            <div class="font-bold text-[#BD9135] mr-[10px]">Кількість підходів:</div>
                            <div>{{elements[currentElementIndex].approaches_break}} хв.</div>
                        </div>
                    
                        <div class="w-[100%] mt-[15px]">
                            <div v-if="elements[currentElementIndex].additional_images.length > 0">
                                <div class="font-bold text-[#BD9135]">Ілюстрації</div>
                                <carousel :items-to-show="1">
                                    <slide v-for="slide in elements[currentElementIndex].additional_images" :key="slide">
                                        <img class="w-full" :src="apiURL + 'static/' + slide" alt="">
                                    </slide>
                                </carousel>
                            </div>
                            <div v-if="elements[currentElementIndex].schemas.length > 0" class="mt-[35px]">
                                <div class="font-bold text-[#BD9135]">Схеми</div>
                                <carousel :items-to-show="1">
                                    <slide v-for="slide in elements[currentElementIndex].schemas" :key="slide">
                                        <img class="w-full" :src="apiURL + 'static/' + slide + '.svg'" alt="">
                                    </slide>
                                </carousel>
                            </div>
                            <div v-if="elements[currentElementIndex].additional_videos.length > 0" class="mt-[35px]">
                                <div class="font-bold text-[#BD9135]">Відео</div>
                                <div class="flex mt-[15px] justify-center">
                                    <carousel :items-to-show="1">
                                        <slide v-for="slide in elements[currentElementIndex].additional_videos" :key="slide">
                                            <div class="w-full flex justify-center">
                                                <YouTube 
                                                    :width = "'100%'" 
                                                    :src="slide" 
                                                    @ready="onReady"
                                                    ref="youtube" 
                                                />
                                            </div>
                                        </slide>
                                    </carousel>
                                </div>
                            </div>
                        </div>


                        <button @click="startStandardCarrying(elements[currentElementIndex])" class="bg-[#339989] mt-5 mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                            <div class="w-full text-[#FFF] font-semibold">Старт тестування</div>

                            <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#FFF"/>
                            </svg>
                        </button>

                        <button v-if="showElementInfoFull" @click="showElementInfoFull = false" class="bg-[#BD9135] mt-5 mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                            <div class="w-full text-[#252526] font-semibold">Згорнути</div>

                            <svg width="8" height="10" transform="rotate(-90)" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#252526"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="mt-[20px] px-[10px]">
                <div class="font-bold text-white uppercase text-center">Керувати тренуванняМ</div>
                <div class="text-white text-xs text-center px-[10px]">(додати час до поточного елементу, перейти до наступного, завершити тренування)</div>
    
                <div class="mx-auto border-[#000] border-opacity-10 bg-[#47484966] py-[10px] mt-[15px] rounded-[10px]">
                    <div class="text-[11px] text-center text-[#8C8C8C]">додати час до поточного елементу</div>
    
                    <div class="mt-[10px] flex justify-evenly">
                        <div class="flex flex-col items-center justify-center">
                            <div @click="addOneMinuteToElement()">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="24.6154" fill="#339989" stroke="#474849" stroke-width="2.76923"/>
                                <path d="M26 10L26 42" stroke="white" stroke-width="5.53846"/>
                                <line x1="10" y1="26.2308" x2="42" y2="26.2308" stroke="white" stroke-width="5.53846"/>
                                </svg>
                            </div>
                            <div class="text-[#8C8C8C]">1 хв</div>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <div @click="addFiveMinuteToElement()">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16.6165" cy="16.6165" r="15.7316" fill="#339989" fill-opacity="0.3" stroke="#339989" stroke-width="1.76981"/>
                                <path d="M16.6167 6.39099L16.6167 26.8421" stroke="white" stroke-width="3.53962"/>
                                <line x1="6.39111" y1="16.764" x2="26.8422" y2="16.764" stroke="white" stroke-width="3.53962"/>
                                </svg>
                            </div>
                            <div class="text-[#8C8C8C]">5 хв</div>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <div @click="addTenMinuteToElement()">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16.6165" cy="16.6165" r="15.7316" fill="#339989" fill-opacity="0.3" stroke="#339989" stroke-width="1.76981"/>
                                <path d="M16.6167 6.39099L16.6167 26.8421" stroke="white" stroke-width="3.53962"/>
                                <line x1="6.39111" y1="16.764" x2="26.8422" y2="16.764" stroke="white" stroke-width="3.53962"/>
                                </svg>
                            </div>
                            <div class="text-[#8C8C8C]">10 хв</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-[20px] px-[10px]">
                <div class="mx-auto border-[#000] border-opacity-10 bg-[#47484966] py-[10px] mt-[15px] rounded-[10px]">
    
                    <div class="mt-[10px] flex justify-evenly">
                        <div class="flex flex-col items-center justify-center">
                            <div @click="prevElement">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="24.6154" transform="rotate(-180 26 26)" fill="#1B4079" stroke="#474849" stroke-width="2.76923"/>
                                <path d="M21.2002 31.6C21.2002 33.2971 21.2002 34.1456 20.673 34.6728C20.1458 35.2 19.2973 35.2 17.6002 35.2C15.9031 35.2 15.0546 35.2 14.5274 34.6728C14.0002 34.1456 14.0002 33.2971 14.0002 31.6L14.0002 19.6C14.0002 17.903 14.0002 17.0544 14.5274 16.5272C15.0546 16 15.9031 16 17.6002 16C19.2973 16 20.1458 16 20.673 16.5272C21.2002 17.0544 21.2002 17.903 21.2002 19.6L21.2002 31.6Z" fill="white" stroke="white" stroke-width="1.5"/>
                                <path d="M21.3313 24.5172C21.7554 22.7981 23.76 21.5833 27.7691 19.1538C31.6447 16.8052 33.5826 15.6309 35.1442 16.1029C35.7898 16.2981 36.3781 16.6687 36.8525 17.1793C38 18.4142 38 20.8095 38 25.6C38 30.3905 38 32.7858 36.8525 34.0207C36.3781 34.5313 35.7898 34.9019 35.1442 35.0971C33.5826 35.5691 31.6447 34.3948 27.7691 32.0462C23.76 29.6167 21.7554 28.4019 21.3313 26.6829C21.1562 25.9733 21.1562 25.2267 21.3313 24.5172Z" fill="white" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                                </svg>    
                            </div>
                            <div class="text-[#8C8C8C] text-center text-xs">попередній<br>елемент</div>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <div @click="nextElement">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="24.6154" fill="#1B4079" stroke="#474849" stroke-width="2.76923"/>
                                <path d="M30.8 19.6C30.8 17.9029 30.8 17.0544 31.3273 16.5272C31.8545 16 32.703 16 34.4 16C36.0971 16 36.9456 16 37.4728 16.5272C38 17.0544 38 17.9029 38 19.6V31.6C38 33.2971 38 34.1456 37.4728 34.6728C36.9456 35.2 36.0971 35.2 34.4 35.2C32.703 35.2 31.8545 35.2 31.3273 34.6728C30.8 34.1456 30.8 33.2971 30.8 31.6V19.6Z" fill="white" stroke="white" stroke-width="1.5"/>
                                <path d="M30.6687 26.6828C30.2446 28.4019 28.24 29.6167 24.2309 32.0462C20.3553 34.3948 18.4174 35.5691 16.8558 35.0971C16.2102 34.9019 15.6219 34.5313 15.1475 34.0207C14 32.7858 14 30.3905 14 25.6C14 20.8095 14 18.4142 15.1475 17.1793C15.6219 16.6687 16.2102 16.2981 16.8558 16.1029C18.4174 15.6309 20.3553 16.8052 24.2309 19.1538C28.24 21.5833 30.2446 22.7981 30.6687 24.5172C30.8438 25.2267 30.8438 25.9733 30.6687 26.6828Z" fill="white" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                                </svg>
    
                            </div>
                            <div class="text-[#8C8C8C] text-center text-xs">наступний<br>елемент</div>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <div @click="stopTraining">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="24.6154" fill="#DA4167" stroke="#474849" stroke-width="2.76923"/>
                                <path d="M15 19.125V32.875C15 34.8195 15 35.7918 15.6041 36.3959C16.2082 37 17.1805 37 19.125 37H32.875C34.8195 37 35.7918 37 36.3959 36.3959C37 35.7918 37 34.8195 37 32.875V19.125C37 17.1805 37 16.2082 36.3959 15.6041C35.7918 15 34.8195 15 32.875 15H19.125C17.1805 15 16.2082 15 15.6041 15.6041C15 16.2082 15 17.1805 15 19.125Z" fill="white" stroke="white" stroke-width="2.15625"/>
                                </svg>
                            </div>
                            <div class="text-[#DA4167] text-center text-xs">завершити<br>тренування</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="!endTrainingFlag" class="mt-[45px]">
            <button @click="startTraining" class="bg-[#339989] mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                <div class="w-full text-white font-semibold">Розпочати тренування</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#fff"/>
                </svg>
            </button>

            <div class="mt-[20px] px-[10px]">
                <div class="mx-auto border-[#000] border-opacity-10 bg-[#47484966] py-[10px] mt-[15px] rounded-[10px]">
                    <div class="font-bold text-white uppercase text-center">{{ elements[currentElementIndexForView].name }}</div>
                    <button v-if="!showElementInfoFull" @click="showElementInfoFull = true" class="bg-[#BD9135] mt-3 mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                        <div class="w-full text-[#252526] font-semibold">Розгорнути</div>

                        <svg width="8" height="10" transform="rotate(90)" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#252526"/>
                        </svg>
                    </button>

                    <div v-if="showElementInfoFull" class="text-white mt-3 px-[15px] mb-[10px]">
                    
                        <div>
                            {{ elements[currentElementIndexForView].info }}
                        </div>

                        <div v-if="elements[currentElementIndex].approaches_number" class="mt-[10px] flex">
                            <div class="font-bold text-[#BD9135] mr-[10px]">Кількість підходів:</div>
                            <div>{{elements[currentElementIndex].approaches_number}}</div>
                        </div>
                        <div v-if="elements[currentElementIndex].approaches_break" class="mt-[10px] flex">
                            <div class="font-bold text-[#BD9135] mr-[10px]">Кількість підходів:</div>
                            <div>{{elements[currentElementIndex].approaches_break}} хв.</div>
                        </div>
                    
                        <div class="w-[100%] mt-[20px]">
                            <div v-if="elements[currentElementIndexForView].additional_images.length > 0">
                                <div class="font-bold text-[#BD9135]">Ілюстрації</div>
                                <carousel :items-to-show="1">
                                    <slide v-for="slide in elements[currentElementIndexForView].additional_images" :key="slide">
                                        <img class="w-full" :src="apiURL + 'static/' + slide" alt="">
                                    </slide>
                                </carousel>
                            </div>
                            <div v-if="elements[currentElementIndexForView].schemas.length > 0" class="mt-[35px]">
                                <div class="font-bold text-[#BD9135]">Схеми</div>
                                <carousel :items-to-show="1">
                                    <slide v-for="slide in elements[currentElementIndexForView].schemas" :key="slide">
                                        <img class="w-full" :src="apiURL + 'static/' + slide + '.svg'" alt="">
                                    </slide>
                                </carousel>
                            </div>
                            <div v-if="elements[currentElementIndexForView].additional_videos.length > 0" class="mt-[35px]">
                                <div class="font-bold text-[#BD9135]">Відео</div>
                                <div class="flex mt-[15px] justify-center">
                                    <carousel :items-to-show="1">
                                        <slide v-for="slide in elements[currentElementIndexForView].additional_videos" :key="slide">
                                            <div class="w-full flex justify-center">
                                                <YouTube 
                                                    :width = "'100%'" 
                                                    :src="slide" 
                                                    @ready="onReady"
                                                    ref="youtube" 
                                                />
                                            </div>
                                        </slide>
                                    </carousel>
                                </div>
                            </div>
                        </div>

                        <button v-if="showElementInfoFull" @click="showElementInfoFull = false" class="bg-[#BD9135] mt-5 mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                            <div class="w-full text-[#252526] font-semibold">Згорнути</div>

                            <svg width="8" height="10" transform="rotate(-90)" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#252526"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="mt-[20px] px-[10px]">
                <div class="mx-auto border-[#000] border-opacity-10 bg-[#47484966] py-[10px] mt-[15px] rounded-[10px]">
    
                    <div class="mt-[10px] flex justify-evenly">
                        <div class="flex flex-col items-center justify-center">
                            <div @click="prevElementToView">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="24.6154" transform="rotate(-180 26 26)" fill="#1B4079" stroke="#474849" stroke-width="2.76923"/>
                                <path d="M21.2002 31.6C21.2002 33.2971 21.2002 34.1456 20.673 34.6728C20.1458 35.2 19.2973 35.2 17.6002 35.2C15.9031 35.2 15.0546 35.2 14.5274 34.6728C14.0002 34.1456 14.0002 33.2971 14.0002 31.6L14.0002 19.6C14.0002 17.903 14.0002 17.0544 14.5274 16.5272C15.0546 16 15.9031 16 17.6002 16C19.2973 16 20.1458 16 20.673 16.5272C21.2002 17.0544 21.2002 17.903 21.2002 19.6L21.2002 31.6Z" fill="white" stroke="white" stroke-width="1.5"/>
                                <path d="M21.3313 24.5172C21.7554 22.7981 23.76 21.5833 27.7691 19.1538C31.6447 16.8052 33.5826 15.6309 35.1442 16.1029C35.7898 16.2981 36.3781 16.6687 36.8525 17.1793C38 18.4142 38 20.8095 38 25.6C38 30.3905 38 32.7858 36.8525 34.0207C36.3781 34.5313 35.7898 34.9019 35.1442 35.0971C33.5826 35.5691 31.6447 34.3948 27.7691 32.0462C23.76 29.6167 21.7554 28.4019 21.3313 26.6829C21.1562 25.9733 21.1562 25.2267 21.3313 24.5172Z" fill="white" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                                </svg>    
                            </div>
                            <div class="text-[#8C8C8C] text-center text-xs">попередній<br>елемент</div>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <div @click="nextElementToView">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="24.6154" fill="#1B4079" stroke="#474849" stroke-width="2.76923"/>
                                <path d="M30.8 19.6C30.8 17.9029 30.8 17.0544 31.3273 16.5272C31.8545 16 32.703 16 34.4 16C36.0971 16 36.9456 16 37.4728 16.5272C38 17.0544 38 17.9029 38 19.6V31.6C38 33.2971 38 34.1456 37.4728 34.6728C36.9456 35.2 36.0971 35.2 34.4 35.2C32.703 35.2 31.8545 35.2 31.3273 34.6728C30.8 34.1456 30.8 33.2971 30.8 31.6V19.6Z" fill="white" stroke="white" stroke-width="1.5"/>
                                <path d="M30.6687 26.6828C30.2446 28.4019 28.24 29.6167 24.2309 32.0462C20.3553 34.3948 18.4174 35.5691 16.8558 35.0971C16.2102 34.9019 15.6219 34.5313 15.1475 34.0207C14 32.7858 14 30.3905 14 25.6C14 20.8095 14 18.4142 15.1475 17.1793C15.6219 16.6687 16.2102 16.2981 16.8558 16.1029C18.4174 15.6309 20.3553 16.8052 24.2309 19.1538C28.24 21.5833 30.2446 22.7981 30.6687 24.5172C30.8438 25.2267 30.8438 25.9733 30.6687 26.6828Z" fill="white" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="text-[#8C8C8C] text-center text-xs">наступний<br>елемент</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-else>
            <div class="text-white text-center mt-[20px] font-bold uppercase text-[21px]">Тренування завершено</div>
            <div class="text-white text-center my-[20px]">Переконайтеся, будь ласка, що ви правильно відзначили присутніх</div>

            <button @click="$emit('close')" class="bg-[#BD9135] mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                <div class="w-full text-white font-semibold">До переклички</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#fff"/>
                </svg>
            </button>
            <button @click="$emit('saveTraining')" class="bg-[#339989] mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                <div class="w-full text-white font-semibold">Завершити тренування</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#fff"/>
                </svg>
            </button>
        </div>

        

    </div>
</template>

<script>
    import { ref, onMounted, computed, watch } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { addPlayerStandard, getAllStudentStandardsByStudentId, updatePlayerStandardByReportId } from '../services/apiRequests'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import YouTube from 'vue3-youtube'
    import StandardCarryingForm from './StandardCarryingForm.vue'
    import StudentStandartCarrying from './StudentStandartCarrying.vue'
    import UpdateStudentStandartCarrying from './UpdateStudentStandartCarrying.vue'

    export default {
        name: 'TrainingSummaryView',

        props: {
            training: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const store = useMainStore()
            const apiURL = ref(process.env.VUE_APP_API_URL)

            const selectedSummary = ref(store.currentTraining.summary)

            const elements = ref(selectedSummary.value.elements)

            const totalDuration = ref(0)

            const trainingProccess = ref(0)
            const currentElementTime = ref(0)

            const startedTraining = ref(false)

            const currentElementIndex = ref(0)
            const showElementInfoFull = ref(false)

            const endTrainingFlag = ref(false)

            const intervalTimer = ref(null)

            const currentElementIndexForView = ref(0)

            const standardCarryingFlag = ref(false)
            const standardCarryingObject = ref(null)

            const updateStudentTestingFlag = ref(false)
            const previuosTestValue = ref(null)
            const previuosTestObject = ref(null)

            const startStandardCarrying = async (standart) => {
                standardCarryingObject.value = standart

                currentStandartPlayers.value = []

                for (let player of store.currentTrainingStudentsList) {
                    let standardsRes = await getAllStudentStandardsByStudentId(player.student_id)
                    standardsRes = standardsRes.data

                    let studentStandart = standardsRes.find(standard => standard.training_id === props.training.training_id && standard.base_standard_id === standart.base_standard_id)

                    if (studentStandart) {
                        currentStandartPlayers.value.push({
                            ...player,
                            tested: true,
                            result: null
                        })
                    } else {
                        currentStandartPlayers.value.push({
                            ...player,
                            tested: false,
                            result: null
                        })
                    }
                }

                standardCarryingFlag.value = true

                // store.currentTrainingStudentsList.map(async player => {
                //     let standardsRes = await getAllStudentStandardsByStudentId(player.student_id)

                //     return {
                //         ...player,
                //         tested: false,
                //         result: null
                //     }
                // })
            }

            const currentStudentTestingFlag = ref(false)
            const currentStudentTestingObject = ref(null)

            const currentStandartPlayers = ref([])

            const setCurrentStudentTesting = (student) => {
                currentStudentTestingObject.value = student
                currentStudentTestingFlag.value = true
            }

            const setCurrentStudentRestartTesting = async (student) => {
                currentStudentTestingObject.value = student

                let standardsRes = await getAllStudentStandardsByStudentId(student.student_id)
                standardsRes = standardsRes.data

                console.log('standardsRes ---- ', standardsRes)
                console.log('props.training ---- ', elements.value[currentElementIndex.value])

                previuosTestObject.value = standardsRes.find(standard => standard.training_id === props.training.training_id && standard.base_standard_id === elements.value[currentElementIndex.value].base_standard_id)

                previuosTestValue.value = +previuosTestObject.value.result
                console.log(previuosTestValue.value)

                updateStudentTestingFlag.value = true
            }

            const prevElementToView = () => {
                if (currentElementIndexForView.value > 0) {
                    currentElementIndexForView.value -= 1
                }
            }

            const nextElementToView = () => {
                if (currentElementIndexForView.value < elements.value.length - 1) {
                    currentElementIndexForView.value += 1
                }
            }

            watch(() => currentElementTime.value, (newValue) => {
                if (currentElementIndex.value === elements.value.length) {
                    stopTraining()
                }
                
                if (newValue >= elements.value[currentElementIndex.value].time * 60) {
                    currentElementIndex.value += 1
                    currentElementTime.value = 0
                }
            })

            const parsedDuration = computed(() => {
                return Math.floor(totalDuration.value / 60) + ' год ' + totalDuration.value % 60 + ' хв'
            })

            const trainingProccessProcent = computed(() => {
                return (trainingProccess.value / (totalDuration.value * 60)) * 100
            })

            const addOneMinuteToElement = () => {
                elements.value[currentElementIndex.value].time = +elements.value[currentElementIndex.value].time + 1
                totalDuration.value += 1
                countWidthOfElements()
            }

            const addFiveMinuteToElement = () => {
                elements.value[currentElementIndex.value].time = +elements.value[currentElementIndex.value].time + 5
                totalDuration.value += 5
                countWidthOfElements()
            }
            
            const addTenMinuteToElement = () => {
                elements.value[currentElementIndex.value].time = +elements.value[currentElementIndex.value].time + 10
                totalDuration.value += 10
                countWidthOfElements()
            }

            const nextElement = () => {
                const timeToEndCurrentElement = elements.value[currentElementIndex.value].time * 60 - currentElementTime.value
                trainingProccess.value += timeToEndCurrentElement
                currentElementTime.value = 0
                currentElementIndex.value += 1


                if (currentElementIndex.value === elements.value.length) {
                    stopTraining()
                }
            }

            const prevElement = () => {
                if (currentElementIndex.value > 0) {
                    const timeToEndCurrentElement = elements.value[currentElementIndex.value].time * 60 - currentElementTime.value
                    trainingProccess.value -= elements.value[currentElementIndex.value].time * 60 - currentElementTime.value
                    currentElementTime.value = 0
                    currentElementIndex.value -= 1
                }
            }

            const startTraining = () => {
                startedTraining.value = true

                intervalTimer.value = setInterval(() => {
                    trainingProccess.value += 1
                    currentElementTime.value += 1
                }, 1000);
            }

            const stopTraining = () => {
                trainingProccess.value = totalDuration.value * 60
                startedTraining.value = false
                endTrainingFlag.value = true

                clearInterval(intervalTimer.value)
            }

            const countWidthOfElements = () => {
                elements.value = elements.value.map(el => {
                    el.width = (el.time / totalDuration.value) * 100
                    return el
                })
            }

            onMounted(() => {
                totalDuration.value = elements.value.reduce((acc, el) => acc + +el.time, 0)

                elements.value = elements.value.map(el => {
                    el.width = (el.time / totalDuration.value) * 100
                    return el
                })
            })

            const setStudentTested = async (ctx) => {
                const student = ctx.student
                const standart = ctx.standart

                const studentIndex = currentStandartPlayers.value.findIndex(player => player.student_id === student.student_id)

                currentStandartPlayers.value[studentIndex].tested = true
                currentStandartPlayers.value[studentIndex].result = ctx.mark

                console.log(ctx)

                console.log({
                    student_id: student.student_id,
                    training_id: props.training.training_id,
                    base_standard_name: standart.name,
                    report_id: new Date().getTime(),
                    base_standard_id: standart.base_standard_id,
                    result: ctx.value
                })

                await addPlayerStandard({
                    student_id: student.student_id,
                    training_id: props.training.training_id,
                    base_standard_name: standart.name,
                    report_id: new Date().getTime(),
                    base_standard_id: standart.base_standard_id,
                    result: ctx.value
                })

                currentStudentTestingFlag.value = false
            }

            const updateStudentTested = async (ctx) => {
                const student = ctx.student
                const standart = ctx.standart

                const studentIndex = currentStandartPlayers.value.findIndex(player => player.student_id === student.student_id)

                currentStandartPlayers.value[studentIndex].tested = true
                currentStandartPlayers.value[studentIndex].result = ctx.mark

                console.log(ctx)

                console.log({
                    student_id: student.student_id,
                    training_id: props.training.training_id,
                    base_standard_name: standart.name,
                    report_id: standart.report_id,
                    base_standard_id: standart.base_standard_id,
                    result: ctx.value
                })

                await updatePlayerStandardByReportId(ctx.previuosTestObject.report_id, {
                    student_id: student.student_id,
                    training_id: props.training.training_id,
                    base_standard_name: standart.name,
                    report_id: ctx.previuosTestObject.report_id,
                    base_standard_id: standart.base_standard_id,
                    result: ctx.value
                })

                updateStudentTestingFlag.value = false
            }

            return {
                store,
                selectedSummary,
                elements,
                totalDuration,
                parsedDuration,
                startedTraining,
                trainingProccessProcent,
                startTraining,
                currentElementIndex,
                showElementInfoFull,
                apiURL,
                addOneMinuteToElement,
                addFiveMinuteToElement,
                addTenMinuteToElement,
                nextElement,
                stopTraining,
                endTrainingFlag,
                currentElementIndexForView,
                prevElementToView,
                nextElementToView,
                prevElement,
                standardCarryingFlag,
                currentStudentTestingFlag,
                currentStudentTestingObject,
                setCurrentStudentTesting,
                startStandardCarrying,
                standardCarryingObject,
                currentStandartPlayers,
                setStudentTested,
                setCurrentStudentRestartTesting,
                updateStudentTestingFlag,
                previuosTestValue,
                updateStudentTested,
                previuosTestObject
            }
        },
        
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            YouTube,
            StandardCarryingForm,
            StudentStandartCarrying,
            UpdateStudentStandartCarrying
        },
    }
</script>

<style lang="scss" scoped>

</style>