<template>
  <div class="mt-[17px]">
    <div class="text-white flex justify-between mb-[7px] items-center">
      <div class="text-[9px] tracking-[0.2em]">//////////////</div>
      <div class="text-[15px] font-semibold">КАЛЕНДАР ТРЕНУВАНЬ</div>
      <div class="text-[9px] tracking-[0.2em]">//////////////</div>
    </div>
    <div class="flex justify-center items-center mt-[18px]">
      <button @click="previousMonth" class="mr-4">
      
        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>


      </button>
      <span class="text-[13px] font-semibold text-[#fff]">{{ currentMonth.toUpperCase() }} {{ currentYear }}</span>
      <button @click="nextMonth" class="ml-4">
      
        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L8.43043 8.17424C9.18986 8.90341 9.18986 10.0966 8.43043 10.8258L2 17" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>

      
      </button>
    </div>
    <table class="mx-auto mt-2">
      <thead class="border-b-2 border-[#fff]">
        <tr>
          <th class="text-[#fff] text-lg" width="45px">Пн</th>
          <th class="text-[#fff]" width="45px">Вт</th>
          <th class="text-[#fff]" width="45px">Ср</th>
          <th class="text-[#fff]" width="45px">Чт</th>
          <th class="text-[#fff]" width="45px">Пт</th>
          <th class="text-[#DA4167]" width="45px">Сб</th>
          <th class="text-[#DA4167]" width="45px">Нд</th>
        </tr>
      </thead>
      <tbody :key="updateCalendarKey">
        <tr v-for="week in weeks" class="my-2">
          <td 
            v-for="day in week" 
            :key="day.id" 
            class="text-center text-lg" 
            :class="
              {
                'text-[#DA4167]' : day.weekDay == 5 || day.weekDay == 6,
                'text-[#fff]' : day.weekDay != 5 && day.weekDay != 6
              }
            "
            height="40px" width="40px"
          >
            <div 
              @click="emitDate(day)"
              class="rounded-xl bg-opacity-25 h-full align-middle text-center pt-[5px]"
              :class="{
                'rounded-[6px] border-[1px] border-[#0ABFC2] bg-[#0ABFC2] bg-opacity-50' : day != 0 ? day.isPlanedTrainingDay : false,
                'rounded-[6px] border-[1px] border-[#BD9135] bg-[#BD9135] bg-opacity-50' : day != 0 ? day.isCompletedTrainingDay : false,
              }"
            >
              {{ day.monthDay }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <button @click="$router.push('/add-new-training')" class="bg-[#BD9135] rounded-[9px] text-[#252526] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-2 mx-auto">
      <div class="w-full">Додати тренування</div>
    </button> 
  </div>
</template>
<script>
  import { getWeeksInMonth, addingMatchOfTheDayOfTheWeekAndItsNumberInMonth, formatWeeks } from './service/helpers'

  export default {
    data() {
      return {
        currentMonth: '',
        currentYear: '',
        weeks: [],
        currentDate: new Date(),
        updateCalendarKey: +new Date()
      }
    },
    props: {
      planedTrainingDays: {
        type: Array,
        default: () => []
      },
      completedTrainings: {
        type: Array,
        default: () => []
      },
      completedTrainingsDates: {
        type: Array,
        default: () => []
      }
    },
    created() {
      this.currentDate = new Date()
      this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
      this.currentYear = this.currentDate.getFullYear()
      this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
      // console.log('this.planedTrainingDays ---- ', this.planedTrainingDays)
      this.weeks.forEach(week => {
          week.forEach(day => {
            if (day != 0) {
              const dayDate = new Date(new Date(day.dayDate).setDate(new Date(day.dayDate).getDate() + 1, new Date(day.dayDate).getMonth(), new Date(day.dayDate).getFullYear())).toISOString().split('T')[0]
              console.log('dayDate ---- ', dayDate)
              if (this.planedTrainingDays.includes(dayDate)) {
                day.isPlanedTrainingDay = true
                return
              } else {
                day.isPlanedTrainingDay = false
              }

              if (this.completedTrainingsDates.includes(dayDate)) {
                day.isCompletedTrainingDay = true
              } else {
                day.isCompletedTrainingDay = false
              }
            }
          })
        })

    },
    watch: {
      planedTrainingDays() {
        this.updateCalendarKey = +new Date()
      }
    },
    methods: {
      previousMonth() {
        this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() - 1)
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
        this.weeks.forEach(week => {
          week.forEach(day => {
            if (day != 0) {
              const dayDate = new Date(new Date(day.dayDate).setDate(new Date(day.dayDate).getDate() + 1, new Date(day.dayDate).getMonth(), new Date(day.dayDate).getFullYear())).toISOString().split('T')[0]
              // console.log('dayDate ---- ', dayDate)
              if (this.planedTrainingDays.includes(dayDate)) {
                day.isPlanedTrainingDay = true
                return
              } else {
                day.isPlanedTrainingDay = false
              }

              if (this.completedTrainingsDates.includes(dayDate)) {
                day.isCompletedTrainingDay = true
              } else {
                day.isCompletedTrainingDay = false
              }
            }
          })
        })
      },
      nextMonth() {
        this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() + 1)
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
        this.weeks.forEach(week => {
          week.forEach(day => {
            if (day != 0) {
              const dayDate = new Date(new Date(day.dayDate).setDate(new Date(day.dayDate).getDate() + 1, new Date(day.dayDate).getMonth(), new Date(day.dayDate).getFullYear())).toISOString().split('T')[0]
              // console.log('dayDate ---- ', dayDate)
              if (this.planedTrainingDays.includes(dayDate)) {
                day.isPlanedTrainingDay = true
                return
              } else {
                day.isPlanedTrainingDay = false
              }

              if (this.completedTrainingsDates.includes(dayDate)) {
                day.isCompletedTrainingDay = true
              } else {
                day.isCompletedTrainingDay = false
              }
            }
          })
        })
      },
      emitDate(day) {
        this.$emit('settedDay', day.dayDate.toISOString().split('T')[0])
      }
    }
  }
</script>
