<template>
    <div class="search-block w-[90%] mx-auto">
        <div class="flex justify-between mt-2">
            <div class="flex py-[3px]">
                <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 px-[10px] rounded-[6px]">
                    <img src="@/desktopApp/assets/icons/magnify_icon.svg" class="w-3 h-3">
                    <input v-model="search" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px]" placeholder="Пошук..." type="text">
                </div>
                <div class="ml-[20px] flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/desktopApp/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/desktopApp/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
            </div>
            <div class="filter flex items-center">
                <button class="bg-[#BD9135] hover:bg-[#a77f2b] w-[228px] text-white px-4 h-[38px] text-[14px] rounded-md" @click="$emit('addStudent')">Додати</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'

    export default {
        name: 'menu-bar',

        emits: ['update:modelValue', 'startFilter'],

        props: {
            modelValue: {
                type: String,
                default: ''
            },
            page: {
                type: Number,
                default: 1
            },
            maxPage: {
                type: Number,
                default: 1
            }
        },

        setup(props, { emit }) {
            const search = ref(props.modelValue)
            const page = ref(props.page) 

            const prevPage = () => {
                page.value > 1 ? page.value-- : page.value = 1
            }

            const nextPage = () => {
                page.value += 1
            }

            watch(search, (value) => {
                emit('update:modelValue', value)
                emit('startFilter')
            })

            watch(page, (value) => {
                if (value >= props.maxPage) {
                    page.value = props.maxPage
                } else if (value <= 1 && value != '') {
                    page.value = 1
                } else if (value == ''){
                    return
                }
                emit('update:page', value)
                emit('startFilter')
            })

            return {
                search,
                page,
                prevPage,
                nextPage
            }
        }
    }
</script>

<style lang="scss" scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
