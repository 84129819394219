<template>
    <div class="h-full flex w-[97%] mx-auto bg-[#47484966] rounded-[8px]">
        
        <div class="w-[250px] h-[500px] ml-[38px] mt-[28px]">
            <div>
                <div>
                    <img 
                        @click="uploadPhoto"
                        src="@/desktopApp/assets/icons/upload_icon_light.svg" 
                        class="absolute left-[210px] top-[20px] cursor-pointer z-40"
                    >
                    <input 
                        ref="fileInput"
                        type="file" 
                        class="hidden"
                        :value="inputValue"
                        @change="$emit('loadPhoto', $event)"
                    />
                </div>
                <div class="flex justify-center h-[162px] w-full">
                    <img :src="photo" @error="photo = require('@/desktopApp/assets/student_photo_placeholder.svg')" class="relative top-[10px] student-logo">
                </div>
            </div>

            <div class=" w-full h-[74px] relative top-[10px] mt-[10px]">
                <div class="text-[30px] font-semibold text-center text-white">
                    <div class="h-[45px]" v-if="name?.length > 0 || surname?.length > 0">
                        <marquee v-if="name?.length + surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
                            <span v-for="id in 100" class="mx-3">{{ name + ' ' + surname }}</span>
                        </marquee>
                        <span v-else>{{ name + ' ' + surname }}</span>
                    </div>
                    <span v-else class="opacity-25 text-[#FFF]">Лєо Мессі</span>
                </div>
                <div class="flex relative justify-between top-[-5px] mx-[8px]">
                    <div class="flex flex-col items-center justify-center">
                        <div class="text-[9px] text-[#FFF]">група</div>
                        <div class="text-[12px] font-semibold text-[#FFF]">
                            <span v-if="group?.length > 0">{{ group }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px] text-[#FFF]">вік</div>
                        <div class="text-[12px] font-semibold text-[#FFF]">
                            <span v-if="age > 0">{{ age }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px] text-[#FFF]">амплуа</div>
                        <div class="text-[12px] font-semibold text-[#FFF]">
                            <span v-if="studentAmplua?.length > 0">{{ studentAmplua }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px] text-[#FFF]">номер</div>
                        <div class="text-[12px] font-semibold text-[#FFF]">
                            <span v-if="studentNumber?.length > 0">{{ studentNumber }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex  items-center justify-center font-normal">
                        <img src="@/desktopApp/assets/logo.svg" class="w-[20px]">
                        <div class="text-[#FFF] mx-1 mb-[2px]">|</div>
                        <img src="@/desktopApp/assets/icons/uk_flag_dark.svg" class="w-[20px] ml-[5px]">
                    </div>
                </div>
            </div>

            <div class="mx-[14px] relative  top-[27px]">
                <div class="flex justify-between items-center align-middle mb-[10px]">
                    <div>
                        <input v-model="surname" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Прізвище" type="text">
                    </div>
                    <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                        прізвище
                    </div>
                </div>
                <div class="flex justify-between items-center align-middle mb-[10px]">
                    <div>
                        <input v-model="name" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Ім'я" type="text">
                    </div>
                    <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                        ім'я
                    </div>
                </div>
                <div class="flex justify-between items-center align-middle mb-[10px]">
                    <div class="w-[50%]">
                        <input v-model="patronymic" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="По батькові" type="text">
                    </div>
                    <div class="font-normal w-[50%] text-end text-[#BD9135] opacity-90 text-[11px]">
                        по батькові
                    </div>
                </div>
                <div class="flex justify-between items-center align-middle mb-[10px]">
                    <div>
                        <input v-model="formattedBirthDate" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="12.12.2012" type="date">
                    </div>
                    <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                        дата народження
                    </div>
                </div>
                <div class="flex justify-between items-center align-middle mb-[10px]">
                    <div>
                        <input v-model="phoneNumber" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="+38000000000" type="text">
                    </div>
                    <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                        телефон
                    </div>
                </div>
                <div class="flex justify-between items-center align-middle mb-[10px]">
                    <div>
                        <input v-model="email" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="student@gmail.com" type="text">
                    </div>
                    <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                        email
                    </div>
                </div>
                <div class="flex justify-between items-center align-middle mb-[10px]">
                    <div>
                        <input v-model="nationality" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Українець" type="text">
                    </div>
                    <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                        національність
                    </div>
                </div>
                <div class="flex">
                    <div @click="$emit('close')" class="bg-[#BD9135] w-[100%] cursor-pointer font-bold rounded-[6px] text-white text-center flex items-center justify-center h-[32px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 12L20 11.9998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 7L4.70711 11.2929C4.37377 11.6262 4.20711 11.7929 4.20711 12C4.20711 12.2071 4.37377 12.3738 4.70711 12.7071L9 17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-3/4">
            <div class="ml-[26px] flex justify-between w-full">
                <div>
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Кар'єра</div>
                    <div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">09-2006 — 05-2012</div>
                            <div class="text-[#FFF] text-[10px]">ФК Колос</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">09-2006 — 05-2012</div>
                            <div class="text-[#FFF] text-[10px]">ФК Колос</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">09-2006 — 05-2012</div>
                            <div class="text-[#FFF] text-[10px]">ФК Колос</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Фінанси</div>
                    <div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">Непрямі витрати</div>
                            <div class="text-[#A8A8A8] text-[10px]">49 000,00 ГРН</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">Прямі витрати</div>
                            <div class="text-[#DA4167] text-[10px]">156 000,00 ГРН</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">Загальні витрати</div>
                            <div class="text-[#339989] text-[10px]">200 000,00 грн</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Медицина</div>
                    <div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">група крові</div>
                            <div class="text-[#FFF] text-[10px]">O(I)-</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">кардіозагартованість</div>
                            <div class="text-[#FFF] text-[10px]">36 VO2  макс</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">кровʼяний тиск</div>
                            <div class="text-[#FFF] text-[10px]">98/70  мм рт. ст.</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">середній серцевий ритм</div>
                            <div class="text-[#FFF] text-[10px]">73 уд.хв</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ml-[26px] flex justify-between w-full">
                <div class="justify-center ml-[45px]">
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Амплуа</div>
                    <div>
                        <div>
                            <img src="@/desktopApp/assets/icons/little_field.svg"/>
                        </div>
                    </div>
                </div>
                <div class="ml-[35px]">
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Аналіз граця</div>
                    <div>
                        <RadarChart :chart-data="[2, 3, 1, 5, 3, 1]" :chart-size="{ width: '200px', height: '200px' }" />
                    </div>
                </div>
                <div class="w-[200px] relative top-[20px]">
                    <div class="h-[227px] relative w-[200px]">
                        <div class="text-[#FFF] text-[14px] text-center">
                            <span class="font-bold">{{ 'XL' }}</span> розмір
                        </div>
                        <div class="w-full flex justify-center h-full mb-[10px]">
                            <img src="@/desktopApp/assets/student_params.svg" class="h-[85%]">
                        </div>
                        <div class="text-[#FFF] text-[14px] absolute bottom-[5px] w-[100px] left-[52%] translate-x-[-50%] flex justify-between">
                            <div class="font-bold">{{ 'Пр.' }}</div>
                            <div><span class="font-bold">{{ '42' }}</span> р.</div>
                        </div>
                        <!-- <div class="text-[#FFF] text-[14px] breast-volume-block absolute text-center">
                            <span class="font-bold">{{ '100' }}</span> см
                        </div> -->
                        <!-- <div class="text-[#FFF] text-[14px]  absolute bottom-[-20px] w-full text-center">
                            <span class="font-bold">{{ '72' }}</span> вага (кг)
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="ml-[26px] flex justify-between w-full">
                <div class="justify-center ml-[25px]">
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Тренування</div>
                    <PerCircle displayText="тренувань" :value="11" :percent="50" progressBarColor="#339989"/>
                </div>
                <div class="ml-[10px]">
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Зіграно матчів</div>
                    <PerCircle displayText="тренувань" :value="11" :percent="50" progressBarColor="#339989"/>
                </div>
                <div class="w-[200px]">
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Зіграно хвилин</div>
                    <PerCircle displayText="тренувань" :value="11" :percent="50" progressBarColor="#339989"/>
                </div>
            </div>
            <div class="ml-[26px] flex justify-between w-full">
                <div class="justify-center ml-[25px] w-[70%] relative top-[20px]">
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Статистика</div>
                    <div class="w-full">
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center"></th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">матчів</th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">голів</th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">асист</th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">пен.</th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">автогол</th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">жовтих</th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">черв.</th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">трен.</th>
                                    <th class="text-[#C59014] text-[10px] py-[10px] text-center">пропущ.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="py-[10px] text-start text-[#C59014] text-[10px]">до переходу</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">21</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">15</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">2</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">5</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">32</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">14</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">17</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">11</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">10</td>
                                </tr>
                                <tr>
                                    <td class="py-[10px] text-start text-[#C59014] text-[10px]">в клубі</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">21</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">15</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">2</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">5</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">32</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">14</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">17</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">11</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">10</td>
                                </tr>
                                <tr>
                                    <td class="py-[10px] text-start text-[#C59014] text-[10px]">Всього</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">21</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">15</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">2</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">5</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">32</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">14</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">17</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">11</td>
                                    <td class="py-[10px] text-center text-[#FFF] text-[10px]">10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="w-[200px] relative top-[25px]">
                    <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Тестування</div>
                    <div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">Абсолютна швидкість</div>
                            <div class="text-[#A8A8A8] text-[10px]">17 м/с</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">Стартова швидкість</div>
                            <div class="text-[#DA4167] text-[10px]">17 м/с</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">Код-тест</div>
                            <div class="text-[#339989] text-[10px]">62</div>
                        </div>
                        <div class="flex justify-between w-[208px] mb-[4px]">
                            <div class="text-[#C59014] text-[10px]">Загальна оцінка</div>
                            <div class="text-[#339989] text-[10px]"><svg width="57" height="11" viewBox="0 0 57 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.46768 0L6.72107 3.45957L10.6678 3.49243L7.49571 5.66341L8.68151 9.14329L5.46768 7.02546L2.25386 9.14329L3.43965 5.66341L0.267607 3.49243L4.21429 3.45957L5.46768 0Z" fill="#C59014"/>
                            <path d="M16.8398 0L18.0931 3.45957L22.0398 3.49243L18.8678 5.66341L20.0536 9.14329L16.8398 7.02546L13.6259 9.14329L14.8117 5.66341L11.6397 3.49243L15.5864 3.45957L16.8398 0Z" fill="#C59014"/>
                            <path d="M28.2138 0L29.4672 3.45957L33.4139 3.49243L30.2418 5.66341L31.4276 9.14329L28.2138 7.02546L25 9.14329L26.1857 5.66341L23.0137 3.49243L26.9604 3.45957L28.2138 0Z" fill="#C59014"/>
                            <path d="M39.5858 0L40.8392 3.45957L44.7859 3.49243L41.6139 5.66341L42.7997 9.14329L39.5858 7.02546L36.372 9.14329L37.5578 5.66341L34.3858 3.49243L38.3325 3.45957L39.5858 0Z" fill="#C59014"/>
                            <path d="M50.9589 0L52.2123 3.45957L56.159 3.49243L52.9869 5.66341L54.1727 9.14329L50.9589 7.02546L47.7451 9.14329L48.9309 5.66341L45.7588 3.49243L49.7055 3.45957L50.9589 0Z" fill="#C59014"/>
                            </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import RadarChart from '../components/RadarChart.vue'
    import PerCircle from '../components/PerCircle.vue'

    export default {
        name: 'main-tab',

        props: {
            student: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const name = ref(
                props.student?.name ? props.student.name : ''
            )
            const surname = ref(
                props.student?.surname ? props.student.surname : ''
            )
            const patronymic = ref(
                props.student?.patronymic ? props.student.patronymic : ''
            )
            const formattedBirthDate = ref(
                props.student?.birth_date ? props.student.birth_date : ''
            )
            const email = ref(
                props.student?.email ? props.student.email : ''
            )
            const phoneNumber = ref(
                props.student?.phone ? props.student.phone : ''
            )
            const nationality = ref(
                props.student?.nationality ? props.student.nationality : ''
            )
            const group = ref(
                props.student?.group_name ? props.student.group_name : ''
            )
            const age = ref(
                props.student?.age ? props.student.age : ''
            )
            const studentAmplua = ref(
                props.student?.amplua ? props.student.amplua : ''
            )
            const studentNumber = ref(
                props.student?.player_number ? props.student.player_number : ''
            )
            const photo = ref(
                props.student?.photo ? apiURL.value + 'static/' + props.student.photo : ''
            )


            return {
                name,
                surname,
                patronymic,
                formattedBirthDate,
                phoneNumber,
                email,
                nationality,
                group,
                age,
                studentAmplua,
                studentNumber,
                photo,
                apiURL
            }
        },

        components: {
            RadarChart,
            PerCircle
        },
    }
</script>

<style lang="scss" scoped>

</style>