import axios from 'axios'

export async function getTokens(login, password) {
    const session_url = process.env.VUE_APP_API_URL + 'auth/trainer_auth'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            username: login,
            password: password
        }
    }

    const response = await axios(config)
    console.log('response', response)
    if (response.data.status != 'error') {
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        return true
    } else {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        return false
    }
}

async function rewriteTokensRequest() {
    const session_url = process.env.VUE_APP_API_URL + 'auth/trainer_refresh'
    
    const config = {
        method: 'post',
        url: session_url,
        headers: { 'Authorization': 'Bearer '+ localStorage.getItem('refresh_token') }
    }

    let response = {}
    try {
        response = await axios(config)
    } catch (error) {
        response.status = 422
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
    }


    if (response.status != 200) {
        // localStorage.removeItem('access_token')
        // localStorage.removeItem('refresh_token')
        // window.location.reload();
    } else if (response.status == 200) {
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
    }
}

async function sendApiRequest(axiosConfig) {
    axiosConfig.headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token') 
    }
    let response = {}
    try {
        response = await axios(axiosConfig)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        await rewriteTokensRequest()
        axiosConfig.headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token') 
        }
        try {
            response = await axios(axiosConfig)
        } catch (error) {
            // localStorage.removeItem('access_token')
            // localStorage.removeItem('refresh_token')
            // window.location.reload();
        }
        
        return response
    } else if (response.status == 200) {
        return response
    }
}

async function sendApiRequestReturnData(axiosConfig) {
    const response = await sendApiRequest(axiosConfig)
    return response.data
}

export const getTrainerDataRequest = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_by_credentials'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequest(config)
}

export const getStudentBalanceDataRequest = async (balance_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'balance/get_balance_state'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerGroupsRequest = async (trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_groups_by_trainer_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getPlanedTrainingsByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_planed_trainings_by_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId,
            current_date: new Date().toISOString()
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsByGroupIdRequest = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_all_students_list_in_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const processPresentStudents = async (students, training_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_completed_training_to_student_list'
    const config = {
        method: 'post',
        url: session_url,
        data: {
            training_id: training_id,
            students: students
        }
    }

    return await sendApiRequest(config)
}

export const processMissedStudents = async (students, training_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_missed_trainings_by_student_list'
    const config = {
        method: 'post',
        url: session_url,
        data: {
            training_id: training_id,
            students: students
        }
    }

    return await sendApiRequest(config)
}

export const updateTrainingStatus = async (training_id, status) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_training_status'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            training_id: training_id,
            status: status
        }
    }

    return await sendApiRequest(config)
}

export const getStudentVirtualBalanceRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'balance/get_balance_state'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const addVirtualIncomeRequest = async (balanceId, amount, label) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_virtual_income'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: balanceId,
        amount: amount,
        label: label
    }
    formData.append('virtual_income', JSON.stringify(txConfig))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const addRealIncomeRequest = async (balanceId, amount, label, virtualTxId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_income'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: balanceId,
        amount: amount,
        label: label,
        embed_virtual_income: virtualTxId
    }

    formData.append('real_income', JSON.stringify(txConfig))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getRealIncomesByBalanceIdRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_all_real_incomes_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getAllStudentsRequest = async (count, skip) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_all_students'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            count: count,
            skip: skip
        }
    }

    return await sendApiRequest(config)
}

export const getCompletedTrainingsByGroup = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_completed_trainings_by_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsWhichAttendedTraining = async (trainingDate, group_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_students_by_attended_training'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_date: trainingDate,
            group_id: group_id
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsWhichMissedTraining = async (trainingDate, group_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_students_by_missed_training'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_date: trainingDate,
            group_id: group_id
        }
    }

    return await sendApiRequest(config)
}

export const deleteCompletedTrainingFromStudent = async (studentId, trainingFlag, trainingDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/delete_completed_training_from_student'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            student_id: studentId,
            training_flag: trainingFlag,
            training_date: trainingDate
        }
    }

    return await sendApiRequest(config)
}

export const addCompletedTrainingToStudent = async (studentId, trainingFlag, trainingDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_completed_training_to_student'
    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId,
            training_flag: trainingFlag,
            training_date: trainingDate
        }
    }

    return await sendApiRequest(config)
}

export const changeMissedToAttendedTraining = async (studentId, trainingDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/change_missed_to_attended_training'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId,
            training_date: trainingDate
        }
    }

    return await sendApiRequest(config)
}

export const changeAttendedToMissedTraining = async (studentId, trainingDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/change_attended_to_missed_training'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId,
            training_date: trainingDate
        }
    }

    return await sendApiRequest(config)
}

export const getTrainingById = async (trainingId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_training_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_id: trainingId
        }
    }

    return await sendApiRequest(config)
}

export const deleteTrainingById = async (trainingId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/delete_training_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_id: trainingId
        }
    }

    return await sendApiRequest(config)
}

export const updateTrainingById = async (trainingId, startTime, endTime, trainingDate, groupId, groupLabel, locationId, locationLabel, type, description, summary) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_training_by_id'

    const config = {
        method: 'put',
        url: session_url + '?training_id=' + trainingId,
        data: {
            group_id: groupId,
            group_label: groupLabel,
            date: new Date(trainingDate).toISOString(),
            start_time: startTime,
            end_time: endTime,
            location_id: locationId,
            location_label: locationLabel,
            type: type,
            description: description,
            summary: summary
        }
    }

    console.log(config)

    return await sendApiRequest(config)
}

export const getPlanedTrainingsByDate = async (date) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_planed_trainings_by_date'

    const days = new Date(date).getDate() + 1
    const month = new Date(date).getMonth()
    const year = new Date(date).getFullYear()


    const config = {
        method: 'get',
        url: session_url,
        params: {
            date: new Date(year, month, days, 0, 0, 0, 0).toISOString()
        }
    }

    return await sendApiRequest(config)
}

export const getAllGroups = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_all_groups'

    const config = {
      method: 'get',
      url: session_url,
    }
    
    return await sendApiRequest(config)
}

export const getAllLocations = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'location/get_all_locations'

    const config = {
        method: 'get',
        url: session_url,
    }

    return await sendApiRequest(config)
}

export const addNewTraining = async (groupName, groupId, locationName, locationId, startTime, endTime, date, trainingType, summary) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_new_training'
    let formattedDate = null

    if (typeof date === 'string') {
        formattedDate = (new Date(date)).toISOString()
    } else {
        formattedDate = (new Date(formatDate(date))).toISOString()
    }

    const data = {
        group_id: groupId,
        group_label: groupName,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        location_id: locationId,
        location_label: locationName,
        type: trainingType,
        summary: summary
    }

    const config = {
        method: 'post',
        url: session_url,
        data: data
    }
    
    return await sendApiRequest(config)
}

export const addNewStudentRequest = async (new_student_obj) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/add_new_student'

    const formData = new FormData()
    console.log('new_student_obj ----- ', new_student_obj)
    formData.append('new_student', JSON.stringify(new_student_obj))

    const config = {
      method: 'post',
      url: session_url,
      data: formData
    }
    return await sendApiRequest(config)
}

export const addGroupToStudents = async (studentsIds, groupId, groupName) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/add_list_of_students_to_group'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            group_id: groupId,
            group_name: groupName
        },
        data: studentsIds
    }

    return await sendApiRequest(config)
}

export const updateStudent = async (student_obj, photo, idPhotos, passportPhotos, attorneyFiles, photoForPassport) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student'

    console.log('student_obj', student_obj)

    student_obj.birthday = new Date(student_obj.birthday).toISOString()
    student_obj.start_training = new Date(student_obj.start_training).toISOString()
    student_obj.passport_end_date = new Date(student_obj.passport_end_date).toISOString()

    const formData = new FormData()
    if (photo !== null && photo.startsWith('data')) {
        formData.append('student_picture', dataURItoBlob(photo))
    } else {
        formData.append('student_picture', null)
    }

    idPhotos.forEach((id_photo, index) => {
        formData.append(`id_photos`, id_photo)
    })

    passportPhotos.forEach((passport_photo, index) => {
        formData.append(`passport_photos`, passport_photo)
    })

    attorneyFiles.forEach((attorney_file, index) => {
        formData.append(`attorney_files`, attorney_file)
    })

    photoForPassport.forEach((photo_for_passport_file, index) => {
        formData.append(`photo_for_passport`, photo_for_passport_file)
    })

    formData.append('updated_student', JSON.stringify(student_obj))

    const config = {
        method: 'put',
        url: session_url,
        data: formData,
        params: {
            student_id: student_obj.id
        }
    }

    return await sendApiRequest(config)
}

export const getTransactionsByBalanceIdRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_transactions_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getAllIncomesByGroupInYear = async (year) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_groups_by_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerCompletedTrainingsByYear = async (year, trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_trainer_completed_trainings_by_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getGroupsAttendanceByYearAndMonth = async (year, month) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_groups_attendance_by_month_and_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            month: month
        }
    }

    return await sendApiRequest(config)
}

export const broadcastMessageToGroup = async (groupId, message) => {
    const session_url = process.env.VUE_APP_API_URL + 'messages/broadcast_message_to_group_by_trainer'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId,
            message_text: message
        }
    }

    return await sendApiRequest(config)
}

export const getStudentAttendedTrainingsByStudentId = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_students_completed_trainings'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequest(config)
}

export const getAllBroadcastedMessages = async (sender_id, count, skip) => {
    const session_url = process.env.VUE_APP_API_URL + 'messages/get_all_broadcasted_messages'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            sender_id: sender_id,
            count: count,
            skip: skip
        }
    }

    return await sendApiRequest(config)
}

export const getProgramsByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_programs_by_group_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getSummariesByMicrocycleId = async (id, count = 20, skip = 0) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_summaries_by_microcycle_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            microcycle_id: id,
            count: count,
            skip: skip
        }
    }

    return await sendApiRequest(config)
}

export const getMissedTrainingsByStudentId = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'missed_trainings_router/get_missed_trainings_by_user_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            user_id: studentId
        }
    }

    return await sendApiRequest(config)
}

export const getActiveStudentsInGroup = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_all_students_list_in_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getAllCompletedTrainingsByStudentId = async (student_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'trainings/get_students_completed_trainings',
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getPlanedTrainingsByGroup = async (group_id) => {
    const currentDate = new Date()

    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'trainings/get_planed_trainings_by_group',
        params: {
            group_id: group_id,
            current_date: currentDate.toISOString()
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getGroupById = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_group_by_id'
    const config = {
        method: 'get',
        url: session_url + '?group_id=' + groupId,
        params: {
            group_id: groupId
        }
    }
    return await sendApiRequest(config)
}

export const getStudentById = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_student_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequest(config)
}

export const getAllStudents = async (count = 100, skip = 0, balance_sort = null, query = null, startAgeRange = null, endAgeRange = null, status = ['active']) => {
    console.log('status -------- ', status)
    const session_url = process.env.VUE_APP_API_URL + 'student/get_all_students'
    const config = {
      method: 'post',
      url: session_url,

      data: {
            count: count,
            skip: skip,
            balance_sort: balance_sort,
            query: query,
            age_from: startAgeRange,
            age_to: endAgeRange,
            status: status
      }
    }
    return await sendApiRequest(config)
}

export const getStudentsCountByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_number_of_students_by_group_id'
    
    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerById = async (trainerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_by_id'
    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainerId
        }
    }
    return await sendApiRequest(config)
}

export const getAllTrainers = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_all_trainers'
    const config = {
      method: 'get',
      url: session_url
    }
    return await sendApiRequest(config)
}

export const getStudentsCount = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'student/get_students_count'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllStudentsListInGroup = async (group_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'groups/get_all_students_list_in_group',
        params: {
            group_id: group_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getProgramsByName = async (name) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/get_programs_by_name'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            name: name,
            count: 20,
            skip: 0
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteProgramsFromGroup = async (groupId, trainingProgramsIds) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/delete_group_from_programs'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            training_program_ids: trainingProgramsIds,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const addTrainingProgramsToGroup = async (groupId, trainingProgramsIds) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/add_group_to_programs'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            training_program_ids: trainingProgramsIds,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const updateGroupRequest = async (groupId, groupName, groupYearRange, trainerId, paymentStatus) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/update_group'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            group_id: groupId,
        },
        data: {
            name: groupName,
            year_range: groupYearRange,
            trainer_id: trainerId,
            payment_status: paymentStatus
        }
    }

    return await sendApiRequest(config)
}

export const getAllTrainings = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_all_trainings'
    const config = {
        method: 'get',
        url: session_url
    }
    return await sendApiRequest(config)
}

export const getSchemaById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/get_schema_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            schema_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addTrainingRequest = async (training, additional_images) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_constructor/add_new_training'
    
    const config = {
        method: 'post',
        url: session_url,
        data: training
    }

    return await sendApiRequestReturnData(config)
}

export const addNewTrainingSummary = async (trainingSummaryObject) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/add_summary'

    const config = {
        method: 'post',
        url: session_url,
        data: trainingSummaryObject
    }

    return await sendApiRequestReturnData(config)
}

export const getAllSummaries = async (count = 20, skip = 0, filters = {}, query = '') => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_all_summaries'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            count: count,
            skip: skip,
            filters: filters,
            query: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainingSummaryById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_summary_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            summary_id: id
        }
    }

    return await sendApiRequestReturnData(config)
} 

export const getSummaryById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_summary_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            summary_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteSummaryById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/delete_summary'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            summary_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateSummaryById = async (id, newSummary) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/update_summary'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            summary_id: id
        },
        data: newSummary
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainingsSummaryCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_summary_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const addTrainingSummaryProgram = async (trainingSummaryProgramObject) => {
    console.log('trainingSummaryProgramObject ------- ', trainingSummaryProgramObject)
    const session_url = process.env.VUE_APP_API_URL + 'training_program/add_new_training_program'

    const config = {
        method: 'post',
        url: session_url,
        data: trainingSummaryProgramObject
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainingPrograms = async (count = 20, skip = 0, query = '') => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/get_programs_by_name'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            count: count,
            skip: skip,
            name: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteTrainingProgramById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/delete_training_program_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_program_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainingProgramById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/get_training_program_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_program_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateTrainingProgramById = async (id, newTrainingProgram) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/update_training_program_by_id'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            training_program_id: id
        },
        data: newTrainingProgram
    }

    return await sendApiRequestReturnData(config)
}

export const addSummaryToProgram = async (program_id, program_name, microcycle_id, microcycle_name, mesocycle_id, mesocycle_name, summary_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/add_summary_to_program'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            program_id: program_id,
            program_name: program_name,
            microcycle_id: microcycle_id,
            microcycle_name: microcycle_name,
            mesocycle_id: mesocycle_id,
            mesocycle_name: mesocycle_name,
            summary_id: summary_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const countTrainingPrograms = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/count_training_programs'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const countSummariesWithMicrocycleId = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/count_summaries_with_microcycles_id'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const countSummariesInCurrentProgram = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/count_trainings_in_current_program'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_program_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainingElementsRequest = async (count, skip, filters = {}, query = '') => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/get_all_training_elements'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            count: count,
            skip: skip,
            filters: filters,
            query: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getStatisticsByElements = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/statistics_by_element_types'

    const config = {
        method: 'get',
        url: session_url,
    }

    return await sendApiRequestReturnData(config)
}

export const getElementByIdRequest = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/get_training_element_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_element_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateTrainingElementById = async (id, trainingElement, new_additional_images) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/update_training_element_by_id'

    const form = new FormData()

    console.log('trainingElement ------- ', trainingElement)

    form.append('request', JSON.stringify(trainingElement))

    new_additional_images.forEach((image) => {
        form.append('addition_images', image)
    })

    const config = {
        method: 'put',
        url: session_url,
        params: {
            training_element_id: id
        },
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getLocationById = async (locationId) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/get_location_by_id'
    const config = {
        method: 'get',
        url: session_url + '?location_id=' + locationId,
        params: {
            location_id: locationId
        }
    }
    return await sendApiRequest(config)
}

export const updateTraining = async (trainingId, groupName, groupId, locationName, locationId, startTime, endTime, date, type, description, summary) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_training_by_id'
    
    let formattedDate = null

    if (typeof date === 'string') {
        formattedDate = (new Date(date + ' GMT')).toISOString()
    } else {
        formattedDate = (new Date(formatDate(date) + ' GMT')).toISOString()
    }
    
    const data = {
        group_id: groupId,
        group_label: groupName,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        location_id: locationId,
        location_label: locationName,
        type: type,
        description: description,
        summary: summary
    }
    const config = {
        method: 'put',
        url: session_url + '?training_id=' + trainingId,
        data: data
    }
    return await sendApiRequest(config)
}

export const deleteGroupRequest = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/delete_group'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            group_id: groupId,
        }
    }

    return await sendApiRequest(config)
}

export const addNewGroup = async (groupName, groupYearRange, trainerId, paymentStatus) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/add_new_group'
    const config = {
        method: 'post',
        url: session_url,
        data: {
            name: groupName,
            year_range: groupYearRange,
            trainer_id: trainerId,
            payment_status: paymentStatus
        }
    }
    return await sendApiRequest(config)
}

export const getAllIncomes = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_incomes_by_balance_id',
        params: {
            balance_id: balance_id,
            year: new Date().getFullYear()
        }
    }
    return await sendApiRequestReturnData(config)
}

export const getAllOutlays = async (balance_id, year) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_outlay_by_balance_id',
        params: {
            balance_id: balance_id,
            year: year
        }
    }
    const response = await sendApiRequestReturnData(config)
    return response
}

export const getBalanceState = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_balance_state',
        params: {
            balance_id: balance_id
        }
    }
    const response = await sendApiRequestReturnData(config)
    return response.balance_state
}

export const sendPhotoToRemoveBg = async (blob) => {
    const formData = new FormData();
    formData.append('file', blob);
    
    const response = await axios(
        {
            url: 'https://kolosapi.carpediem.pp.ua/subservice/removebg',
            method: 'POST',
            responseType: 'blob', 
            data: formData,
        }
    )

    return new Blob([response.data])
}

export const deleteElementByIdRequest = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/delete_training_element_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_element_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addTrainingElementRequest = async (trainingElement, addition_images) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/add_new_training_element'

    const form = new FormData()

    form.append('request', JSON.stringify(trainingElement))

    addition_images.forEach((image) => {
        form.append('addition_images', image)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getSvgCodeOfSchema = async (filename) => {
    
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'static/' + filename,
    }
    
    return await sendApiRequestReturnData(config)
}

export const createNewTrainingSchemaRequest = async (trainingSchema, file) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/create_schema'
    console.log('trainingSchema ------- ', trainingSchema)

    const formData = new FormData()

    formData.append('training_schema', JSON.stringify(trainingSchema))

    formData.append('file', file)

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const updateSchemaByIdRequest = async (id, trainingSchema, file) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/update_schema_by_id'

    const formData = new FormData()

    formData.append('training_schema', JSON.stringify(trainingSchema))

    formData.append('file', file)

    const config = {
        method: 'put',
        url: session_url,
        params: {
            schema_id: id
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const deleteSchemaByIdRequest = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/delete_schema_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            schema_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainingSchemasRequest = async (count, skip) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/get_all_schemas'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            count: count,
            skip: skip
        }
    }

    return await sendApiRequestReturnData(config)
}

export const parsePalarXls = async (file) => {
    const formData = new FormData()

    //multipart/form-data

    formData.append('file', file)

    const config = {
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'training_summary_addons/parse_palar_xls',
        data: formData,
        responseType: 'blob',
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    return await sendApiRequest(config)
}

export const getDebtStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_debt_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getActiveStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_active_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getArchiveStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_archive_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerFullCardByStudentId = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/get_player_full_card_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerHealthParamsByStudentId = async (studentId) => {
    console.log('studentId ------- ', studentId)
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/get_player_health_params_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllStudentStandardsByStudentId = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/get_all_student_standards_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllStandardsRequest = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'standards/get_standards'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerScoutReportsByStudentId = async (studentId, scout_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_player_scout_reports_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId,

            scout_id: scout_id,
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deletePlayerScoutReportById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/delete_player_scout_report'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            report_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllScoutsWhichDoReportsByStudent = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_all_scouts_which_do_reports_by_student'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getScoreSettings = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'settings/get_score_settings'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainerMeetUps = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_all_trainer_meet'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getAllMatchReports = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-all-match-reports'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getMatchReportById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-match-report'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            match_report_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerStatisticsByMatchReportIdAndPlayerId = async (matchReportId, playerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-player-statistics-by-match-report-id-and-player-id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            match_report_id: matchReportId,
            player_id: playerId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updatePlayerStatisticsByMatchReportIdAndPlayerId = async (matchReportId, playerId, newStatistics) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/update-player-statistics'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            match_report_id: matchReportId,
            player_id: playerId
        },
        data: newStatistics
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerStatisticsByPlayerId = async (playerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-player-statistics-by-player-id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            player_id: playerId
        }
    }

    return await sendApiRequestReturnData(config)
}