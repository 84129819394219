<template>
    <div @click="closeCard" style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[7px] fixed w-full h-[100vh]"></div>
    <exit-confirm-form 
        v-if="updateStudentFlag"
        :alertText="'Бажаєте зберегти зміни у картці учня ' + currentStudent.name + ' ' + currentStudent.surname + '?'"
        class="fixed top-[50%] ml-[110px] w-[260px] left-[50%] translate-x-[-50%] translate-y-[-50%]"

        @returnToCurrent="updateStudentFlag = false"
        @doSave="updateStudentVM"
        @dontSave="$emit('close')"
    />
    <div 
        v-if="!updateStudentFlag" 
        class="student-card fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] flex"
        :class="{
            'scale-[0.85] origin-center ml-[130px]' : screenWidth < 1360,
            'ml-[110px]' : screenWidth >= 1360,
            'ml-[126px]' : screenWidth < 1400
        }"    
    >
        <image-cropper 
            v-if="showImageCropper"
            :image="imageToCrop"
            @processedImage="processImage"
        />
        <new-info-card 
            @loadPhoto="loadImage"
            :studentPhoto = "studentPhoto"
            v-model:name="currentStudent.name"
            v-model:surname="currentStudent.surname"
            v-model:patronymic="currentStudent.patronymic"
            v-model:birthDate="currentStudent.birthday"
            v-model:phoneNumber="currentStudent.phone"
            v-model:email="currentStudent.email"
            v-model:group="currentStudent.group_name"
            v-model:nationality="currentStudent.nationality"
            :studentNumber="currentStudent.player_number"
            :studentAmplua="currentStudent.amplua"
            class=""
        />

        <div class="ml-[15px]">
            <new-parameters-card 
                v-model:height="currentStudent.height"
                v-model:weight="currentStudent.weight"
                v-model:footSize="currentStudent.foot_size"
                v-model:leadingFoot="currentStudent.leading_foot"
                v-model:breastVolume="currentStudent.breast_volume"
                v-model:amplua="currentStudent.amplua"
                v-model:playerNumber="currentStudent.player_number"
                v-model:login="currentStudent.login"
                v-model:password="currentStudent.password"
                v-model:startTraining="currentStudent.start_training"
                class=""
            />
            <new-documents-card 
                v-model:nameEng="currentStudent.name_eng"
                v-model:surnameEng="currentStudent.surname_eng"

                v-model:passport="currentStudent.passport"
                :passportPhotoArray="passportPhotos"
                @addPassportPhoto="passportPhotos.push($event); changed = true"
                
                v-model:idNumber="currentStudent.id_number"
                :idNumberPhotoArray="idPhotos"
                @addIdNumberPhoto="idPhotos.push($event); changed = true"

                v-model:passportEndDate="currentStudent.passport_end_date"

                :attorneyFileArray="attorneyFiles"
                @addAttorneyPhoto="attorneyFiles.push($event); changed = true"

                :photoForPassportArray="photoForPassport"
                @addPhotoForPassport="photoForPassport.push($event); changed = true"

                v-model:patronFirstName="currentStudent.patrons[0].name"
                v-model:patronFirstPhone="currentStudent.patrons[0].phone_number"

                @deletePhoto="ctx => deletePhoto(ctx.currentImage, ctx.currentPhotoType)"
                class=""
            />
        </div>

        <div class="ml-[15px]">
            <new-statistic-card 
                :trainingsMissedList="currentStudentMissedTrainings"
                :trainingsAttendedList="currentStudentAttendedTrainings"
                :groupId="currentStudent.group_id"
                class=""
            />
            <div v-if="financeBlockLoader">
                <new-finance-card 
                    v-if="groupObject.payment_status == 'paid'"
                    :balanceId="currentStudent.balance_id"
                    class=""
                />
                <div v-else style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] relative w-[400px] h-[305px] rounded-[10px] pt-[10px] mt-[15px] border-[1px] border-[#FFF] border-opacity-10">
    
                    <div class="flex flex-col items-center justify-center w-full absolute top-[50%] translate-y-[-50%]">
                        <div class="text-[#FFF]">
                            Фінансовий блок деактивований
                        </div>
                        <div class="text-[#FFF]">
                            Учень знаходиться в безоплатній групі
                        </div>
                    </div>
    
                </div>
            </div>
            <div v-else class="w-[400px] h-[305px]">
                <div class="z-50 fixed w-full h-full">
                    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[33%] ml-[-175px] mt-[-80px]"></div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import CircleProgress from "vue3-circle-progress"
    
    import InfoCard from "./card-components/InfoCard.vue"
    import ParametersCard from "./card-components/ParametersCard.vue"
    import DocumentaryCard from "./card-components/DocumentaryCard.vue"
    import StatsAndFinanceCard from "./card-components/StatsAndFinanceCard.vue"

    import NewInfoCard from "./card-components/NewInfoCard.vue"
    import NewParametersCard from "./card-components/NewParametersCard.vue"
    import NewDocumentsCard from "./card-components/NewDocumentsCard.vue"
    import NewStatisticCard from './card-components/NewStatisticCard.vue'
    import NewFinanceCard from './card-components/NewFinanceCard.vue'

    import ImageCropper from './ui/ImageCropper.vue'
    import ExitConfirmForm from "./ui/ExitConfirmForm.vue"

    import { ref, onBeforeMount } from "vue"
    import { updateStudent, getStudentById, getAllCompletedTrainingsByStudentId, getGroupById } from '@/desktopApp/services/apiRequests'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'student-card',

        props: {
            student: {
                type: Object,
                required: false
            }
        },

        setup(props, { emit }) {
            const currentStudent = ref(Object.assign({}, props.student))
            const studentPhoto = ref(null)
            const updateStudentFlag = ref(false)
            const updateStudentData = ref(false)
            const changed = ref(false)
            const bufferedStudent = ref({})
            const currentStudentMissedTrainings = ref([])
            const currentStudentAttendedTrainings = ref([])
            const screenWidth = ref(window.innerWidth)
            
            const financeBlockLoader = ref(false)

            const showImageCropper = ref(false)
            const imageToCrop = ref({
                src: '',
                type: ''
            })

            const passportPhotos = ref(currentStudent.value.passport_files ? currentStudent.value.passport_files : [])
            const idPhotos = ref(currentStudent.value.id_code_files ? currentStudent.value.id_code_files : [])
            const attorneyFiles = ref(currentStudent.value.attorney_child_files ? currentStudent.value.attorney_child_files : [])
            const photoForPassport = ref(currentStudent.value.photo_for_passport ? currentStudent.value.photo_for_passport : [])

            const groupObject = ref({})

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }
            
            const deletePhoto = (currentPhoto, currentPhotoType) => {
                changed.value = true
                if (currentPhotoType === 'passport') {
                    passportPhotos.value = passportPhotos.value.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'idNumber') {
                    idPhotos.value = idPhotos.value.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'attorney') {
                    attorneyFiles.value = attorneyFiles.value.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'photo_for_passport') {
                    photoForPassport.value = photoForPassport.value.filter((photo) => photo !== currentPhoto)
                }
            }

            const processImage = (image) => {
                studentPhoto.value = image
                showImageCropper.value = false
                changed.value = true
            }

            const closeCard = () => {

                if ((JSON.stringify(currentStudent.value) != JSON.stringify(bufferedStudent.value) || changed.value)
                    && !updateStudentData.value) {
                    updateStudentFlag.value = true
                } else {
                    emit('close')
                }
            }

            const updateStudentVM = async () => {
                const response = await updateStudent(currentStudent.value, studentPhoto.value, idPhotos.value, passportPhotos.value, attorneyFiles.value, photoForPassport.value)
                if (response.status == 200) {
                    updateStudentData.value = true
                    emit('updateUsers')
                    emit('close')
                }
            } 

            onBeforeMount(async () => {
                studentPhoto.value = process.env.VUE_APP_API_URL + 'static/' + currentStudent.value.photo
                if (props.student.group_id) {
                    const groupRes = await getGroupById(props.student.group_id)
                    groupObject.value = groupRes.data
                } else {
                    groupObject.value = {
                        payment_status: 'paid'
                    }
                }
                financeBlockLoader.value = true

                const studentRes = await getStudentById(props.student.student_id)
                const trainingsRes = await getAllCompletedTrainingsByStudentId(props.student.student_id)

                if (studentRes.status == 200) {
                    currentStudent.value = Object.assign({}, studentRes.data.student)
                    console.log('current student value!!!!!!!! -------- ', currentStudent.value)
                    bufferedStudent.value = Object.assign({}, studentRes.data.student)
                    studentPhoto.value = process.env.VUE_APP_API_URL + 'static/' + currentStudent.value.photo
                    passportPhotos.value = currentStudent.value.passport_files ? currentStudent.value.passport_files : []
                    idPhotos.value = currentStudent.value.id_code_files ? currentStudent.value.id_code_files : []
                    attorneyFiles.value = currentStudent.value.attorney_child_files ? currentStudent.value.attorney_child_files : []
                    photoForPassport.value = currentStudent.value.photo_for_passport ? currentStudent.value.photo_for_passport : []
                }
                currentStudentMissedTrainings.value = trainingsRes.missed_trainings
                currentStudentAttendedTrainings.value = trainingsRes.trainings_attended

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            return {
                currentStudent,
                studentPhoto,
                closeCard,
                updateStudentFlag,
                updateStudentData,
                updateStudentVM,
                loadImage,
                processImage,
                showImageCropper,
                imageToCrop,
                passportPhotos,
                idPhotos,
                attorneyFiles,
                deletePhoto,
                changed,
                photoForPassport,
                currentStudentMissedTrainings,
                currentStudentAttendedTrainings,
                screenWidth,
                groupObject,
                financeBlockLoader
            }
        },
        
        components: {
            CircleProgress,
            InfoCard,
            ParametersCard,
            DocumentaryCard,
            StatsAndFinanceCard,
            NewInfoCard,
            NewParametersCard,
            NewDocumentsCard,
            NewStatisticCard,
            NewFinanceCard,
            ImageCropper,
            ExitConfirmForm
        }
    }
</script>

<style lang="scss" scoped>
    // .student-card {
    //     font-family: 'Avenir Cyrillic';
    // }

    .block-title { 
        font-family: 'Avenir Cyrillic Bold';
    }
</style>