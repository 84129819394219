<template>
    <div>
        <div class="bg-[#BD9135] text-center mb-[30px] font-bold w-[90%] rounded-[3px] mx-auto text-black py-[5px]">
            СТВОРИТИ (норматив/вправу/завдання)
        </div>

        <ImageViewer v-if="openPictureURLFlag" :imageURL="openPictureURL" :imageDataURLFilename="imageDataURLFilename" :imageDataURL="openPictureDataURL" @close="openPictureURLFlag = false" @deleteAlreadySavedImage="deleteAlreadySavedImage" @deleteNewImage="deleteNewImage" />
        <div v-else class="w-[90%] mx-auto relative">
            <div class="absolute top-[-6px] flex w-full justify-start z-50">
                <div class="flex w-full flex-col items-start justify-start mt-[5px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">Тип елемента</div>
                    <select v-model="element_type" name="element_type" class="bg-[#252526] text-white outline-none text-[18px] w-[30%] py-[5px] px-[5px] rounded-[6px]" 
                    :class="{
                        'text-[#8C8C8C]': element_type === '',
                        'border-[#DA4167] border-[3px] ': !validation.elementType
                    }">
                        <option value="" disabled>Оберіть тип елемента</option>
                        <option class="text-white" value="standard">норматив</option>
                        <option class="text-white" value="exercise">завдання</option>
                        <option class="text-white" value="task">вправа</option>
                        <option class="text-white" value="warmup_ofp">розминка(офп)</option>
                        <option class="text-white" value="warmup_sfp">розминка(сфп)</option>
                        <option class="text-white" value="hitch_ofp">заминка(офп)</option>
                    </select>
                </div>
            </div>

            <div class="mt-[80px] w-full">
                <StandardForm 
                    v-model:elementName="elementName"
                    v-model:ageGroup="ageGroup"
                    v-model:description="description"
                    v-model:additionalImages="additionalImages"
                    v-model:schemas="schemas"
                    v-model:programId="programId"
                    v-model:scoreboardStructure="scoreboardStructure" 
                    v-model:scoreboardType="scoreboardType"
                    v-model:baseStandart="baseStandart"
                    :videos="videos"
                    @changeVideoLink="e => videos[e['id']] = e['value']"
                    @addNewVideoLink="e => videos.push(e)"
                    @deleteLink="e => videos.splice(e, 1)" 
                    :additionalImages="additionalImages"
                    @addNewImages="e => addNewAdditionalImages(e)"
                    :newAdditionalImages = "newAdditionalImages"
                    @openSavedPicture="openSavedPicture"
                    @openNewPicture="openNewPicture"

                    :validation="validation"

                    @addSchema="addNewSchema"
                    v-if="element_type == 'standard'"
                
                />            
                <ExerciseForm 
                    v-model:elementName="elementName"
                    v-model:ageGroup="ageGroup"
                    v-model:description="description"
                    v-model:additionalImages="additionalImages"
                    v-model:schemas="schemas"
                    v-model:programId="programId"
                    v-model:scoreboardStructure="scoreboardStructure" 
                    v-model:scoreboardType="scoreboardType"
                    :videos="videos"
                    @changeVideoLink="e => videos[e['id']] = e['value']"
                    @addNewVideoLink="e => videos.push(e)"
                    @deleteLink="e => videos.splice(e, 1)" 
                    :additionalImages="additionalImages"
                    @addNewImages="e => addNewAdditionalImages(e)"
                    :newAdditionalImages = "newAdditionalImages"
                    @openSavedPicture="openSavedPicture"
                    @openNewPicture="openNewPicture"

                    :validation="validation"

                    @addSchema="addNewSchema"
                    v-else-if="element_type == 'exercise'"
                />            
                <TaskForm 
                    v-model:elementName="elementName"
                    v-model:ageGroup="ageGroup"
                    v-model:description="description"
                    v-model:additionalImages="additionalImages"
                    v-model:schemas="schemas"
                    v-model:programId="programId"
                    v-model:scoreboardStructure="scoreboardStructure" 
                    v-model:scoreboardType="scoreboardType" 
                    v-model:time="elementTime"
                    v-model:elementDirection="elementDirection"
                    v-model:approachesNumber="approachesNumber"
                    v-model:approachesBreak="approachesBreak"
                    v-model:fieldWidth="fieldWidth"
                    v-model:fieldHeight="fieldHeight"
                    v-model:accent="accent"
                    :videos="videos"
                    :elementType="element_type"
                    @changeVideoLink="e => videos[e['id']] = e['value']"
                    @addNewVideoLink="e => videos.push(e)"
                    @deleteLink="e => videos.splice(e, 1)"
                    :additionalImages="additionalImages"
                    @addNewImages="e => addNewAdditionalImages(e)"
                    :newAdditionalImages = "newAdditionalImages"
                    @openSavedPicture="openSavedPicture"
                    @openNewPicture="openNewPicture"

                    :validation="validation"

                    @addSchema="addNewSchema"
                    v-else
                />            
            </div>

            <div @click="createElement" class="text-[#252526] bg-[#BD9135] mx-auto cursor-pointer mt-[100px] border-[1px] text-center border-[#BD9135] text-[18px] w-[300px] py-[3px] px-[5px] rounded-[6px]">Зберегти</div>
            <div @click="$router.push('/training-elements')" class="text-[#252526] bg-[#BD9135] mx-auto cursor-pointer mt-[30px] border-[1px] text-center border-[#BD9135] text-[18px] w-[300px] py-[3px] px-[5px] rounded-[6px]">Назад</div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch, reactive } from 'vue'
    import { useRouter } from 'vue-router'
    import { addTrainingElementRequest } from '@/desktopApp/services/apiRequests.js'
    
    import StandardForm from './components/StandardForm.vue'
    import ExerciseForm from './components/ExerciseForm.vue'
    import TaskForm from './components/TaskForm.vue'
    import ImageViewer from './components/ImageViewer.vue'

    function dataURLToFile(dataURL) {
        const arr = dataURL.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], 'image.jpeg', { type: mime })
        
    }

    function readFileAsDataURL(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                resolve(event.target.result)
            }
            reader.onerror = (error) => {
                reject(error)
            }
            reader.readAsDataURL(file)
        })
    }

    export default {
        name: 'ElementForm',

        setup() {
            const element_type = ref('task')
            const elementName = ref('')
            const scoreboardType = ref(null)
            const scoreboardStructure = reactive({
                minimal_value: null,
                maximal_value: null,
                middle_value: null
            })
            const ageGroup = ref('')
            const videos = ref([])
            const description = ref('')

            const additionalImages = ref([])
            const schemas = ref([])
            const programId = ref('')
            const elementTime = ref(null)
            const newAdditionalImages = ref([])

            const router = useRouter()

            const openPictureURL = ref('')
            const openPictureDataURL = ref('')
            const openPictureURLFlag = ref(false)
            const imageDataURLFilename = ref('')

            const elementDirection = ref('')

            const approachesNumber = ref(null)
            const approachesBreak = ref(null)

            const fieldWidth = ref(null)
            const fieldHeight = ref(null)

            const accent = ref(null)

            const baseStandart = ref(null)

            const validation = ref({
                elementName: true,
                ageGroup: true,
                description: true,
                scoreboardStructure: true,
                time: true,
                elementType: true,
                elementDirection: true,
                approachesNumber: true,
                approachesBreak: true,
                baseStandart: true
            })

            watch(baseStandart, () => {
                console.log('baseStandart.value', baseStandart.value)
                if (element_type.value === 'standard') {
                    if (baseStandart.value === null) {
                        validation.value.baseStandart = false
                    } else {
                        validation.value.baseStandart = true
                    }
                }
            })

            watch(elementName, () => {
                if (elementName.value.length > 0) {
                    validation.value.elementName = true
                } else {
                    validation.value.elementName = false
                }
            })

            watch(ageGroup, () => {
                if (ageGroup.value.length > 0) {
                    validation.value.ageGroup = true
                } else {
                    validation.value.ageGroup = false
                }
            })

            watch(description, () => {
                if (description.value.length > 0) {
                    validation.value.description = true
                } else {
                    validation.value.description = false
                }
            })

            watch(element_type, () => {
                console.log('element_type.value', scoreboardType.value)
                if ((element_type.value === 'standard' || element_type.value === 'exercise') && scoreboardType.value != 'point') {
                    if (scoreboardStructure.minimal_value === null || 
                        scoreboardStructure.maximal_value === null || 
                        scoreboardStructure.middle_value === null) {
                        validation.value.scoreboardStructure = false
                    }
                }
            })

            watch(() => scoreboardStructure, () => {
                if (element_type.value === 'standard' || element_type.value === 'exercise') {
                    if (scoreboardStructure.minimal_value === null || 
                        scoreboardStructure.maximal_value === null || 
                        scoreboardStructure.middle_value === null) {
                        validation.value.scoreboardStructure = false
                    } else {
                        validation.value.scoreboardStructure = true
                    }
                }
            })

            watch(elementTime, () => {
                if (element_type.value === 'task' || element_type.value === '') {
                    if (elementTime.value === null) {
                        validation.value.time = false
                    } else {
                        validation.value.time = true
                    }
                }
            })

            watch(elementDirection, () => {
                if (element_type.value === 'task' || element_type.value === '') {
                    if (elementDirection.value.length === 0) {
                        validation.value.elementDirection = false
                    } else {
                        validation.value.elementDirection = true
                    }
                }
            })

            watch(element_type, () => {
                if (element_type.value.length > 0) {
                    validation.value.elementType = true
                } else {
                    validation.value.elementType = false
                }
            })

            // watch(approachesNumber, () => {
            //     if (approachesNumber.value === null) {
            //         validation.value.approachesNumber = false
            //     } else {
            //         validation.value.approachesNumber = true
            //     }
            // })

            // watch(approachesBreak, () => {
            //     if (approachesBreak.value === null) {
            //         validation.value.approachesBreak = false
            //     } else {
            //         validation.value.approachesBreak = true
            //     }
            // })

            const checkValidation = () => {
                let result = true
                if (elementName.value.length === 0) {
                    validation.value.elementName = false
                    result = false
                }
                if (ageGroup.value.length === 0) {
                    validation.value.ageGroup = false
                    result = false
                }
                if (description.value.length === 0) {
                    validation.value.description = false
                    result = false
                }
                if ((element_type.value === 'standard' || element_type.value === 'exercise') && scoreboardType.value != 'point') {
                    if (scoreboardStructure.minimal_value === null || 
                        scoreboardStructure.maximal_value === null || 
                        scoreboardStructure.middle_value === null) {
                        validation.value.scoreboardStructure = false
                        result = false
                    }
                }
                if (element_type.value === 'task' || element_type.value === '') {
                    if (elementTime.value === null) {
                        validation.value.time = false
                        result = false
                    }
                }
                if (element_type.value.length === 0) {
                    validation.value.elementType = false
                    result = false
                }
                if (element_type.value === 'task' || element_type.value === '') {
                    if (elementDirection.value.length === 0) {
                        validation.value.elementDirection = false
                        result = false
                    }
                }
                if (element_type.value === 'standard') {
                    if (baseStandart.value === null) {
                        validation.value.baseStandart = false
                    } else {
                        validation.value.baseStandart = true
                    }
                }
                // if (approachesNumber.value === null) {
                //     validation.value.approachesNumber = false
                //     result = false
                // }
                // if (approachesBreak.value === null) {
                //     validation.value.approachesBreak = false
                //     result = false
                // }

                return result
            }

            const addNewElement = async () => {
                if (!checkValidation()) {
                    return false
                }
                let mappedQualityCompressedImages = []
                for (let i = 0; i < newAdditionalImages.value.length; i++) {
                    const e = newAdditionalImages.value[i]
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    const fileURL = await readFileAsDataURL(e)
                    const img = await onLoadImg(fileURL)
                    console.log('fileURL -------- ', fileURL)
                    
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const resultDataUrl = canvas.toDataURL('image/jpeg', 0.9);
                    console.log('resultDataUrl', resultDataUrl);
                    const blob = dataURLToFile(resultDataUrl);
                    mappedQualityCompressedImages.push(blob);
                }

                const result = await addTrainingElementRequest({
                    element_id: '',
                    type: element_type.value,
                    name: elementName.value,
                    info: description.value,
                    additional_videos: videos.value,
                    additional_images: [],
                    schemas: [],
                    training_program_years: ageGroup.value,
                    score_board_type: scoreboardType.value,
                    time: elementTime.value,
                    tags: [],
                    minimal_value: scoreboardStructure.minimal_value,
                    maximal_value: scoreboardStructure.maximal_value,
                    middle_value: scoreboardStructure.middle_value,
                    element_direction: elementDirection.value,
                    approaches_number: approachesNumber.value,
                    approaches_break: approachesBreak.value,
                    field_width: fieldWidth.value,
                    field_height: fieldHeight.value,
                    accent: accent.value,
                    base_standard_id: baseStandart.value
                }, mappedQualityCompressedImages)

                console.log('result', result)
                return result
            }

            const createElement = async () => {
                const result = await addNewElement()
                if (result) {
                    router.push('/training-elements')
                }
            }

            const onLoadImg = async (e) => {
                return new Promise((resolve, reject) => {
                    const img = new Image()
                    img.src = e
                    img.onload = () => {
                        resolve(img)
                    }
                })
            }

            watch(element_type, () => {
                elementTime.value = null
                elementDirection.value = ''
                approachesNumber.value = null
                approachesBreak.value = null
                fieldWidth.value = null
                fieldHeight.value = null
                accent.value = null
                scoreboardType.value = null
                scoreboardStructure.minimal_value = null
                scoreboardStructure.maximal_value = null
                scoreboardStructure.middle_value = null
            })

            const addNewAdditionalImages = (e) => {
                newAdditionalImages.value.push(...e)
                console.log('newAdditionalImages.value', newAdditionalImages.value)
            }

            const openSavedPicture = (e) => {
                console.log('openSavedPicture -------- ', e)
                openPictureDataURL.value = ''
                openPictureURL.value = e
                openPictureURLFlag.value = true
            }

            const openNewPicture = async (e) => {
                console.log('openNewPicture -------- ', e)
                openPictureURL.value = ''
                imageDataURLFilename.value = e.name
                openPictureDataURL.value = await readFileAsDataURL(e)
                openPictureURLFlag.value = true
            }

            const deleteAlreadySavedImage = (e) => {
                additionalImages.value = additionalImages.value.filter(el => el !== e)
            }

            const deleteNewImage = (e) => {
                console.log('deleteNewImage -------- ', e)
                console.log('newAdditionalImages.value -------- ', newAdditionalImages.value)
                newAdditionalImages.value = newAdditionalImages.value.filter(el => el.name !== e)
            }

            const addNewSchema = async () => {
                console.log('addNewSchema -------- ', 111)
                const result = await addNewElement()
                const elementId = result.element_id
                router.push(`/training-schemes/${elementId}`)
                console.log('addNewSchema -------- ', result)
            }

            return {
                element_type,
                elementName,
                scoreboardType,
                scoreboardStructure,
                ageGroup,
                videos,
                description,
                additionalImages,
                schemas,
                programId,
                createElement,
                elementTime,
                newAdditionalImages,
                addNewAdditionalImages,
                openSavedPicture,
                openNewPicture,
                openPictureURL,
                openPictureDataURL,
                openPictureURLFlag,
                deleteAlreadySavedImage,
                deleteNewImage,
                imageDataURLFilename,
                addNewSchema,
                validation,
                elementDirection,
                approachesNumber,
                approachesBreak,
                fieldWidth,
                fieldHeight,
                accent,
                baseStandart
            }
        },

        components: {
            StandardForm,
            ExerciseForm,
            TaskForm,
            ImageViewer
        }
    }
</script>
