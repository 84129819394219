<template>
    <MissedTrainingReasonView class="bg-[#252526] absolute z-[999] h-full w-full" v-if="currentMissedTrainingFlag" @close="currentMissedTrainingFlag = false" :missedTraining="currentMissedTraining" />
    <div :style="{display: trainingSummaryFlag ? 'none' : 'block',}">
        <div>
            <div @click="$router.push('/select-group-for-training')" class="flex text-[#BD9135] items-center ml-3 mt-2">
                <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                <div class="ml-2">
                    Назад
                </div>
            </div>
            
        </div>
        <button @click="trainingSummaryFlag = true" v-if="store.currentTraining.summary?.name" class="bg-[#BD9135] mb-2 mt-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
            <div class="w-full text-white font-semibold">До конспекта</div>
    
            <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#fff"/>
            </svg>
        </button>
        <div class="px-4 mt-3 overflow-y-auto whitespace-nowrap">
            <div v-for="student in studentsWithStatuses" class="flex mb-2">
                <div class="w-[70%] bg-[#474849] text-white flex justify-between pr-[20px] text-[18px] py-[10px] pl-2 rounded-l-[10px] font-semibold">
                    <div>
                        {{ student.name }}
                    </div>
                    <div v-if="checkBirthday(student.birthday)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 11V15C4 18.2998 4 19.9497 5.02513 20.9749C6.05025 22 7.70017 22 11 22H13C16.2998 22 17.9497 22 18.9749 20.9749C20 19.9497 20 18.2998 20 15V11" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 9C3 8.25231 3 7.87846 3.20096 7.6C3.33261 7.41758 3.52197 7.26609 3.75 7.16077C4.09808 7 4.56538 7 5.5 7H18.5C19.4346 7 19.9019 7 20.25 7.16077C20.478 7.26609 20.6674 7.41758 20.799 7.6C21 7.87846 21 8.25231 21 9C21 9.74769 21 10.1215 20.799 10.4C20.6674 10.5824 20.478 10.7339 20.25 10.8392C19.9019 11 19.4346 11 18.5 11H5.5C4.56538 11 4.09808 11 3.75 10.8392C3.52197 10.7339 3.33261 10.5824 3.20096 10.4C3 10.1215 3 9.74769 3 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linejoin="round"/>
                            <path d="M6 3.78571C6 2.79949 6.79949 2 7.78571 2H8.14286C10.2731 2 12 3.7269 12 5.85714V7H9.21429C7.43908 7 6 5.56091 6 3.78571Z" stroke="#DA4167" stroke-width="1.5" stroke-linejoin="round"/>
                            <path d="M18 3.78571C18 2.79949 17.2005 2 16.2143 2H15.8571C13.7269 2 12 3.7269 12 5.85714V7H14.7857C16.5609 7 18 5.56091 18 3.78571Z" stroke="#DA4167" stroke-width="1.5" stroke-linejoin="round"/>
                            <path d="M12 11L12 22" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div v-if="student.missedTrainings" @click="() => {currentMissedTrainingFlag = true; currentMissedTraining = student.missedTrainings}">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 13.5H14.5M7.5 8.5H11" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.1706 19.8905C17.3536 19.6125 20.6856 16.2332 20.9598 11.9909C21.0134 11.1607 21.0134 10.3009 20.9598 9.47072C20.6856 5.22838 17.3536 1.84913 13.1706 1.57107C11.7435 1.47621 10.2536 1.47641 8.8294 1.57107C4.64639 1.84913 1.31441 5.22838 1.04024 9.47072C0.986587 10.3009 0.986587 11.1607 1.04024 11.9909C1.1401 13.536 1.82343 14.9666 2.62791 16.1746C3.09501 17.0203 2.78674 18.0758 2.30021 18.9978C1.94941 19.6626 1.77401 19.995 1.91484 20.2351C2.05568 20.4752 2.37026 20.4829 2.99943 20.4982C4.24367 20.5285 5.08268 20.1757 5.74868 19.6846C6.1264 19.4061 6.31527 19.2668 6.44544 19.2508C6.5756 19.2348 6.83177 19.3403 7.34401 19.5513C7.8044 19.7409 8.33896 19.8579 8.8294 19.8905C10.2536 19.9852 11.7435 19.9854 13.1706 19.8905Z" stroke="#C59014" stroke-width="1.5" stroke-linejoin="round"/>
                        </svg>

                    </div>
                </div>
                <div v-if="student.status == 'present'" @click="student.status = 'missing'" class="w-[30%] text-white font-semibold text-center align-middle flex justify-center items-center rounded-r-[10px] bg-[#339989]">
                    Присутній
                </div>
                <div v-else-if="student.status == 'missing'" @click="student.status = 'present'" class="w-[30%] text-white font-semibold text-center align-middle flex justify-center items-center rounded-r-[10px] bg-[#DA4167]">
                    Відсутній
                </div>
            </div>        
        </div>
    
        <div class="w-full py-3 rounded-t-[10px]">    
            <div class="text-[#DA4167] text-center text-[18px] font-semibold">
                Гравців на тренуванні — {{ studentsWithStatuses.filter(student => student.status == 'present').length }}
            </div>
            <div class="mt-[30px]">
                <button @click="additionalStudentsFlag = true" class="bg-[#BD9135] mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                    <div class="w-full text-[#252526] font-semibold">Додати учня</div>
    
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#252526"/>
                    </svg>
                </button>
                <button v-if="!store.currentTraining.summary?.name" @click="startTraining" class="bg-[#339989] mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                    <div class="w-full text-white font-semibold">Провести тренування</div>

                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#fff"/>
                    </svg>
                </button>
            </div>
        </div>
    
        <div v-if="additionalStudentsFlag" class="fixed top-0 left-0 w-full h-full bg-[#252526]">
            <div class="relative">
                <div>
                    <div @click="additionalStudentsFlag = false" class="flex text-[#BD9135] items-center ml-3 mt-2">
                        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                        <div class="ml-2">
                            Назад
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-[#FFF] text-[18px] text-center">Додаткові учні</div>
            <div class="flex justify-center mt-[10px]">
                <input v-model="newStudentInput" type="text" class="bg-transparent border-[2px] border-[#FFF] px-3 py-2 w-[92%] rounded-[9px] placeholder:text-[#FFF] placeholder:opacity-60 outline-none select-none text-[#FFF]" placeholder="Ім'я та прізвище учня">
            </div>
            <div class="relative">
                <div v-if="visibleStudents.length > 0" class="flex flex-col items-center absolute w-full h-[300px] overflow-y-auto">
                    <div v-for="student in visibleStudents" @click="addStudent(student)" class="text-[#252526] bg-[#FFF] px-3 py-3 border-b-[1px] border-[#252526] w-[92%]">
                        <div class="text-[18px]">{{ student.name + ' ' + student.surname }}</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col items-center mt-[20px]">
                <div v-if="additionalStudents.length > 0" v-for="student in additionalStudents" class="flex mb-2 w-[92%]">
                    <div class="w-[70%] bg-[#252526] text-[18px] py-[10px] pl-2 rounded-l-[10px] font-semibold">
                        {{ student.name + ' ' + student.surname }}
                    </div>
                    <div @click="deleteAdditionalStudent(student)" class="w-[30%] text-white font-semibold text-center align-middle flex justify-center items-center rounded-r-[10px] bg-[#DA4167]">
                        Видалити
                    </div>
                </div>   
                <div v-else>
                    <div class="text-[#BD9135] text-[18px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">Немає доданих учнів</div>
                </div>
            </div>
        </div>
    </div>
    <TrainingSummaryView 
        v-if="store.currentTraining.summary?.name" 
        @close="trainingSummaryFlag = false" 
        @saveTraining="startTraining" 
        :style="{display: trainingSummaryFlag ? 'block' : 'none',}"  
        :training="store.currentTraining"
    />
</template>

<script>
    import { onMounted, ref, onBeforeUnmount, onUpdated, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../stores/mainStore'
    import { processPresentStudents, processMissedStudents, updateTrainingStatus, getAllStudentsRequest, getMissedTrainingsByStudentId } from '../services/apiRequests'
    import TrainingSummaryView from './TrainingSummaryView.vue'
    import MissedTrainingReasonView from './MissedTrainingReasonView.vue'

    export default {
        name: 'StudentRollCallView',

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const studentsWithStatuses = ref([])

            const trainingSummaryFlag = ref(false)

            const currentMissedTraining = ref(null)
            const currentMissedTrainingFlag = ref(false)

            const startTraining = async () => {
                const presentStudents = []
                const missedStudents = []

                studentsWithStatuses.value.forEach(student => {
                    if (student.status == 'present') {
                        presentStudents.push(student.student_id)
                    } else {
                        missedStudents.push(student.student_id)
                    }
                })
                
                await processPresentStudents(presentStudents, store.currentTraining.training_id)
                await processMissedStudents(missedStudents, store.currentTraining.training_id)
                await updateTrainingStatus(store.currentTraining.training_id, 'completed')   

                store.allTrainings = []

                router.push('/wish-good-luck-on-training')
            }

            const allStudents = ref([])
            const visibleStudents = ref([])
            const newStudentInput = ref('')
            const getAllStudents = async () => {
                const response = await getAllStudentsRequest(newStudentInput.value)
                allStudents.value = response.data.filter(student => {
                    return !store.currentTrainingStudentsList.some(currentStudent => currentStudent.student_id == student.student_id)
                })
            }

            watch(newStudentInput, async (value) => {
                const response = await getAllStudentsRequest(newStudentInput.value)
                allStudents.value = response.data.filter(student => {
                    return !store.currentTrainingStudentsList.some(currentStudent => currentStudent.student_id == student.student_id)
                })
                if (value.length > 0) {
                    visibleStudents.value = allStudents.value.filter(student => {
                        return student.name.toLowerCase().includes(value.toLowerCase()) || student.surname.toLowerCase().includes(value.toLowerCase())
                    })
                } else {
                    visibleStudents.value = []
                }
            })
            const additionalStudentsFlag = ref(false)
            const additionalStudents = ref([])
            const addStudent = (student) => {
                const buffer = [...studentsWithStatuses.value]
                buffer.push({
                    name: student.name + ' ' + student.surname,
                    student_id: student.student_id,
                    birthday: student.birthday,
                    status: 'missing'
                })
                studentsWithStatuses.value = buffer
                additionalStudents.value.push(student)
                visibleStudents.value = []
                newStudentInput.value = ''
            }

            const deleteAdditionalStudent = (additionalStudent) => {
                const buffer = [...studentsWithStatuses.value]
                const index = buffer.findIndex(student => additionalStudent.student_id == student.student_id)
                buffer.splice(index, 1)
                studentsWithStatuses.value = buffer
                additionalStudents.value = additionalStudents.value.filter(currentStudent => currentStudent.student_id != additionalStudent.student_id)
            }

            const checkBirthday = (birthday) => {
                const date = new Date(birthday)
                const currentDate = new Date()
                return date.getDate() == currentDate.getDate() && date.getMonth() == currentDate.getMonth()
            }

            onMounted(async () => {
                const buffer = [...store.currentTrainingStudentsList]

                studentsWithStatuses.value = await Promise.all(buffer.map(async student => {
                    const response = await getMissedTrainingsByStudentId(student.student_id)
                    const allMissedTrainings = response.data.filter(training => training.training_id == store.currentTraining.training_id)
                    if (allMissedTrainings.length > 0) {
                        return {
                            name: student.name + ' ' + student.surname,
                            student_id: student.student_id,
                            birthday: student.birthday,
                            status: 'missing',
                            missedTrainings: allMissedTrainings[0]
                        }
                    } else {
                        return {
                            name: student.name + ' ' + student.surname,
                            student_id: student.student_id,
                            birthday: student.birthday,
                            status: 'present',
                            missedTrainings: null
                        }
                    }
                }))

                // studentsWithStatuses.value = buffer.map(student => {

                //     return {
                //         name: student.name + ' ' + student.surname,
                //         student_id: student.student_id,
                //         birthday: student.birthday,
                //         status: 'present'
                //     }
                // })
                await getAllStudents()
            })

            return {
                store,
                studentsWithStatuses,
                startTraining,
                allStudents,
                newStudentInput,
                visibleStudents,
                additionalStudents,
                addStudent,
                additionalStudentsFlag,
                deleteAdditionalStudent,
                checkBirthday,
                trainingSummaryFlag,
                currentMissedTraining,
                currentMissedTrainingFlag
            }
        },
        components: {
            TrainingSummaryView,
            MissedTrainingReasonView
        }
    }
</script>
