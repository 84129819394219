<template>
    <div @click="processExit" style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[10px] fixed w-full h-[130%] top-[-70px] -mt-2 z-20"></div>
    <div v-if="chooseTrainerAlert" class="absolute top-[50%] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-4 bg-[#252526] rounded-[9px] ml-[100px] z-[150] w-[250px] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div class="text-center mb-3 text-[#FFF]">Перед внесенням учнів у групу, необхідно вибрати тренера</div>
        <div class="flex justify-center">
            <button @click="chooseTrainerAlert = false" class="bg-[#DA4167] hover:bg-opacity-90 text-white px-4 py-1 rounded-[10px]">OK</button>
        </div>
    </div>
    
    <exit-confirm-form
        v-if="exitConfirmFlag && loadedData"
        :alertText="'Бажаєте зберегти зміни у картці групи ' + groupName"
        class="absolute top-[50%] ml-[110px] z-40 w-[260px] left-[50%] translate-x-[-50%] translate-y-[-50%]"

        @returnToCurrent="exitConfirmFlag = false"
        @dontSave="$emit('close')"
        @doSave="updateGroupVM()"
    />
    <select-students-form 
        v-if="selectStudentsFlag"
        :group="group"
        :trainer="trainer"
        class="z-40"
        @close="selectStudentsFlag = false; updateGroup()"
    />
    <div v-if="!loadedData" class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] ml-[90px] z-40"></div>
    <div v-if="!exitConfirmFlag && loadedData && !selectStudentsFlag" class="z-40">

        <div style="box-shadow: 4px 4px 4px 0px #00000040;" :class="{'scale-[.8]' : screenWidth < 1500, 'scale-[1]' : screenWidth >= 1500}" class="border-[1px] border-[#8C8C8C33] bg-[#252526] pt-[23px] rounded-[10px] px-[15px] w-[691px] h-[556px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] z-50">
            <div class="flex justify-center mb-[15px]"><img src="../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center px-[20px] text-[24px] text-[#fff] leading-[19px] mb-[10px]">{{ groupName }}</div>
            <div class="font-light text-center px-[20px] text-[16px] text-[#fff] leading-[19px] mb-[10px]">(Учнів - {{ studentsCount }})</div>

            <div class="flex justify-between">
                <div class="w-[48%]">
                    <div class="w-full">
                        <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                            назва нової групи
                        </div>
                        <div class="mt-[3px]">
                            <input 
                                v-model="groupName"
                                class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[3px] pl-[5px] outline-none"
                                type="text" 
                                name="name"
                            >
                        </div>
                    </div>
        
                    <div class="w-full mt-[20px]">
                        <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                            діапазон років народження учнів
                        </div>
                        <div class="mt-[3px]">
                            <input 
                                v-model="groupPeriods"
                                class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[3px] pl-[5px] outline-none"
                                type="text" 
                                name="name"
                            >
                        </div>
                    </div>
        
                    <div class="w-full mt-[20px]">
                        <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                            оберіть тренера зі списку
                        </div>
                        <select :value="trainerId" @change="e => selectNewTrainer(e.target.value)" class="mt-[3px] w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[5.5px] pl-[5px] outline-none" name="to_balance">
                            <option class="bg-[#252526] text-[#fff]" value="" disabled>Виберіть тренера</option>
                            <option v-for="trainer in trainers" :value="trainer.trainer_id" class="bg-[#252526] text-[#fff]">{{ trainer.name + ' ' + trainer.surname }}</option>
                        </select>
                    </div>
        
                    <div class="w-full mt-[20px] flex items-center justify-between">
                        <div class="text-[#8C8C8C] text-center font-medium relative top-[5px] text-[14px]">
                            Група 
                            <span v-if="groupPaymentStatusBool" class="text-[#8FC408]">платна</span>
                            <span v-else class="text-[#FA1367]">безоплатна</span>
                        </div>
                        <div>
                            <IphoneSwitch :isOnValue="groupPaymentStatusBool" @change="changeGroupPaymentStatus"/>
                        </div>
                    </div>

                    <div class="w-full mt-[20px] flex items-center justify-between">
                        <div class="text-[#8C8C8C] text-center font-medium relative top-[5px] text-[14px]">
                            Картки гравців 
                            <span v-if="fullStudentsCardFlag" class="text-[#8FC408]">розширена</span>
                            <span v-else class="text-[#FA1367]">звичайна</span>
                        </div>
                        <div>
                            <IphoneSwitch :isOnValue="fullStudentsCardFlag" @change="fullStudentsCardFlag = !fullStudentsCardFlag"/>
                        </div>
                    </div>
    
                    <div class="w-full mt-[10px]">
                        <input v-model="programName" class="mt-[3px] w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[4px] pl-[5px] outline-none" placeholder="Назва програми">
                        <div class="absolute w-[90%] z-[50]">
                            <div v-if="searchedPrograms.length > 0" v-for="program in searchedPrograms" @click="addProgramToGroup(program)" class="bg-[#9E9E9E] px-[5px] py-[2px] cursor-pointer hover:bg-[#929292] w-[50%]">{{program.name}}</div>
                        </div>
                        
                        <div class="mt-[10px] flex h-[25px] w-[100%] overflow-x-auto scrolltab">
                            <div v-if="addedPrograms.length == 0" class="text-center w-full text-white opacity-30">Немає доданих програм</div>
                            <div v-else v-for="program in addedPrograms" class="mr-[5px]">
                                <div class="px-[7px] flex items-center justify-between py-[1px] bg-[#9E9E9E] truncate rounded-full text-[11px] w-[90px] text-center">
                                    <div class="truncate w-[70%]">{{ program.name }}</div>
                                    <img @click="removeProgramFromGroup(program)" src="@/desktopApp/assets/icons/cross-icon.svg" class="w-[10px] rotate-45 cursor-pointer">
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
    
                <div class="w-[48%]">
                    <div class="relative mx-auto h-[234px] border-[1px] border-[#9E9E9E] mt-[20px] rounded-[6px]">
                        <div v-if="selectTrainerFlag" class="w-full h-full relative">
                            <div class="flex justify-center items-center h-full">
                                <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                                    інформація про тренера
                                </div>
                            </div>
                            <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] top-[40px] rotate-[45deg]"></div>
                            <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] bottom-[40px] right-0 rotate-[45deg]"></div>
                            <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] bottom-[40px] left-0 rotate-[-45deg]"></div>
                            <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] top-[40px] right-0 rotate-[-45deg]"></div>
                        </div>
        
                        <div v-else class="absolute w-full bottom-0">
                            <div class="h-[150px] w-full flex justify-center -z-10">
                                <img :key="trainerPictureKey" :src="trainerPicture" @error="$event.target.src = require('@/desktopApp/assets/photo-placeholder-big.png')" class="-z-10 relative ">
                            </div>
                            <div class="bg-[#BD9135] w-full py-1 mt-[-20px] z-20 text-white">
                                <div class="font-medium text-[23px] text-center">{{ trainer ? trainer.name + ' ' + trainer.surname : "Тренер" }}</div>
                                <div class="font-medium text-[23px] relative bottom-[3px] text-center">{{ groupName.length > 0 ? groupName : 'Група' }}</div>
                                <div class="text-center">({{ groupPeriods ? groupPeriods : "? - ?" }})</div>
                            </div>
                        </div>
                    </div>
        
                    <button @click="openStudentAddingForm" class="w-full rounded-[6px] bg-[#8FC408] text-[13px] text-[#252526] py-[6px] mt-[20px]">
                        Управління учнями
                    </button>
                </div>
            </div>

        </div>
    </div>


</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { getTrainerById, getGroupById, getStudentsCountByGroupId, updateGroupRequest, getAllTrainers, getProgramsByName, deleteProgramsFromGroup, addTrainingProgramsToGroup, getProgramsByGroupId } from '@/desktopApp/services/apiRequests'

    import DataInput from '../ui/DataInput.vue'
    import CoachSelectionForm from '../ui/CoachSelectionForm.vue'
    import SelectStudentsForm from './SelectStudentsForm.vue'
    import IphoneSwitch from '../ui/IphoneSwitch.vue'

    import ExitConfirmForm from '../ui/ExitConfirmForm.vue'

    export default {
        name: 'group-full-card',

        props: {
            group: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const selectStudentsFlag = ref(false)
            
            const groupClone = ref(Object.assign({}, props.group))
            
            const groupName = ref(groupClone.value.name)
            const groupPeriods = ref(groupClone.value.year_range)
            
            const trainer = ref({})
            const trainerPicture = ref('')

            let bufferGroupName = ''
            let bufferGroupPeriods = ''
            let bufferGroupPaymentStatusText = ''
            let bufferGroupTrainer = {}

            const trainerFullName = ref('')

            const screenWidth = ref(window.innerWidth)

            const trainers = ref([])
            const trainerId = ref('')
            const trainerPictureKey = ref(uuid.v4())

            const groupPaymentStatusBool = ref(props.group.payment_status && props.group.payment_status == 'paid' ? true : false)
            const groupPaymentStatusText = ref(props.group.payment_status && props.group.payment_status == 'paid' ? 'paid' : 'free')

            const loadedData = ref(false)
            const chooseTrainerAlert = ref(false)

            const programName = ref('')
            const searchedPrograms = ref([])
            const addedPrograms = ref([])
            const cachedPrograms = ref([])

            const fullStudentsCardFlag = ref(false)

            const addProgramToGroup = (program) => {
                addedPrograms.value.push(program)
                programName.value = ''
                searchedPrograms.value = []
            }

            const removeProgramFromGroup = (program) => {
                addedPrograms.value = addedPrograms.value.filter(p => p.id != program.id)
            }

            watch(programName, async (value) => {
                if (value.length > 0) {
                    const response = await getProgramsByName(value)
                    searchedPrograms.value = response
                } else {
                    searchedPrograms.value = []
                }
            })

            const openStudentAddingForm = () => {
                if (trainer.value?.trainer_id) {
                    selectStudentsFlag.value = true
                } else {
                    chooseTrainerAlert.value = true
                }
            }

            const updateGroupVM = async () => {
                const deletedElements = cachedPrograms.value.filter(program => !addedPrograms.value.some(p => p.id == program.id))
                const addedElements = addedPrograms.value.filter(program => !cachedPrograms.value.some(p => p.id == program.id))

                console.log('deletedElements ------- ', deletedElements)
                console.log('addedElements ------- ', addedElements)

                if (deletedElements.length > 0) {
                    await deleteProgramsFromGroup(props.group.group_id, deletedElements.map(p => p.id))
                }

                if (addedElements.length > 0) {
                    await addTrainingProgramsToGroup(props.group.group_id, addedElements.map(p => p.id))
                }

                const res = await updateGroupRequest(
                    props.group.group_id,
                    groupName.value,
                    groupPeriods.value,
                    trainer.value ? trainer.value.trainer_id : '',
                    groupPaymentStatusText.value,
                    fullStudentsCardFlag.value ? 'full' : 'simple'
                )

                console.log(res)
                if (res.status === 200) {
                    emit('close')
                    emit('updateGroup')
                }
            }

            const processExit = () => {
                if ((bufferGroupName !== groupName.value || bufferGroupPeriods !== groupPeriods.value || bufferGroupTrainer?.trainer_id !== trainer.value?.trainer_id || bufferGroupPaymentStatusText !== groupPaymentStatusText.value || addedPrograms.value != cachedPrograms.value) && loadedData.value) {
                    exitConfirmFlag.value = true
                } else {
                    emit('close')
                }
            }

            const selectNewTrainerFlag = ref(false)

            const exitConfirmFlag = ref(false)

            const selectNewTrainer = (newTrainerId) => {
                const newTrainer = trainers.value.find(trainer => trainer.trainer_id == newTrainerId)
                trainer.value = newTrainer
                trainerId.value = newTrainerId
                trainerPicture.value = process.env.VUE_APP_API_URL + 'static/' + newTrainer.photo
                selectNewTrainerFlag.value = false
                trainerFullName.value = trainer.value.name + ' ' + trainer.value.surname
                trainerPictureKey.value = uuid.v4()
            }
            
            const studentsCount = ref(0)

            const updateGroup = async () => {
                const res = await getGroupById(props.group.group_id)
                groupClone.value = res.data
                const studentsCountRes = await getStudentsCountByGroupId(props.group.group_id)
                studentsCount.value = studentsCountRes.data
            }

            onMounted(async () => {
                try {
                    const res = await getTrainerById(props.group.trainer_id)
                    trainer.value = res.data
                } catch (e) {
                    trainer.value = null
                }
                const studentsCountRes = await getStudentsCountByGroupId(props.group.group_id)
                studentsCount.value = studentsCountRes.data
                trainerFullName.value = trainer.value?.name + ' ' + trainer.value?.surname
                trainerPicture.value = process.env.VUE_APP_API_URL + 'static/' + trainer.value?.photo
                trainerId.value = trainer.value?.trainer_id
            
                bufferGroupName = groupName.value
                bufferGroupPeriods = groupPeriods.value
                bufferGroupTrainer = Object.assign({}, trainer.value)
                bufferGroupPaymentStatusText = groupPaymentStatusText.value + ''

                const programsRes = await getProgramsByGroupId(props.group.group_id)
                console.log(programsRes)
                addedPrograms.value = Object.assign([], programsRes)
                cachedPrograms.value = Object.assign([], programsRes)

                fullStudentsCardFlag.value = props.group.student_card_type == 'full'

                const response = await getAllTrainers()
                response.data.forEach(trainer => {
                    trainers.value.push({
                        trainer_id: trainer.trainer_id,
                        name: trainer.name,
                        surname: trainer.surname,
                        photo: trainer.photo
                    })
                })

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                loadedData.value = true
            })

            const changeGroupPaymentStatus = () => {
                groupPaymentStatusBool.value = !groupPaymentStatusBool.value
                if (groupPaymentStatusBool.value) {
                    groupPaymentStatusText.value = 'paid'
                } else {
                    groupPaymentStatusText.value = 'free'
                }
            }

            return {
                selectStudentsFlag,
                groupName,
                groupPeriods,
                trainer,
                updateGroup,
                studentsCount,
                trainerPicture,
                selectNewTrainer,
                selectNewTrainerFlag,
                trainerFullName,
                exitConfirmFlag,
                processExit,
                updateGroupVM,
                loadedData,
                trainers,
                trainerId,
                trainerPictureKey,
                groupPaymentStatusBool,
                groupPaymentStatusText,
                changeGroupPaymentStatus,
                openStudentAddingForm,
                chooseTrainerAlert,
                screenWidth,
                programName,
                searchedPrograms,
                addedPrograms,
                addProgramToGroup,
                removeProgramFromGroup,
                fullStudentsCardFlag
            }
        },

        components: {
            DataInput,
            CoachSelectionForm,
            SelectStudentsForm,
            ExitConfirmForm,
            IphoneSwitch
        }
    }
</script>
