<template>
    <div v-if="loadingSpinnerFlag" class="bg-[#252526] z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[40%] left-[45%] ml-[-20px]"></div>
    </div>
    <div v-else class="flex justify-center w-full">
        <div class="w-[90%]">
            <div class="w-full flex justify-center">
                <div 
                    :class="{
                        'w-[93%]' : screenWidth > 1500,
                        'w-[100%] px-[20px]' : screenWidth < 1500,
                    }" 
                    class="mt-[15px]"
                >
                    <div class="cashbox w-full">
                        <div class="flex justify-between">
                            <div class="flex justify-between w-[63.8%]">
                                <div class="w-[260px] mr-[34px] h-[80px] pt-[5px] bg-zinc-300 relative bg-opacity-10 rounded-lg border border-slate-700 backdrop-blur-[10px]">
                                    <div class="w-full text-center text-gray-400 text-[19px] font-normal tracking-wide">всі джерела</div>
                                    <div class="w-full text-center text-[#DA4167] text-[30px] font-extrabold tracking-wider">{{ formatNumber(store.student_balance + store.service_balance) }}</div>
                                </div>
                                <div class="w-[260px] h-[80px] mr-[34px] pt-[5px] bg-zinc-300 relative bg-opacity-10 rounded-lg border border-slate-700 backdrop-blur-[10px]">
                                    <div class="w-full text-center text-gray-400 text-[19px] font-normal tracking-wide">готівка (учні)</div>
                                    <div class="w-full text-center text-[#0ABFC2] text-[30px] font-extrabold tracking-wider">{{ formatNumber(store.student_balance) }}</div>
                                </div>
                                <div class="w-[260px] h-[80px] bg-zinc-300 pt-[5px] relative bg-opacity-10 rounded-lg border border-slate-700 backdrop-blur-[10px]">
                                    <div class="w-full text-center text-gray-400 text-[19px] font-normal tracking-wide">готівка (клуб)</div>
                                    <div class="w-full text-center text-[#BD9135] text-[30px] font-extrabold tracking-wider">{{ formatNumber(store.service_balance) }}</div>
                                </div>
                            </div>
                            <div> 
                                <div class="cursor-pointer bg-[#BD9135] w-[260px] text-center py-[7px] px-[10px] rounded-[6px]">
                                    Створити повідомлення
                                </div>
                            </div>
                        </div>
                    </div>
    
    
                    <div class="mt-[40px] flex justify-between">
                        <div class="w-max">
                            <div class="mb-[30px] flex justify-between">
                                    <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ПРОВЕДЕНО ТРЕНУВАНЬ</span></div>
                                    <div>
                                        <select class="outline-none border-[#A4A6B3] text-[#BD9135] border-[1px] bg-transparent text-[13px] flex items-center justify-start px-[5px] py-[0.6px] cursor-pointer rounded-[4px] w-[65px] text-center">
                                            <option class="bg-[#28375F]">2024</option>
                                            <option class="bg-[#28375F]">2023</option>
                                            <option class="bg-[#28375F]">2022</option>
                                            <option class="bg-[#28375F]">2021</option>
                                            <option class="bg-[#28375F]">2020</option>
                                            <option class="bg-[#28375F]">2019</option>
                                        </select>
                                    </div>
                                </div>
                            <div>
                                <LineChart :data="trainerAllCompletedTrainings.data" :labels="trainerAllCompletedTrainings.labels" :width="screenWidth * 0.47"/>
                            </div>
                        </div>
    
                        <div class="w-[29%]">
                            <div class="mb-[10px] flex justify-between">
                                <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ВІДВІДУВАННЯ ТРЕНУВАНЬ</span></div>
                                <div>
                                    <select class="outline-none border-[#A4A6B3] text-[#BD9135] border-[1px] bg-transparent text-[13px] flex items-center justify-start px-[5px] py-[0.6px] cursor-pointer rounded-[4px] w-[115px] text-center">
                                        <option class="bg-[#28375F]">травень 2023</option>
                                        <option class="bg-[#28375F]">червень 2023</option>
                                        <option class="bg-[#28375F]">липень 2023</option>
                                        <option class="bg-[#28375F]">серпень 2023</option>
                                    </select>
                                </div>
                            </div>
                            <div class="overflow-y-auto h-[100px] px-[20px] relative scrollbar">
                                <div v-for="attendance in groupAttendanceChartData" class="flex items-center w-full pb-[10px]">
                                    <div class="text-[#BD9135] truncate w-[40px] mr-[10px] text-[12px]">{{ attendance.group_name }}</div>
                                    <PercentLine :percent="attendance.attendance" :fullWidth="screenWidth > 1700 ? 300 : screenWidth * 0.2"/>
                                </div>
                                <!-- <div class="bg-gradient-to-b from-transparent to-[#252526] w-full h-[40%] absolute bottom-0 left-0"></div> -->
                            </div>
                        </div>
                    </div>
    
    
                    <div class="mt-[40px] flex justify-between w-full">
                        <div class="messages-block border-blue-400 w-[65%] h-[308px]">
                            <div class="text-[#fff] font-bold text-[15px] text-start">
                                ПОВІДОМЛЕННЯ
                            </div>
                            <div class="message-box relative mt-[5px] h-[270px] overflow-auto pr-[8px] border-blue-400">
                                <div v-for="i in 3" class="w-full flex h-[58px] rounded-[8px] bg-[#BD9135] bg-opacity-5 border-[1px] border-[#A5A3A3] border-opacity-20 mb-2">
                                    <div class="h-full rounded-l-md bg-gray-400 w-[8px]"></div>
                                    <div class="w-full relative h-full truncate text-[14px] text-[#BD9135] px-[10px] py-[5px]">
                                        <div class="absolute top-[50%] opacity-60 left-[50%] translate-x-[-50%] translate-y-[-50%]">Відсутні повідомлень</div>    
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="trainings-block h-[293px] w-[29%] border-red-400">
                            <div class="text-[#fff] relative left-[4px] text-[15px] font-bold text-start">
                                НАСТУПНІ ТРЕНУВАННЯ
                            </div>
                            <div class="mt-[5px] h-[268px] overflow-auto px-[5px] message-box">
                                <div v-for="training in store.allTrainings" class="w-full flex h-[24px] items-center rounded-[4px] border-[1px] border-green-400 mb-[9px]">
                                    <div class="h-full rounded-l-[4px] bg-green-400 w-[5px]"></div>
                                    <div class="px-[14px] w-full flex text-[#BD9135] align-middle">
                                        <div class="w-[10%] align-middle text-[0.9em] ">
                                            {{ getWeekDayShortNameByDate(training.date) }}.
                                        </div>
                                        <div class="w-[25%] text-[0.9em] truncate text-start font-semibold align-middle">
                                            {{ training.date.split('T')[0].split('-').reverse().join('.') }}
                                        </div>
                                        <div class="w-[20%] text-[0.9em] text-center align-middle">
                                            {{ training.start_time }}
                                        </div>
                                        <div class="w-[30%] text-[0.9em] truncate text-center align-middle">
                                            {{ training.location_label }}
                                        </div>
                                        <div class="w-[15%] font-bold text-[0.9em] truncate text-end align-middle">
                                            {{ training.group_label }}
                                        </div>
                                    </div>
                                </div>
    
                                <div v-if="store.allTrainings.length < 6" v-for="training in 6 - store.allTrainings.length" class="w-full flex h-[24px] items-center rounded-[4px] border-[1px] border-[#4E5568] mb-[9.2px]">
                                <div class="h-full rounded-l-[4px] bg-gray-400 w-[5px]"></div>
                                    <div class="px-[d] w-full flex text-[#BD9135] align-middle">
                                        <div class="w-full text-[14px] truncate opacity-60 text-center align-middle">
                                            Відсутнє заплановане тренування
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'
    import { getAllIncomesByGroupInYear, getGroupsAttendanceByYearAndMonth, getTrainerCompletedTrainingsByYear } from '@/desktopApp/services/apiRequests'

    import BarChart from '../components/MainComponents/BarChart.vue'
    import OneLine from '../components/MainComponents/OneLine.vue'
    import LineChart from '../components/MainComponents/LineChart.vue'
    import PercentLine from '../components/MainComponents/PercentLine.vue'

    export default {
        name: 'main-view',

        setup() {
            const store = useMainStore()
            const router = useRouter()
            const screenWidth = ref(window.innerWidth)

            const loadingSpinnerFlag = ref(true)

            const incomesByGroupsYear = ref(new Date().getFullYear())
            const incomesByGroupsMonth = ref(new Date().getMonth())
            const filteredIncomesByYear = ref([])
            const dataForCurrentMonthGroupsIncomes = ref({})

            const groupAttendanceYear = ref(new Date().getFullYear())
            const groupAttendanceMonth = ref(new Date().getMonth())
            const groupAttendanceChartData = ref([])

            const completedTrainingsYear = ref(new Date().getFullYear())
            const completedTrainingsMonth = ref(new Date().getMonth())
            const completedTrainingsFlag = ref(false)

            const dataForYearGroupsIncomes = ref({ data: [], labels: [] })

            const trainerAllCompletedTrainings = ref({
                    data: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    labels: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
                })

            const logout = () => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                router.go()
            }

            const formatNumber = (number) => {
                const parts = number.toFixed(2).toString().split(".");
                
                const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                
                const formattedNumber = formattedInteger + "." + parts[1];
                
                return formattedNumber;
            }

            const filterIncomesByTrainerGroups = (incomes) => {
                const trainerGroupsIds = store.trainer_groups.map(group => group.group_id)
                const filteredIncomes = []

                for (let i = 0; i < incomes.length; i++) {
                    filteredIncomes.push(incomes[i])
                    filteredIncomes[filteredIncomes.length - 1].groups = incomes[i].groups.filter(group => trainerGroupsIds.includes(group.group_id)) 
                }

                return filteredIncomes
            }

            const generateDataForYearGroupsIncomes = (incomes) => {
                const data = []
                const labels = []

                for (let i = 0; i < 12; i++) {
                    const currentMonth = i > 9 ? `${i + 1}` : `0${i + 1}`
                    const currentIncome = incomes.find(income => income.date_formatted === `${currentMonth}.${incomesByGroupsYear.value}`)
                    if (currentIncome) {
                        data.push(currentIncome.groups.reduce((acc, group) => acc + group.all_incomes, 0))
                    } else {
                        data.push(0)
                    }
                }

                return { data, labels: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'] }
            }

            const generateDataForCurrentMonthGroupsIncomes = (incomes, currentMonth) => {
                currentMonth = currentMonth > 9 ? `${currentMonth + 1}` : `0${currentMonth + 1}`
                return incomes.find(income => income.date_formatted === `${currentMonth}.${incomesByGroupsYear.value}`)
            }

            const updateCompletedTrainings = async () => {
                completedTrainingsFlag.value = false
                const trainings = await getTrainerCompletedTrainingsByYear(completedTrainingsYear.value, store.trainer.trainer_id)
                trainerAllCompletedTrainings.value.data = trainings.data
                completedTrainingsFlag.value = true
            }

            const getWeekDayShortNameByDate = (date) => {
                const weekDays = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                const dateObj = new Date(date)
                return weekDays[dateObj.getDay()]
            }

            onMounted(async () => {
                await store.getTrainerData()
                await store.getStudentBalanceData()
                await store.getServiceBalanceData()
                await store.getTrainerGroups()
                await store.getAllTrainerPlanedTrainings()
                await store.getTrainerRealIncomes()

                const groupAttendanceRes = await getGroupsAttendanceByYearAndMonth(groupAttendanceYear.value, groupAttendanceMonth.value)
                groupAttendanceChartData.value = groupAttendanceRes.data.filter(group => store.trainer_groups.map(group => group.group_id).includes(group.group_id))

                const incomesByGroupsReq = await getAllIncomesByGroupInYear(incomesByGroupsYear.value)
                filteredIncomesByYear.value = filterIncomesByTrainerGroups(incomesByGroupsReq.data)

                // dataForYearGroupsIncomes.value = generateDataForYearGroupsIncomes(filteredIncomesByYear.value)

                // dataForCurrentMonthGroupsIncomes.value = generateDataForCurrentMonthGroupsIncomes(filteredIncomesByYear.value, incomesByGroupsMonth.value)
                
                await updateCompletedTrainings()
                
                loadingSpinnerFlag.value = false

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            return {
                logout,
                loadingSpinnerFlag,
                screenWidth,
                chartData: {
                    data: [1, 2, 3, 4, 4000, 6, 7, 2000, 3200, 1100, 2100, 3900],
                    labels: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
                },
                store,
                formatNumber,
                filteredIncomesByYear,
                dataForYearGroupsIncomes,
                dataForCurrentMonthGroupsIncomes,
                groupAttendanceChartData,
                trainerAllCompletedTrainings,
                completedTrainingsFlag,
                incomesByGroupsYear,
                incomesByGroupsMonth,
                groupAttendanceYear,
                groupAttendanceMonth,
                completedTrainingsYear,
                completedTrainingsMonth,
                getWeekDayShortNameByDate
            }
        },

        components: {
            BarChart,
            OneLine,
            LineChart,
            PercentLine
        }
    }
</script>

<style lang="scss">

.scrollbar::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #BD9135;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}

</style>