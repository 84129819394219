<template>
    <div class="w-full">
        <div class="w-full">
            <TrainingProgramView v-if="trainingProgramLoaded" class="w-full" :program="trainingProgram" @saveProgram="updateTrainingProgram"/>            
        </div>
    </div>
</template>

<script>
    import { useRouter, useRoute } from 'vue-router'
    import { ref, onMounted } from 'vue'
    import { getTrainingProgramById, updateTrainingProgramById } from '@/desktopApp/services/apiRequests'
    import TrainingProgramView from './TrainingProgramView.vue'

    export default {
        name: 'TrainingProgramUpdateForm',

        setup() {
            const router = useRouter()
            const route = useRoute()

            const trainingProgram = ref({})
            const trainingProgramLoaded = ref(false)

            const updateTrainingProgram = async (newTrainingProgramObject) => {
                await updateTrainingProgramById(route.params.id, newTrainingProgramObject)
                router.push('/training-programs')
            }

            onMounted(async () => {
                const trainingProgramId = route.params.id
                trainingProgram.value = await getTrainingProgramById(trainingProgramId)
                trainingProgramLoaded.value = true
            })

            return {
                updateTrainingProgram,
                router,
                trainingProgram,
                trainingProgramLoaded
            }
        },

        components: {
            TrainingProgramView,
        }
    }
</script>

<style lang="scss" scoped>

</style>