<template>
  <div class="main-tab">
    <div class="main-tab__player-info">
      <div class="main-tab__player-header">
        <div class="main-tab__image-container">
          <img :src="photo" />
        </div>
        <div class="main-tab__name">
          <marquee v-if="name?.length + surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
            <span v-for="id in 100" class="mx-3">{{ name + ' ' + surname }}</span>
          </marquee>
          <span v-else>{{ name + ' ' + surname }}</span>
          <span v-if="name?.length == 0 || surname?.length == 0" style="color: white; opacity: 0.2;">Лєо Мессі</span>
        </div>
        <div class="main-tab__header-row">
          <InfoBlock
            v-for="(infoBlock, index) in infoBlocks"
            :key="index"
            :mainText="infoBlock.mainText"
            :value="infoBlock.value"
            :mainTextPrimary="true"
          />
          <div class="main-tab__origin">
            <img :src="club" alt="" />
            <div class="main-tab__divider"></div>
            <img :src="flag" alt="" />
          </div>
        </div>
      </div>
      <div class="main-tab__player-body">
        <InputHorizontal
          label="ім'я"
          name="first_name"
          placeholder="Введіть ім'я"
          :value="name"
        />
        <InputHorizontal
          label="прізвище"
          name="last_name"
          placeholder="Введіть прізвище"
          :value="surname"
        />
        <InputHorizontal
          label="по батькові"
          name="middle_name"
          placeholder="Введіть по батькові"
          :value="patronymic"
        />
        <InputHorizontal
          label="дата нр."
          type="date"
          name="birth_date"

          :value="formattedBirthDate"
        />
        <InputHorizontal
          label="телефон"
          name="phone_number"
          placeholder="Введіть телефон"
          :value="phoneNumber"
        />
        <InputHorizontal
          label="email"
          name="email"
          placeholder="Введіть email"
          :value="email"
        />
        <InputHorizontal
          label="національність"
          name="nationality"
          placeholder="Введіть національність"
          :value="nationality"
        />
        <IconButton @click="$emit('close')" :src="arrow" :fullWidth="true" />
      </div>
    </div>

    <div class="main-tab__block-text main-tab__block--career">
      <div class="main-tab__title">Кар'єра</div>
      <div class="main-tab__list">
        <InfoLine
          v-for="(value, key) in career"
          :key="key"
          :label="value.time"
          :value="value.team"
        />
      </div>
    </div>

    <div class="main-tab__block main-tab__block--position">
      <div class="main-tab__title">Амплуа</div>
      <PositionsField :playerAmplua="studentAmplua" :playerNumber="studentNumber"  />
    </div>

    <div class="main-tab__block main-tab__block--trainings">
      <div class="main-tab__title">Тренування</div>
      <img :src="progress" alt="Block 2 Image" />
    </div>

    <div class="main-tab__block-text main-tab__block--finances">
      <div class="main-tab__title">Фінанси</div>
      <div class="main-tab__list">
        <InfoLine :label="'непрямі витрати'">
          <CurrencyComponent :value="finances.total_expense" :textNormal="true"
        /></InfoLine>
        <InfoLine :label="'прямі витрати'">
          <CurrencyComponent :value="finances.total_expense" :textNormal="true"
        /></InfoLine>
        <InfoLine :label="'загальні витрати'">
          <CurrencyComponent :value="finances.total_expense" :textNormal="true"
        /></InfoLine>
      </div>
    </div>

    <div class="main-tab__block main-tab__block--analysis">
      <div class="main-tab__title">Аналіз гравця</div>
      <!-- <img :src="graph" alt="Block 2 Image" /> -->
      <RadarChart :chartData="[1, 3, 4, 5,1, 3, 4, 5,1,]" :chart-size="{ width: '250px', height: '220px' }" />
    </div>

    <div class="main-tab__block main-tab__block--matches-played">
      <div class="main-tab__title">Зіграно матчів</div>
      <img :src="progress" alt="Block 2 Image" />
    </div>

    <div class="main-tab__block-text main-tab__block--health">
      <div class="main-tab__title">Медицина</div>
      <div class="main-tab__list">
        <InfoLine :label="'група крові'" :value="health.blood_type" />
        <InfoLine :label="'кардіозагартованість'" :value="health.cardiogram" />
        <InfoLine :label="'кров\'яний тиск'" :value="health.blood_pressure" />
        <InfoLine
          :label="'середній серцевий ритм'"
          :value="health.heart_rate"
        />
      </div>
    </div>

    <div class="main-tab__block main-tab__block--parameters">
      <div class="main-tab__title">Параметри</div>
      <PlayerModel 
        :height="student.height"
        :breastVolume="student.breast_volume"
        :leadingLeg="student.leading_foot"
        :legSize="student.foot_size"
        :weight="student.weight"
      />
    </div>
    <div class="main-tab__block main-tab__block--minutes-played">
      <div class="main-tab__title">Зіграно хвилин</div>
      <img :src="progress" alt="Block 2 Image" />
    </div>
    <div class="main-tab__table">
      <div class="main-tab__title">Статистика</div>
      <TableWrapper>
        <TableComponent>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell :value="' '" :width="50" :borderNone="true" />
              <TableHeaderCell
                v-for="headerCell in table.header"
                :key="headerCell"
                :value="headerCell"
                :borderNone="true"
                :width="10"
              />
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell
                :value="'до приходу'"
                :width="30"
                :borderNone="true"
                :fontPrimary="true"
                :textAlign="'start'"
              />
              <TableCell
                v-for="(value, key) in table.before_joining"
                :value="value"
                :key="key"
                :borderNone="true"
              />
            </TableRow>
            <TableRow>
              <TableCell
                :value="'після приходу'"
                :width="30"
                :borderNone="true"
                :font-primary="true"
                :textAlign="'start'"
              />
              <TableCell
                v-for="(value, key) in table.after_joining"
                :value="value"
                :key="key"
                :borderNone="true"
              />
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                :value="'всього'"
                :width="30"
                :borderNone="true"
                :font-primary="true"
                :textAlign="'start'"
              />
              <TableCell
                v-for="(value, key) in table.after_joining"
                :value="value"
                :key="key"
                :borderNone="true"
                :width="5"
              />
            </TableRow>
          </TableFooter>
        </TableComponent>
      </TableWrapper>
    </div>
    <div class="main-tab__block-text main-tab__block--testing">
      <div class="main-tab__title">Тестування</div>
      <div class="main-tab__list">
        <InfoLine
          :label="'абсолютна швидкість'"
          :value="testing.absolute_speed"
        />
        <InfoLine
          :label="'стартова швидкість'"
          :value="testing.starting_speed"
        />
        <InfoLine :label="'код тесту'" :value="testing.test_code" />
        <InfoLine :label="'загальна оцінка'" :value="testing.rating" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import graph from "@/desktopApp/assets/refactored_assets/img/players/player-graph.png";
import body from "@/desktopApp/assets/refactored_assets/img/players/body.png";
import field from "@/desktopApp/assets/refactored_assets/img/players/field.png";
import arrow from "@/desktopApp/assets/refactored_assets/img/header/arrow.svg";
import progress from "@/desktopApp/assets/refactored_assets/img/players/progress.png";
import player_image from "@/desktopApp/assets/refactored_assets/img/header/player.png";
import club from "@/desktopApp/assets/refactored_assets/img/players/kolos_logo.svg";
import flag from "@/desktopApp/assets/refactored_assets/img/header/flag.png";
import PositionsField from "../widgets/PositionsField.vue";
import PlayerModel from "../widgets/PlayerModel.vue";

export default {
  name: "MainTab",

  props: {
    student: {
      type: Object,
      required: true,
    },
    fullCardInfo: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const apiURL = ref(process.env.VUE_APP_API_URL)
    const name = ref(
      props.student?.name ? props.student.name : ''
    )
    const surname = ref(
      props.student?.surname ? props.student.surname : ''
    )
    const patronymic = ref(
      props.student?.patronymic ? props.student.patronymic : ''
    )
    const formattedBirthDate = ref(
      props.student?.birth_date ? props.student.birth_date.split : ''
    )
    const email = ref(
      props.student?.email ? props.student.email : ''
    )
    const phoneNumber = ref(
      props.student?.phone ? props.student.phone : ''
    )
    const nationality = ref(
      props.student?.nationality ? props.student.nationality : ''
    )
    const group = ref(
      props.student?.group_name ? props.student.group_name : ''
    )
    const age = ref(
      props.student?.age ? props.student.age : ''
    )
    const studentAmplua = ref(
      props.student?.amplua ? props.student.amplua : ''
    )
    const studentNumber = ref(
      props.student?.player_number ? props.student.player_number : ''
    )
    const photo = ref(
      props.student?.photo ? apiURL.value + 'static/' + props.student.photo : ''
    )

    const infoBlocks = ref([
      {
        mainText: "Група",
        value: group.value,
      },
      {
        mainText: "Вік",
        value: age.value,
      },
      {
        mainText: "Амплуа",
        value: studentAmplua.value,
      },
      {
        mainText: "Номер",
        value: studentNumber.value,
      },
    ])

    const career = ref(props.fullCardInfo.career.map((item) => {
      return {
        time: item.time,
        team: item.club,
      }
    }))

    onMounted(() => {
      console.log('props.student', props.fullCardInfo)
    })


    return {
      name,
      surname,
      patronymic,
      formattedBirthDate,
      phoneNumber,
      email,
      nationality,
      group,
      age,
      studentAmplua,
      studentNumber,
      photo,
      apiURL,
      infoBlocks,
      career
    }
  },

  components: {
    PositionsField,
    PlayerModel
  },

  data() {
    return {
      player_image: player_image,
      graph: graph,
      arrow: arrow,
      body: body,
      field: field,
      club: club,
      flag: flag,
      progress: progress,
      team_info: {
        group: "U-11",
        age: "11",
        position: "ПН",
        number: "23",
      },
      table: {
        header: [
          "матчів",
          "голів",
          "асист",
          "пен.",
          "автогол",
          "жовт.",
          "черв.",
          "трен.",
          "пропущ.",
        ],
        before_joining: {
          matches: 100,
          goals: 11,
          assists: 10,
          penalties: 11,
          auto_goals: 11,
          yellow_card: 11,
          red_cards: 11,
          trainings: 11,
          missed_trainings: 11,
        },
        after_joining: {
          matches: 100,
          goals: 11,
          assists: 10,
          penalties: 11,
          auto_goals: 11,
          yellow_card: 11,
          red_cards: 11,
          trainings: 11,
          missed_trainings: 11,
        },
        total: {
          matches: 100,
          goals: 11,
          assists: 10,
          penalties: 11,
          auto_goals: 11,
          yellow_card: 11,
          trainings: 11,
          missed_trainings: 11,
        },
      },

      personal_info: {
        first_name: "Mark",
        last_name: "Bertsyk",
        middle_name: "Vicotorovich",
        birth_date: "12.12.1990",
        phone_number: "+380662252243",
        email: "m.bertsyk@outlook.com",
        nationality: "UKR",
        city: "Kyiv",
        contract_expires: "20.12.2024",
      },
      finances: {
        expenses: 49_000,
        straight_expenses: 156_000,
        total_expense: 200_000,
      },
      testing: {
        absolute_speed: 17,
        starting_speed: 17,
        test_code: 12,
        rating: 5,
      },
      health: {
        blood_type: "O(I)-",
        cardiogram: "36",
        blood_pressure: "98/70",
        heart_rate: "72",
      },
      stats: {
        before_joining: {
          matches: 100,
          goals: 11,
          assists: 10,
          penalties: 11,
          auto_goals: 11,
          yellow_card: 11,
          trainings: 11,
          missed_trainings: 11,
        },
        after_joining: {
          matches: 100,
          goals: 11,
          assists: 10,
          penalties: 11,
          auto_goals: 11,
          yellow_card: 11,
          trainings: 11,
          missed_trainings: 11,
        },
        total: {
          matches: 100,
          goals: 11,
          assists: 10,
          penalties: 11,
          auto_goals: 11,
          yellow_card: 11,
          trainings: 11,
          missed_trainings: 11,
        },
      },
    };
  },
};
</script>
