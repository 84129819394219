<template>
    <div class="w-full">
        <div class="w-full">
            <TrainingProgramView class="w-full" @saveProgram="saveTrainingProgram"/>            
        </div>
    </div>
</template>

<script>
    import { useRouter } from 'vue-router'
    import { ref, onMounted } from 'vue'
    import { addTrainingSummaryProgram } from '@/desktopApp/services/apiRequests'
    import TrainingProgramView from './TrainingProgramView.vue'

    export default {
        name: 'TrainingProgramCreationForm',

        setup() {
            const router = useRouter()

            const saveTrainingProgram = async (newTrainingProgramObject) => {
                await addTrainingSummaryProgram(newTrainingProgramObject)
                console.log(newTrainingProgramObject)
                router.push('/training-programs')
            }

            return {
                saveTrainingProgram,
                router
            }
        },

        components: {
            TrainingProgramView
        }
    }
</script>

<style lang="scss" scoped>

</style>