<template>
    <div class="w-[80%] mx-auto mb-[20px]">
        <div class="flex justify-end">
            <ButtonComponent @click="$router.go(-1)">
                Назад
            </ButtonComponent>
        </div>
        
        <div class="flex justify-between mt-[20px] border-y-[1px] border-[#BD913533] items-center">
            <div class="w-[33.3333%] flex justify-start ">
                <div class="player-header__block-left">
                    <AvatarContainer :src="apiURL + 'static/' + player.photo" />
                    <div class="player-header__info">
                    <div class="player-header__name">{{ player.name + ' ' + player.surname }}</div>
                    <div class="player-header__row">
                        <InfoBlock
                        v-for="(infoBlock, index) in infoBlocks"
                        :key="index"
                        :mainText="infoBlock.mainText"
                        :value="infoBlock.value"
                        />
                        <img :src="flag" alt="edit" />
                    </div>
                    </div>
                </div>
            </div>

            <div class="w-[33.3333%] flex justify-center">
                <div class="match-report__banner" style="background-color: transparent; border: none;">
                    <div class="match-report__banner--title">
                        <div>{{ matchReport.league }}</div>
                        <div>Тур {{ matchReport.tour }}</div>
                    </div>

                    <div class="match-report__banner--teams">
                        <div class="match-report__banner--team">
                            <img :src="matchReport.firstTeamLogo ? apiURL + 'static/' + matchReport.firstTeamLogo : require('@/desktopApp/assets/kolos_team.svg')" class="w-max" alt="">
                            <div>{{ matchReport.firstTeamName }}</div>
                        </div>
                        <div class="match-report__banner--score">
                            <div>{{ matchReport.firstTeamScore }}:{{ matchReport.secondTeamScore }}</div>
                            <div>{{ matchTime }}''</div>

                        </div>
                        <div class="match-report__banner--team">
                            <img :src="matchReport.secondTeamLogo ? apiURL + 'static/' + matchReport.secondTeamLogo : require('@/desktopApp/assets/kolos_team.svg')" class="w-max" alt="">
                            <div>{{ matchReport.secondTeamName }}</div>
                        </div>
                    </div>

                    <div class="match-report__banner--info">
                        <!-- <div>{{ matchReport.date.split('T')[0].split('-').reverse().join('.') }}</div> -->
                        <div>({{ matchReport.time }})</div>
                        <div>{{ matchReport.stadium }}</div>
                    </div>
                </div>
            </div>

            <div class="w-[33.3333%] flex items-center justify-end">
                <StarsComponent :rating="avarageRating" class="scale-[1.5] origin-right mr-[10px]"/>
                <div class="relative top-[1px]">{{ avarageRating.toFixed(1) }}</div>
            </div>
        </div>

        <div>
            <div class="flex items-center justify-between">
                <div class="w-[33.3333%] mt-[15px]">
                    <div class="w-[120px]">
                        <div>
                            <InfoLine :label="'автор'" :value="author?.admin_name + ' ' + author.admin_surname" />
                        </div>
                        <div class="mt-[10px]">
                            <InfoLine :label="'дата'" :value="nowDate.toISOString().split('T')[0].split('-').reverse().join('.')" />
                        </div>
                    </div>
                </div>
                <div class="w-[33.3333%] flex items-center justify-center mt-[16px] ml-[90px]">
                    <StarsComponent :rating="newRating" class="scale-[1.5] origin-right mr-[10px]"/>
                    <div class="relative top-[1px]">
                        <input v-model="newRating" type="number" class="bg-transparent w-[60px]" min="0" max="10" placeholder="0">
                    </div>
                </div>
                <div class="w-[33.3333%] flex justify-end">
                    <ButtonComponent @click="createNewPlayerReport" class="mt-[12px]">
                        Зберегти
                    </ButtonComponent>
                </div>
            </div>

            <div class="mt-[17px]">
                <TextArea :label="'коментар'" :value="newComment" @update:modelValue="e => newComment = e" :placeholder="'Опишіть своє рішення'" :backgroundColor="'#47484966'" />
            </div>
        </div>

        <div class="font-bold text-center mt-[60px]">
            ВСІ ЗВІТИ
        </div>

        <div v-for="playerReport in playeMatchReports" class="mt-[10px]">
            <div class="flex items-center justify-between">
                <div class="w-[33.3333%] mt-[15px]">
                    <div class="w-[120px]">
                        <div>
                            <InfoLine :label="'автор'" :value="playerReport.author" />
                        </div>
                        <div class="mt-[10px]">
                            <InfoLine :label="'дата'" :value="playerReport.date" />
                        </div>
                    </div>
                </div>
                <div class="w-[33.3333%] flex items-center justify-center mt-[16px] ml-[45px]">
                    <StarsComponent :rating="playerReport.rating" class="scale-[1.5] origin-right mr-[10px]"/>
                    <div class="relative top-[1px]">
                        {{ playerReport.rating }}
                    </div>
                </div>
                <div class="w-[33.3333%] flex justify-end">
                    <ButtonComponent @click="deletePlayerReport(playerReport.id)" :negative="true" class="mt-[12px]">
                        Видалити
                    </ButtonComponent>
                </div>
            </div>

            <div class="mt-[17px]">
                <TextArea :label="'коментар'" :value="playerReport.comment" :placeholder="'Опишіть своє рішення'" :backgroundColor="'#47484966'" />
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useMainStore } from '@/desktopApp/stores/mainStore';
import { getStudentById, getMatchReportById, getAdmin, getPlayerStatisticsByMatchReportIdAndPlayerId, updatePlayerStatisticsByMatchReportIdAndPlayerId } from '@/desktopApp/services/apiRequests';
import flag from "@/desktopApp/assets/refactored_assets/img/header/flag.png";

export default {
    name: "PlayerMatchMarkView",

    setup(props) {
        const route = useRoute();
        const router = useRouter();

        const store = useMainStore();

        const matchId = route.params.match_id;
        const studentId = route.params.student_id;

        const matchReport = ref({});
        const player = ref({});

        const author = ref({});

        const infoBlocks = ref([]);

        const newRating = ref(0);
        const newComment = ref("");

        const nowDate = new Date();

        const playerMatchStats = ref({});

        const playeMatchReports = ref([]);

        const avarageRating = ref(0);

        const createNewPlayerReport = async () => {
            playeMatchReports.value.push({
                id: new Date().getTime(),
                rating: newRating.value,
                comment: newComment.value,
                date: nowDate.toISOString().split('T')[0].split('-').reverse().join('.'),
                author: author.value.admin_name + ' ' + author.value.admin_surname
            })

            playerMatchStats.value.player_match_reports = playeMatchReports.value;

            newRating.value = 0;
            newComment.value = "";

            avarageRating.value = playeMatchReports.value.reduce((acc, report) => acc + report.rating, 0) / playeMatchReports.value.length || 0;
            
            playerMatchStats.value.player_average_score = avarageRating.value;

            await updatePlayerStatisticsByMatchReportIdAndPlayerId(matchId, studentId, playerMatchStats.value);
        };

        const deletePlayerReport = async (id) => {
            playeMatchReports.value = playeMatchReports.value.filter(report => report.id !== id);

            avarageRating.value = playeMatchReports.value.reduce((acc, report) => acc + report.rating, 0) / playeMatchReports.value.length || 0;

            playerMatchStats.value.player_average_score = avarageRating.value;
            playerMatchStats.value.player_match_reports = playeMatchReports.value;

            await updatePlayerStatisticsByMatchReportIdAndPlayerId(matchId, studentId, playerMatchStats.value);
        };

        watch(newRating, (newValue) => {
            if (newValue < 0) {
                newRating.value = 0;
            } else if (newValue > 10) {
                newRating.value = 10;
            }
        });

        onMounted(async () => {
            matchReport.value = await getMatchReportById(matchId);
            player.value = (await getStudentById(studentId)).data.student;

            console.log(matchReport.value);

            author.value = {
                admin_name: store.trainer.name,
                admin_surname: store.trainer.surname
            }
            console.log(author.value);

            playerMatchStats.value = await getPlayerStatisticsByMatchReportIdAndPlayerId(matchId, studentId);

            playeMatchReports.value = playerMatchStats.value.player_match_reports.filter(
                report => report.author === author.value.admin_name + ' ' + author.value.admin_surname
            ) || [];

            avarageRating.value = playerMatchStats.value.player_average_score || 0;

            console.log(playerMatchStats.value);    

            infoBlocks.value = [
                { mainText: "Група", value: player.value.group_name },
                { mainText: "Вік", value: player.value.age },
                { mainText: "Амплуа", value: player.value.amplua },
                { mainText: "номер", value: player.value.player_number },
            ];
        });

        return {
            matchReport,
            player,
            apiURL: process.env.VUE_APP_API_URL,
            infoBlocks,
            flag: flag,
            newRating,
            newComment,
            author,
            nowDate,
            playeMatchReports,
            avarageRating,
            createNewPlayerReport,
            deletePlayerReport
        };
    },
}
</script>

<style>
    
</style>