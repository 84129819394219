<template>
    <div class="absolute z-[999] bg-[#252526] w-[80vw] h-[100vh] top-0 left-[59%] translate-x-[-50%]">
        <div class="text-white font-bold text-center mt-[20px] text-[21px]">Додавання конспекту до тренування на {{dateFormatted}}</div>
        
        <div v-if="!isProgramToViewVisible" class=" mt-10 mb-5 rounded-[10px] overflow-y-auto">
            <div class="flex justify-end mb-[15px]">
                <div @click="$emit('close')" class="bg-[#BD9135] mr-[125px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Назад</span>
                </div>
            </div>
            <table class="w-[94%] ">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                        <th class="px-3 py-2 w-[35%] text-start font-bold text-sm">Назва програми тренуваннь</th>
                        <th class="w-[5%]" ></th>
                        <th class="px-3 py-2 text-end w-[204px] relative font-bold text-sm">
                            <div class="cursor-pointer w-[178px] z-[-40] pr-[26px]">Редагувати</div>
                        </th>
                        <th class="px-3 w-[10%] py-2 text-end font-bold text-sm">ID програми</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="program in programs" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                            <div class="rounded-full border-[1px] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                <span>{{ program.name[0] }}</span>
                            </div>
                        </td>
                        <td class="px-3 py-2 w-[35%] text-start font-medium text-sm">{{ program.name }}</td>
                        <td class="w-[5%]" ></td>
                        <td class="px-3 py-2 text-end w-[204px] relative font-medium text-sm">
                            <div class="cursor-pointer w-[178px] z-[-40] pr-[26px]">

                                <button 
                                    @click="setProgramToView(program)"
                                    class="py-1 rounded-[10px] text-sm font-medium"    
                                >
                                    <img 
                                        src="@/desktopApp/assets/icons/pen_icon.svg" 
                                        class="w-6 h-6"
                                    >
                                </button>

                            </div>
                        </td>
                        <td class="px-3 w-[10%] py-2 text-end font-medium text-sm">#{{program.id.split('-')[0]}}...</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <TrainingProgramView v-else @addSummary="summary => $emit('addSummary', summary)" :program="programToView" @close="isProgramToViewVisible = false" />
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import TrainingProgramView from './TrainingProgramView.vue'

    export default {
        name: 'add-summary-to-training-form',

        props: {
            date: {
                type: Date,
                required: true
            },
            programs: {
                type: Array,
                required: true
            }
        },

        setup(props, { emit }) {
            const dateFormatted = ref(props.date.split('-').reverse().join('.'))
            const allTrainingProgram = ref([])

            const screenWidth = window.innerWidth

            const programToView = ref({})
            const isProgramToViewVisible = ref(false)
            const setProgramToView = (program) => {
                console.log(program)
                programToView.value = program
                isProgramToViewVisible.value = true
            }

            onMounted(() => {
                console.log(dateFormatted.value)
            })

            return {
                dateFormatted,
                allTrainingProgram,
                setProgramToView,
                programToView,
                isProgramToViewVisible,
                screenWidth
            }
        },

        components: {
            TrainingProgramView
        }
    }
</script>

<style lang="scss" scoped>

</style>