<template>
    <div class="fixed bg-[#252526] w-full h-full top-0 left-0">
        <div @click="$emit('close')" class="flex text-[#BD9135] items-center ml-3 mt-5">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div class="uppercase font-bold text-white text-center mt-[10px]">
            ТЕСТУВАННЯ - {{ standard.name }}
        </div>

        <div class="flex justify-between items-center mb-[10px] mt-[30px] mx-[20px]">
            <div class="flex w-[70%]">
                <div class="mr-[10px]">
                    <div class="w-[43px] overflow-hidden h-[43px] flex items-end justify-center rounded-full border-[#FFF] border-[2px]">
                        <img v-if="student.photo?.length > 0" :src="apiURL + 'static/' + student.photo">
                        <div v-else class="w-full h-full bg-[#FFF] bg-opacity-40 text-[#FFF] text-[16px] font-semibold flex justify-center items-center">
                            {{ student.name[0] + student.surname[0] }}
                        </div>
                    </div>
                </div>
                <div class="w-[75%]">
                    <div class="text-[#FFF] font-semibold truncate">
                        {{ student.name }} {{ student.surname }}
                    </div>
                    <div>
                        <div class="truncate text-[10px] text-gray-400">{{ '#' + student.player_number + ' | ' + student.amplua + ' | ' + student.age + 'p. | ' + student.nationality }}</div>
                    </div>
                </div>
            </div>

            <div class="flex gap-[10px]">
                <div class="relative border-[1px] border-[#339989] w-[22px] h-[22px] rounded-full flex justify-center items-center">
                    <div v-if="mark == 'excellent'" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] border-[1px] w-[17px] h-[17px] rounded-full bg-[#339989] border-[#339989]"></div>
                </div>
                <div class="relative border-[1px] border-[#BD9135] w-[22px] h-[22px] rounded-full flex justify-center items-center">
                    <div v-if="mark == 'good'" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] border-[1px] w-[17px] h-[17px] rounded-full bg-[#BD9135] border-[#BD9135]"></div>
                </div>
                <div class="relative border-[1px] border-[#DA4167] w-[22px] h-[22px] rounded-full flex justify-center items-center">
                    <div v-if="mark == 'bad'" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] border-[1px] w-[17px] h-[17px] rounded-full bg-[#DA4167] border-[#DA4167]"></div>
                </div>
            </div>
        </div>

        <!-- chas -->
        <div v-if="typeScore == 'time'" class="mt-[50px]">
            <div class="text-[#BD9135] text-center">введіть показник:</div>

            <div class="flex justify-center mt-[20px]">
                <div class="w-[38px]">
                    <div>
                        <input v-model="hours" type="text" class="w-[38px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="00">
                    </div>
                    <div class="text-[#BD9135] text-center">
                        год
                    </div>
                </div>
                <div class="relative w-[14px] text-center text-[38px] text-bold text-[#BD9135]">
                    <div class="leading-[35px]">
                        :
                    </div>
                </div>
                <div class="w-[38px]">
                    <div>
                        <input v-model="minutes" type="text" class="w-[38px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="00">
                    </div>
                    <div class="text-[#BD9135] text-center">
                        хв
                    </div>
                </div>
                <div class="relative w-[14px] text-center text-[38px] text-bold text-[#BD9135]">
                    <div class="leading-[35px]">
                        :
                    </div>
                </div>
                <div class="w-[38px]">
                    <div>
                        <input v-model="seconds" type="text" class="w-[38px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="00">
                    </div>
                    <div class="text-[#BD9135] text-center">
                        с
                    </div>
                </div>
                <div class="relative w-[14px] text-center text-[38px] text-bold text-[#BD9135]">
                    <div class="leading-[35px]">
                        ,
                    </div>
                </div>
                <div class="w-[38px]">
                    <div>
                        <input v-model="miliseconds" type="text" class="w-[38px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="00">
                    </div>
                    <div class="text-[#BD9135] text-center">
                        мс
                    </div>
                </div>
            </div>
        </div>
        <div v-if="typeScore == 'time'" class="relative flex justify-center mt-[30px] w-[276px] mx-auto">
            <div class="flex justify-between absolute w-[256px] bottom-[-10px]">
                <div class="text-[#339989] text-center text-[11px] relative left-[31px]">чудово</div>
                <div class="text-[#BD9135] text-center text-[11px] relative left-[6px]">добре</div>
                <div class="text-[#DA4167] text-center text-[11px] relative right-[25px]">недостатньо</div>
            </div>
            <div class="absolute">
                <svg width="276" height="31" viewBox="0 0 276 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="131.5" y1="13" x2="131.5" y2="24" stroke="#BD9135"/>
                    <line x1="204.5" y1="14" x2="204.5" y2="23" stroke="#BD9135"/>
                    <line x1="62.5" y1="14" x2="62.5" y2="23" stroke="#BD9135"/>
    
                    <line x1="63" y1="18.5" x2="204" y2="18.5" stroke="#BD9135"/>
                    <line x1="205" y1="18.5" x2="267" y2="18.5" stroke="#DA4167"/>
                    <line x1="-4.37114e-08" y1="18.5" x2="62" y2="18.5" stroke="#339989"/>
                </svg>
            </div>
            <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                <div class="text-[#BD9135] text-center text-[11px] relative left-[-6px]">{{ middleValue }}</div>
            </div>

            <div class="absolute w-[30px] flex flex-col justify-center items-center top-[20px] left-[91%]" :style="[`left: ${markerPosition}%`]">
                <div class="h-[25px] w-[1px]" :style="[`background-color : ${mark == 'bad' ? '#DA4167' : mark == 'good' ? '#BD9135' : '#339989'}`]"></div>
                <div class="text-[12px]" :style="[`color : ${mark == 'bad' ? '#DA4167' : mark == 'good' ? '#BD9135' : '#339989'}`]">{{ value }}</div>
            </div>
        </div>

        <!-- vidstan -->
        <div v-if="typeScore == 'length'" class="mt-[50px]">
            <div class="text-[#BD9135] text-center">введіть показник:</div>

            <div class="flex justify-center mt-[20px]">
                <div class="w-[38px]">
                    <div>
                        <input v-model="kilometers" type="text" class="w-[38px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="00">
                    </div>
                    <div class="text-[#BD9135] text-center">
                        км
                    </div>
                </div>
                <div class="relative w-[14px] text-center text-[38px] text-bold text-[#BD9135]">

                </div>
                <div class="w-[38px]">
                    <div>
                        <input v-model="meters" type="text" class="w-[38px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="00">
                    </div>
                    <div class="text-[#BD9135] text-center">
                        м
                    </div>
                </div>
                <div class="relative w-[14px] text-center text-[38px] text-bold text-[#BD9135]">

                </div>
                <div class="w-[38px]">
                    <div>
                        <input v-model="centimeters" type="text" class="w-[38px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="00">
                    </div>
                    <div class="text-[#BD9135] text-center">
                        см
                    </div>
                </div>
                <div class="relative w-[14px] text-center text-[38px] text-bold text-[#BD9135]">

                </div>
                <div class="w-[38px]">
                    <div>
                        <input v-model="milimeters" type="text" class="w-[38px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="00">
                    </div>
                    <div class="text-[#BD9135] text-center">
                        мм
                    </div>
                </div>
            </div>
        </div>
        <div v-if="typeScore == 'length'" class="relative flex justify-center mt-[30px] w-[276px] mx-auto">
            <div class="flex justify-between absolute w-[256px] bottom-[-10px]">
                <div class="text-[#DA4167] text-center text-[11px] relative left-[20px]">недостатньо</div>
                <div class="text-[#BD9135] text-center text-[11px] relative right-[20px]">добре</div>
                <div class="text-[#339989] text-center text-[11px] relative right-[45px]">чудово</div>
            </div>
            <div class="absolute top-[14px]">
                <svg width="276" height="31" viewBox="0 0 276 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="131.5" y1="-2.18557e-08" x2="131.5" y2="11" stroke="#BD9135"/>
                    <line x1="204.5" y1="1" x2="204.5" y2="10" stroke="#BD9135"/>
                    <line x1="62.5" y1="1" x2="62.5" y2="10" stroke="#BD9135"/>

                    <line x1="63" y1="5.5" x2="204" y2="5.50001" stroke="#BD9135"/>
                    <line x1="205" y1="5.5" x2="267" y2="5.49999" stroke="#339989"/>
                    <line x1="-4.37114e-08" y1="5.5" x2="62" y2="5.49999" stroke="#DA4167"/>
                </svg>
            </div>
            <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                <div class="text-[#BD9135] text-center text-[11px] relative left-[-6px]">{{ middleValue }}</div>
            </div>

            <div class="absolute w-[30px] flex flex-col justify-center items-center top-[20px] left-[91%]" :style="[`left: ${markerPosition}%`]">
                <div class="h-[25px] w-[1px]" :style="[`background-color : ${mark == 'bad' ? '#DA4167' : mark == 'good' ? '#BD9135' : '#339989'}`]"></div>
                <div class="text-[12px]" :style="[`color : ${mark == 'bad' ? '#DA4167' : mark == 'good' ? '#BD9135' : '#339989'}`]">{{ value }}</div>
            </div>
        </div>

        <!-- povtoruvanna -->
        <div v-if="typeScore == 'count'" class="mt-[50px]">
            <div class="text-[#BD9135] text-center">введіть показник:</div>

            <div class="flex justify-center mt-[20px]">
                <div class="w-[65px]">
                    <div>
                        <input v-model="count" type="text" class="w-[65px] h-[38px] border-[#BD9135] border-[1px] rounded-[4px] bg-[#524A3A] text-center text-white text-[22px] outline-none text-bold" placeholder="0000">
                    </div>
                </div>
            </div>
        </div>
        <div v-if="typeScore == 'count'" class="relative flex justify-center mt-[30px] w-[276px] mx-auto">
            <div class="flex justify-between absolute w-[256px] bottom-[-10px]">
                <div class="text-[#DA4167] text-center text-[11px] relative left-[20px]">недостатньо</div>
                <div class="text-[#BD9135] text-center text-[11px] relative right-[20px]">добре</div>
                <div class="text-[#339989] text-center text-[11px] relative right-[45px]">чудово</div>
            </div>
            <div class="absolute top-[14px]">
                <svg width="276" height="31" viewBox="0 0 276 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="131.5" y1="-2.18557e-08" x2="131.5" y2="11" stroke="#BD9135"/>
                    <line x1="204.5" y1="1" x2="204.5" y2="10" stroke="#BD9135"/>
                    <line x1="62.5" y1="1" x2="62.5" y2="10" stroke="#BD9135"/>

                    <line x1="63" y1="5.5" x2="204" y2="5.50001" stroke="#BD9135"/>
                    <line x1="205" y1="5.5" x2="267" y2="5.49999" stroke="#339989"/>
                    <line x1="-4.37114e-08" y1="5.5" x2="62" y2="5.49999" stroke="#DA4167"/>
                </svg>
            </div>
            <div class="flex justify-center absolute w-[256px] bottom-[-45px]">
                <div class="text-[#BD9135] text-center text-[11px] relative left-[-6px]">{{ middleValue }}</div>
            </div>

            <div class="absolute w-[30px] flex flex-col justify-center items-center top-[20px] left-[91%]" :style="[`left: ${markerPosition}%`]">
                <div class="h-[25px] w-[1px]" :style="[`background-color : ${mark == 'bad' ? '#DA4167' : mark == 'good' ? '#BD9135' : '#339989'}`]"></div>
                <div class="text-[12px]" :style="[`color : ${mark == 'bad' ? '#DA4167' : mark == 'good' ? '#BD9135' : '#339989'}`]">{{ value }}</div>
            </div>
        </div>

        <!-- point -->
        <div v-if="typeScore == 'point'" class="mt-[50px]">
            <div class="text-[#BD9135] text-center">введіть оцінку:</div>

            <div class="flex justify-center mt-[20px] gap-[15px]">
                <div @click="point = 1" :class="{'border-[3px]' : point == 1}" class="bg-[#DA4167] bg-opacity-20 border-[1px] border-[#DA4167] w-[36px] h-[36px] flex justify-center items-center font-bold rounded-full text-[#DA4167]">1</div>
                <div @click="point = 2" :class="{'border-[3px]' : point == 2}" class="bg-[#DA4167] bg-opacity-20 border-[1px] border-[#DA4167] w-[36px] h-[36px] flex justify-center items-center font-bold rounded-full text-[#DA4167]">2</div>
                <div @click="point = 3" :class="{'border-[3px]' : point == 3}" class="bg-[#BD9135] bg-opacity-20 border-[1px] border-[#BD9135] w-[36px] h-[36px] flex justify-center items-center font-bold rounded-full text-[#BD9135]">3</div>
                <div @click="point = 4" :class="{'border-[3px]' : point == 4}" class="bg-[#339989] bg-opacity-20 border-[1px] border-[#339989] w-[36px] h-[36px] flex justify-center items-center font-bold rounded-full text-[#339989]">4</div>
                <div @click="point = 5" :class="{'border-[3px]' : point == 5}" class="bg-[#339989] bg-opacity-20 border-[1px] border-[#339989] w-[36px] h-[36px] flex justify-center items-center font-bold rounded-full text-[#339989]">5</div>
            </div>
        </div>


        <div @click="$emit('tested', {student: student, standart: standard, mark: mark, value: value})" class="mt-[120px]">
            <button class="bg-[#0ABFC2] rounded-[9px] text-[#252526] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between my-5 mx-auto">
                <div class="w-full">START</div>
            </button>
        </div>

        
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';

export default {
    name: "StudentStandartCarrying",

    props: {
        student: {
            type: Object,
            required: true
        },
        standard: {
            type: Object,
            required: true
        }
    },

    setup(props) {
        const apiURL = process.env.VUE_APP_API_URL;

        const typeScore = ref(props.standard.score_board_type);

        const minValue = ref(props.standard.minimal_value);
        const middleValue = ref(props.standard.middle_value);
        const maxValue = ref(props.standard.maximal_value);

        const miliseconds = ref(0);
        const seconds = ref(0);
        const minutes = ref(0);
        const hours = ref(0);

        const milimeters = ref(0);
        const centimeters = ref(0);
        const meters = ref(0);
        const kilometers = ref(0);

        const point = ref(0);

        const count = ref(0);

        const formattedValue = ref('');
        const value = ref(0);

        watch([miliseconds, seconds, minutes, hours], (newValue) => {
            formattedValue.value = `${hours.value}:${minutes.value}:${seconds.value},${miliseconds.value}`;
            value.value = +seconds.value + +minutes.value * 60 + +hours.value * 3600 + +miliseconds.value / 1000;
        });

        watch([milimeters, centimeters, meters, kilometers], (newValue) => {
            formattedValue.value = `${kilometers.value}км ${meters.value}м ${centimeters.value}см ${milimeters.value}мм`;
            value.value = +milimeters.value / 10 + +centimeters.value + +meters.value * 100 + +kilometers.value * 1000;
        });

        watch(point, (newValue) => {
            formattedValue.value = `${point.value}`;
            value.value = +point.value;
        });

        const mark = computed(() => {
            if (typeScore.value == 'length') {
                if (value.value < minValue.value) {
                    return 'bad';
                } else if (value.value >= minValue.value && value.value < maxValue.value) {
                    return 'good';
                } else if (value.value >= maxValue.value) {
                    return 'excellent';
                }
            } else if (typeScore.value == 'time') {
                if (value.value < minValue.value) {
                    return 'excellent';
                } else if (value.value >= minValue.value && value.value < maxValue.value) {
                    return 'good';
                } else if (value.value >= maxValue.value) {
                    return 'bad';
                }
            } else if (typeScore.value == 'count') {
                if (value.value < minValue.value) {
                    return 'bad';
                } else if (value.value >= minValue.value && value.value < maxValue.value) {
                    return 'good';
                } else if (value.value >= maxValue.value) {
                    return 'excellent';
                }
            } else if (typeScore.value == 'point') {
                if (value.value < 3) {
                    return 'bad';
                } else if (value.value >= 3 && value.value < 4) {
                    return 'good';
                } else if (value.value >= 4) {
                    return 'excellent';
                }
            }
        });

        //markerPosition in range -5 to 93
        const markerPosition = computed(() => {
            if (typeScore.value == 'length') {
                if (value.value < minValue.value) {
                    //position in range 0% to 22.5%
                    return ((value.value / minValue.value) * 22.5) - 5;
                } else if (value.value >= minValue.value && value.value < maxValue.value) {
                    //position in range 22.5% to 77.5%
                    return ((value.value - minValue.value) / (maxValue.value - minValue.value) * 51) + 17.5;
                } else if (value.value >= maxValue.value) {
                    //position in range 77.5% to 100%
                    return Math.log(value.value) * 10 + 12 <= 92 ? Math.log(value.value) * 10 + 12 : 92;
                }
            } else if (typeScore.value == 'time') {
                if (value.value < minValue.value) {
                    //position in range 0% to 22.5%
                    return ((value.value / minValue.value) * 22.5) - 5;
                } else if (value.value >= minValue.value && value.value < maxValue.value) {
                    //position in range 22.5% to 77.5%
                    return ((value.value - minValue.value) / (maxValue.value - minValue.value) * 51) + 17.5;
                } else if (value.value >= maxValue.value) {
                    //position in range 77.5% to 100%
                    return Math.log(value.value) * 10 + 12 <= 92 ? Math.log(value.value) * 10 + 12 : 92;
                }
            } else if (typeScore.value == 'count') {
                if (value.value < minValue.value) {
                    //position in range 0% to 22.5%
                    return ((value.value / minValue.value) * 22.5) - 5;
                } else if (value.value >= minValue.value && value.value < maxValue.value) {
                    //position in range 22.5% to 77.5%
                    return ((value.value - minValue.value) / (maxValue.value - minValue.value) * 51) + 17.5;
                } else if (value.value >= maxValue.value) {
                    //position in range 77.5% to 100%
                    return Math.log(value.value) * 10 + 12 <= 92 ? Math.log(value.value) * 10 + 12 : 92;
                }
            } 
        });

        watch(count, (newValue) => {
            formattedValue.value = `${count.value}`;
            value.value = +count.value;
        });

        onMounted(() => {
            console.log('student', props.student)
            console.log('standard', props.standard)
        })

        return {
            apiURL,
            typeScore,
            minValue,
            middleValue,
            maxValue,
            value,
            formattedValue,
            miliseconds,
            seconds,
            minutes,
            hours,
            milimeters,
            centimeters,
            meters,
            kilometers,
            point,
            count,
            mark,
            markerPosition
        }
    }
}
</script>

<style>
    
</style>