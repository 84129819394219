<template>
    <div class="match-report__timeline--binom binom-end">
        <div class="match-report__timeline--event">
            <div class="absolute top-[-10px] font-bold">
                {{ time }}''
            </div>
            <div>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="4" transform="rotate(-90 5 5)" fill="#1B4079" stroke="#8C8C8C" stroke-width="2"/>
                </svg>
            </div>
            <img class="match-report__timeline--event-icon" src="@/desktopApp/assets/icons/end_match_icon.svg" alt="">
            <div class="info h-[35px]">
                <div>Кінець</div>
            </div>
            <div class="mt-[15px]">
                <!-- <RemoveButton @click="$emit('delete')" class="scale-[0.8]"/> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EndMatch',   
    
    props: {
        time: {
            type: Number,
            default: 11
        },
        data: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>

<style>
    
</style>