<template>
    <div>
        <div @click="$emit('close')" class="flex text-[#BD9135] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div class="text-[#FFF] mt-[10px]">
            <div class="text-center">
                ПРИЧИНА ВІДСУТНОСТІ
            </div>
            <div class="w-[90%] mx-auto mt-[10px] h-px bg-[#FFF]"></div>
        </div>

        <div class="w-[90%] mt-5 mx-auto relative flex h-[58px] bg-[#47484966] bg-opacity-5 rounded-[8px] border-[1px] border-[#8C8C8C33] mb-2 px-[10px]">
            <div class="text-[12px] align-middle w-[80%] overflow-hidden h-[85%] flex items-center text-[#fff]">{{ missedTraining.reason }}</div>
        </div>

    </div>
</template>

<script>
    import { useMainStore } from '../stores/mainStore'

    export default {
        name: 'current-message-view',

        props: {
            missedTraining: {
                type: Object,
                required: true
            }
        },

        setup() {

        }
    }
</script>

<style lang="scss" scoped>

</style>