<template>
    <div class="finances-tab">
        <!-- <div class="finances-tab__header">
            <div class="finances-tab__title">Виплати</div>
            <img :src="selectImage"/>
        </div>
        <div class="finances-tab__chart">
            <img :src="chart"/>
        </div>
<div class="finances-tab__table-wrapper">

    <table class="finances-tab__table">
        <tbody>
            <tr v-for="row in rows" :key="row.id">
                <td >{{row.date}}</td>
                <td class="">
                    <CurrencyComponent :value="row.money" :textNormal="true"/>
                </td>
                <td >{{row.text}}</td>
            </tr>
        </tbody>
    </table>
</div> -->
 </div>

 <div class=" text-center text-gray-400">
    В розробці
  </div>
</template>

<script>
import chart from '@/desktopApp/assets/refactored_assets/img/players/finances-graph.png'
import selectImage from '@/desktopApp/assets/refactored_assets/img/players/select.png'

export default {
    name: 'FinancesTab',
    props: {
       
    },
    data() {
        return {
          chart: chart,
          selectImage: selectImage,
            rows: [
           {
                id: 1,
                date: '06.06-2023',
                money: 40_000,
                text: 'Зарплата за травень 2023'
            },
            {
                id: 1,
                date: '21.06-2023',
                money: 20_000,
                text: 'Аванс за травень 2023'
            },
             {
                id: 1,
                date: '21.06-2023',
                money: 20_000,
                text: 'Аванс за травень 2023'
            },
             {
                id: 1,
                date: '21.06-2023',
                money: 20_000,
                text: 'Аванс за травень 2023'
            },
             {
                id: 1,
                date: '21.06-2023',
                money: 20_000,
                text: 'Аванс за травень 2023'
            },
             {
                id: 1,
                date: '21.06-2023',
                money: 20_000,
                text: 'Аванс за травень 2023'
            },
             {
                id: 1,
                date: '21.06-2023',
                money: 20_000,
                text: 'Аванс за травень 2023'
            },
            
      ],
        }
    },
}
</script>