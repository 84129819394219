<template>
    <svg :width="width" :height="height">
      <rect v-for="(value, index) in data" :key="index" :x="columnX(index)" :y="columnY(value)" :width="columnWidth - columnSpacing" :height="columnHeight(value)" fill="#0ABFC2" />
      <text v-for="(label, index) in labels" :key="index" :x="labelX(index)" :y="labelY" text-anchor="middle" fill="white" font-size="12px">{{ label }}</text>
      <text v-for="(value, index) in data" :key="index" :x="columnX(index) + ((columnWidth - columnSpacing) / 2)" :y="columnY(value) - valueLabelOffset" text-anchor="middle" fill="#0ABFC2" font-size="10px">{{ sum(value) }}</text>
    </svg>
  </template>
  
  <script>
function formatNumber(number) {
  const abbreviations = ['K', 'M', 'B', 'T'];
  const powers = [3, 6, 9, 12];

  for (let i = abbreviations.length - 1; i >= 0; i--) {
    const size = Math.pow(10, powers[i]);
    if (number >= size) {
      const roundedNumber = (number / size).toFixed(1);
      return roundedNumber + abbreviations[i];
    }
  }

  return number.toString();
}

  export default {
    props: {
      data: {
        type: Array,
        required: true,
      },
      labels: {
        type: Array,
        required: true,
      },
      width: {
        type: Number,
        default: 420,
      },
      height: {
        type: Number,
        default: 90,
      },
      margin: {
        type: Number,
        default: 30,
      },
      columnSpacing: {
        type: Number,
        default: 15,
      },
      valueLabelOffset: {
        type: Number,
        default: 10,
      },
    },
    computed: {
      columnWidth() {
        return (this.width - this.margin * 2) / this.data.length;
      },
      columnHeight() {
        const max = Math.max(...this.data) || 1;
        return value => (this.height - this.margin * 2) * (value / max) <= 1 ? 2 : (this.height - this.margin * 2) * (value / max);
      },
      labelX() {
        return index => this.margin + index * this.columnWidth + this.columnWidth / 2;
      },
      labelY() {
        return this.height - this.margin / 2;
      },
      columnX() {
        return index => this.margin + index * this.columnWidth + this.columnSpacing / 2;
      },
      columnY() {
        return value => this.height - this.margin - this.columnHeight(value);
      },
      sum() {
        return value => formatNumber(value);
      },
    },
  };
  </script>
  