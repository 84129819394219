<template>
    <div v-if="loadingSpinnerFlag" class="bg-[#252526] z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div class="bg-[#252526] w-full pb-6">
        <trainer-preview />
        <trainer-data />
        <trainings-block />
        <TrainingCalendar 
            v-if="!calendarDataLoadingFlag" 
            :planedTrainingDays="planedTrainingDates" 
            :completedTrainings="completedTrainings" 
            :completedTrainingsDates="completedTrainingsDates"
            @settedDay="parseTrainingsForDay"
        />
        <trainer-finance />

        <button @click="logout" class="bg-[#DA4167] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
            <div class="w-full">Вийти</div>

            <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#fff"/>
            </svg>
        </button>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'
    import { getCompletedTrainingsByGroup } from '../services/apiRequests'

    import TrainerPreview from '../components/TrainerPreview/TrainerPreview.vue'
    import TrainerData from '../components/TrainerData/TrainerData.vue'
    import TrainingsBlock from '../components/TrainingsBlock/TrainingsBlock.vue'
    import TrainerFinance from '../components/TrainerFinance/TrainerFinance.vue'
    import TrainingCalendar from '../components/TrainingCalendar/TrainingCalendar.vue'

    export default {
        name: 'main-view',

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const loadingSpinnerFlag = ref(true)
            const planedTrainingDates = ref([])
            const calendarDataLoadingFlag = ref(true)
            const completedTrainings = ref([])
            const completedTrainingsDates = ref([])

            const logout = () => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                router.go()
            }

            const parseTrainingsForDay = (date) => {
                const trainings = []

                for (let i = 0; i < store.allTrainings.length; i++) {
                    console.log('store.allTrainings ------- ', store.allTrainings[i].date.split('T')[0])
                    const validDate = new Date(new Date(date).setDate(new Date(date).getDate() + 1, new Date(date).getMonth(), new Date(date).getFullYear())).toISOString().split('T')[0]
                    console.log('date ------- ', validDate)
                    if (store.allTrainings[i].date.split('T')[0] === validDate) {
                        trainings.push(store.allTrainings[i])
                    }
                }

                for (let i = 0; i < completedTrainings.value.length; i++) {
                    console.log('store.allTrainings ------- ', completedTrainings.value[i].date.split('T')[0])
                    const validDate = new Date(new Date(date).setDate(new Date(date).getDate() + 1, new Date(date).getMonth(), new Date(date).getFullYear())).toISOString().split('T')[0]
                    console.log('validDate ------- ', validDate)
                    if (completedTrainings.value[i].date.split('T')[0] === validDate) {
                        trainings.push(completedTrainings.value[i])
                    }
                }

                store.currentDayTrainings = trainings
                store.currentDayForFullView = new Date(new Date(date).setDate(new Date(date).getDate() + 1, new Date(date).getMonth(), new Date(date).getFullYear())).toISOString().split('T')[0]
                router.push('/full-day-training')
            }

            onMounted(async () => {
                await store.getTrainerData()
                await store.getStudentBalanceData()
                await store.getServiceBalanceData()
                await store.getTrainerGroups()
                await store.getAllTrainerPlanedTrainings()
                await store.getTrainerRealIncomes()
                await store.getTrainerTransactions()

                planedTrainingDates.value = store.allTrainings.map(training => training.date.split('T')[0])

                for (let i = 0; i < store.trainer_groups.length; i++) {
                    const response = await getCompletedTrainingsByGroup(store.trainer_groups[i].group_id)
                    console.log('store.trainer_groups[i].group_id ------- ', store.trainer_groups[i].name)
                    console.log('response.data ------- ', response.data)
                    completedTrainings.value.push(...response.data)
                }

                completedTrainingsDates.value = completedTrainings.value.map(training => training.date.split('T')[0])

                calendarDataLoadingFlag.value = false
                loadingSpinnerFlag.value = false
            })

            return {
                logout,
                loadingSpinnerFlag,
                store,
                planedTrainingDates,
                calendarDataLoadingFlag,
                completedTrainings,
                completedTrainingsDates,
                parseTrainingsForDay
            }
        },

        components: {
            TrainerPreview,
            TrainerData,
            TrainingsBlock,
            TrainerFinance,
            TrainingCalendar
        }
    }
</script>
