<template>
    <div class="mt-4">
        <div class="text-white flex justify-between mb-[7px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[15px] font-semibold">ОПЛАТИ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>
        <div>
            <button @click="$router.push('/receive-money')" class="bg-[#BD9135] rounded-[9px] text-[#252526] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
                <div class="w-full">Прийняти оплату (готівка)</div>
            </button>
            <div class="text-center mt-2 text-[#BD9135]">
                прийняті платежі готівкою (10 останніх)
            </div>
            <div class="student-data-table overflow-y-auto h-[300px] mt-2 mx-4 border-[0.2px] border-[#FFF] border-opacity-25 rounded-2xl px-6 py-1">
                <div v-for="transaction in store.allTrainerIncomes" class="flex text-[#FFF] justify-between border-b-[0.2px] border-[#FFF] border-opacity-25 items-center py-3">
                    <div class="font-light text-sm truncate w-[30%]">{{ formateDate(transaction.date) }}</div>
                    <div class="font-bold text-sm truncate pl-[10px] w-[30%]">{{ transaction.amount.toFixed(2) }}</div>
                    <div class="font-bold text-sm truncate w-[40%]">{{ getStudentName(transaction.label) }}</div>
                </div>
            </div>

            <div class="text-center mt-2 text-[#BD9135]">
                транзакції тренера
            </div>
            <div class="student-data-table overflow-y-auto h-[300px] mt-2 mx-4 border-[0.2px] border-[#FFF] border-opacity-25 rounded-2xl px-6 py-1">
                <div v-for="transaction in store.allTrainerTransactions" class="flex text-[#FFF] justify-between border-b-[0.2px] border-[#FFF] border-opacity-25 items-center py-3">
                    <div class="font-light text-sm truncate w-[30%]">{{ formateDate(transaction.date) }}</div>
                    <div 
                        class="font-bold text-sm truncate pl-[10px] w-[30%]"
                        :class="{
                            'text-[#DA4167]' : transaction.sender_balance_id == store.trainer.student_balance_id || transaction.sender_balance_id == store.trainer.service_balance_id,
                            'text-[#0ABFC2]' : transaction.receiver_balance_id == store.trainer.student_balance_id || transaction.receiver_balance_id == store.trainer.service_balance_id
                        }"    
                    >
                        <span>{{ transaction.sender_balance_id == store.trainer.student_balance_id || transaction.sender_balance_id == store.trainer.service_balance_id ? '-' : '+' }}</span>
                        <span>{{ transaction.amount.toFixed(2) }}</span>
                    </div>
                    <div class="font-bold text-sm truncate w-[40%]">{{ transaction.label }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue' 
    import { useMainStore } from '../../stores/mainStore'

    export default {
        name: "trainers-finance",

        setup() {
            const store = useMainStore()

            const getStudentName = (transaction_label) => {
                const transactionLabelArray = transaction_label.split(' ')
                return `${transactionLabelArray[transactionLabelArray.length - 1]} ${transactionLabelArray[transactionLabelArray.length - 2]}`
            }

            const formateDate = (date) => {
                const dateArray = date.split('T')[0].split('-')
                return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`
            }

            return {
                store,
                getStudentName,
                formateDate
            }
        }
    }
</script>
