<template>
    <div>
        <div class="bg-[#BD9135] font-bold w-[90%] text-center rounded-[3px] mx-auto text-black py-[5px]">
            СТВОРИТИ ТРЕНУВАННЯ
        </div>

        <div>
            <TrainingForm @saveTraining="saveTraining"/>            
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { addNewTrainingSummary } from '@/desktopApp/services/apiRequests'
    import TrainingForm from './components/TrainingForm.vue'
    
    export default {
        name: 'TrainingCreationForm',

        setup() {
            const router = useRouter()

            const saveTraining = async (newTrainingObject) => {
                await addNewTrainingSummary(newTrainingObject)
                router.push('/trainings-constructor')
            }

            return {
                saveTraining,
                router
            }
        },

        components: {
            TrainingForm
        }
    }
</script>

<style lang="scss" scoped>

</style>