<template>
    <div class="mt-[10px]">
        <div class="flex flex-col items-center">
            <div class="bg-[#DA4167] relative text-white flex justify-center items-center w-[70%] py-[10px] rounded-xl" :class="{'border-[1px] border-[#BD9135]' : updateProgramNameFlag}">
                <div class="font-black w-full flex justify-center">
                    <div v-if="updateProgramNameFlag">
                        <input v-model="programStructure.name" class="w-[100%] outline-none bg-transparent text-white text-center" type="text" placeholder="Назва програми">
                    </div>
                    <div class="w-full flex justify-center" v-else>{{ programStructure.name ? programStructure.name : 'Введіть назву програми' }}</div>
                </div>
                <div class="absolute right-[10px]">
                </div>
            </div>
            <div class="mt-[10px] cursor-pointer select-none">
            </div>
        </div>
        
        <div class="overflow-x-auto">
            <div class="select-none w-[100%]">
                <div v-for="(meso, mesoId) in programStructure.mesocycles" class="w-[100%] px-[10px]">
                    <div class="flex flex-col items-center mt-[10px]" @click="openMacroCycle(mesoId)">
                        <div class="bg-[#339989] text-white w-[100%] py-[20px] rounded-xl flex flex-col items-center">
                            <div class="font-black">{{ meso.name }}</div>
                        </div>
                        <div class="mt-[10px] cursor-pointer"></div>
                    </div>
                    <div class="flex flex-col items-center mt-[10px]" v-if="macrocyclesOpenFlags[mesoId]" >
                        <div v-for="micro in getMicroCyclesByMesocycle(meso.id)" :class="{'border-[1px]' : openMicrocycleObject.id == micro.id}" @click="openMicrocycle(micro)" class="bg-[#1B4079] mb-[20px] text-white w-[100%] py-[10px] rounded-xl flex flex-col items-center cursor-pointer">
                            <div class="font-black">{{ micro.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="openMicrocycleModalFlag" class=" w-[94%] mt-3 mb-5 rounded-[10px] overflow-x-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                        <th class="px-3 py-2 w-[20%] text-start font-bold text-sm"></th>
                        <th class="px-3 py-2 w-[25%] text-start font-bold text-sm">МЕЗО</th>
                        <th class="px-3 py-2 w-[25%] text-start font-bold text-sm">МІКРО</th>
                        <th class="px-3 py-2 w-[25%] text-start font-bold text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="currentMicrocycleSummaries.length > 0" v-for="summary in currentMicrocycleSummaries" @click="() => {selectedSummary = summary; selectedSummaryFlag = true}" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                            <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                <span class="text-[18px]">{{ summary.name[0] }}</span>
                            </div>
                        </td>
                        <td class="px-3 py-2 w-[20%] text-start font-bold text-sm truncate">{{ summary.name }}</td>
                        <td class="px-3 py-2 w-[25%] text-start font-bold text-sm truncate">{{ summary.mesocycle_name }}</td>
                        <td class="px-3 py-2 w-[25%] text-start font-bold text-sm truncate">{{ summary.microcycle_name }}</td>
                        <td class="px-3 w-[25%] py-2 text-start font-bold text-sm"></td>
                    </tr>

                    <tr v-else>
                        <td colspan="5" class="text-center text-white pt-[15px]">Конспекти відсутні</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <TrainingSummaryPreviousView v-if="selectedSummaryFlag" @close="selectedSummaryFlag = false" :addToTrainingFlag="true" @addSummary="selectSummary" class="absolute top-0 w-full h-full bg-[#252526]" :summary="selectedSummary" />
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useRoute } from 'vue-router'
    import { uuid } from 'vue3-uuid'
    import { getSummariesByMicrocycleId } from '@/mobileApp/services/apiRequests'
    import TrainingSummaryPreviousView from './TrainingSummaryPreviousView.vue'

    export default {
        name: 'TrainingProgramView',

        props: {
            program: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props, { emit }) {
            const programStructure = ref({
                name: props.program?.name ? props.program?.name : '',
                description: props.program?.description ? props.program?.description : '',
                age_group: props.program?.age_group ? props.program?.age_group : '',
                mesocycles: props.program?.mesocycles ? props.program?.mesocycles : [],
                microcycles: props.program?.microcycles ? props.program?.microcycles : [],
                groups: props.program?.groups ? props.program?.groups : []
            })

            const selectedSummary = ref({})
            const selectedSummaryFlag = ref(false)

            const updateProgramNameFlag = ref(false) 

            const openMicrocycleModalFlag = ref(false)
            const openMicrocycleObject = ref({})

            const currentMicrocycleSummaries = ref([])
            const openTrainingFormFlag = ref(false)

            const macrocyclesOpenFlags = ref([false, false, false, false])

            const selectSummary = () => {
                selectedSummaryFlag.value = true
                emit('addSummary', selectedSummary.value)
            }
            
            const openMacroCycle = (index) => {
                macrocyclesOpenFlags.value[index] = !macrocyclesOpenFlags.value[index]

                for (let i = 0; i < macrocyclesOpenFlags.value.length; i++) {
                    if (i !== index) {
                        macrocyclesOpenFlags.value[i] = false
                    }
                }
            }

            const getMicroCyclesByMesocycle = (mesocycleId) => {
                return programStructure.value.microcycles.filter(microcycle => microcycle.mesocycle_id === mesocycleId)
            }

            const addMicrocycle = (mesocycleId) => {
                const countMicrocycles = programStructure.value.microcycles.filter(microcycle => microcycle.mesocycle_id === mesocycleId).length
                if (countMicrocycles < 4) {
                    const newMicrocycle = {
                        id: uuid.v4(),
                        name: `Мікроцикл ${countMicrocycles + 1}`,
                        mesocycle_id: mesocycleId
                    }
                    programStructure.value.microcycles.push(newMicrocycle)
                }
            }

            const addMesocycle = () => {
                if (programStructure.value.mesocycles.length < 4) {
                    const newMesocycle = {
                        id: uuid.v4(),
                        name: `Мезоцикл ${programStructure.value.mesocycles.length + 1}`
                    }
                    programStructure.value.mesocycles.push(newMesocycle)
                } 
            }

            const saveProgram = () => {
                emit('saveProgram', programStructure.value)
            }

            const openMicrocycle = async (microcycle) => {
                openMicrocycleObject.value = microcycle
                openMicrocycleModalFlag.value = true

                const result = await getSummariesByMicrocycleId(microcycle.id)
                currentMicrocycleSummaries.value = result.data
            }

            const addSummariesToMicrocycle = async (summaries) => {
                for (let i = 0; i < summaries.length; i++) {
                    const summary = summaries[i]

                    const mesocycleObject = programStructure.value.mesocycles.find(mesocycle => mesocycle.id === openMicrocycleObject.value.mesocycle_id)
                    const microcycleObject = programStructure.value.microcycles.find(microcycle => microcycle.id === openMicrocycleObject.value.id)
                    const programObject = {
                        id: props.program.id,
                        name: props.program.name,
                        description: props.program.description,
                        age_group: props.program.age_group,
                        mesocycles: programStructure.value.mesocycles,
                        microcycles: programStructure.value.microcycles,
                        groups: programStructure.value.groups
                    }

                    await addSummaryToProgram(
                        programObject.id, 
                        programObject.name,
                        microcycleObject.id,
                        microcycleObject.name,
                        mesocycleObject.id,
                        mesocycleObject.name, 
                        summary.summary_id)
                }

                const result = await getSummariesByMicrocycleId(openMicrocycleObject.value.id)
                currentMicrocycleSummaries.value = result.data
                openTrainingFormFlag.value = false
            }

            const removeSummary = async (summary) => {
                await addSummaryToProgram(
                    '', 
                    '',
                    '',
                    '',
                    '',
                    '', 
                    summary.summary_id)
                openTrainingFormFlag.value = false

                const result = await getSummariesByMicrocycleId(openMicrocycleObject.value.id)
                currentMicrocycleSummaries.value = result.data
            }

            onMounted(() => {
                console.log('TrainingProgramView mounted')
            })

            return {
                programStructure,
                getMicroCyclesByMesocycle,
                addMicrocycle,
                addMesocycle,
                updateProgramNameFlag,
                openMicrocycleModalFlag,
                openMicrocycleObject,
                openMicrocycle,
                saveProgram,
                currentMicrocycleSummaries,
                openTrainingFormFlag,
                addSummariesToMicrocycle,
                removeSummary,
                openMacroCycle,
                macrocyclesOpenFlags,
                selectedSummary,
                selectedSummaryFlag,
                selectSummary
            }
        },

        components: {
            TrainingSummaryPreviousView
        }
    }
</script>

<style lang="scss" scoped>

</style>


