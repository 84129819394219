<template>
    <div>
        <div class="bg-[#BD9135] font-bold w-[90%] text-center rounded-[3px] mx-auto text-black py-[5px]">
            РЕДАГУВАТИ ТРЕНУВАННЯ
        </div>

        <div v-if="summaryUploadFlag">
            <TrainingForm :summary="currentSummary" @saveTraining="updateTraining"/>            
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { getSummaryById, updateSummaryById } from '@/desktopApp/services/apiRequests'
    import TrainingForm from './components/TrainingForm.vue'
    export default {
        name: 'TrainingUpdateForm',

        setup() {
            const router = useRouter()
            const route = useRoute()

            const currentSummary = ref({})
            const summaryUploadFlag = ref(false)

            onMounted(async () => {
                currentSummary.value = await getSummaryById(route.params.id)
                summaryUploadFlag.value = true
                console.log('route.params.id --------- ', route.params.id)
                console.log('currentSummary --------- ', currentSummary.value)
            })

            const updateTraining = async (newTrainingObject) => {
                console.log('newTrainingObject --------- ', newTrainingObject)
                newTrainingObject.program_id = currentSummary.value.program_id
                newTrainingObject.program_name = currentSummary.value.program_name
                await updateSummaryById(currentSummary.value.summary_id, newTrainingObject)
                router.push('/trainings-constructor')
            }

            return {
                currentSummary,
                summaryUploadFlag,
                updateTraining
            }
        },

        components: {
            TrainingForm
        }
    }
</script>

<style lang="scss" scoped>

</style>