<template>
    <div>
        <component :is="currentComponent" ></component>
    </div>
</template>

<script>
    import MainView from '../views/MainView.vue'
    import LoginView from '../views/LoginView.vue'
    import AllTrainingsView from '../views/AllTrainingsView.vue'
    import StudentRollCallView from '../views/StudentRollCallView.vue'   
    import GoodTrainingWishView from '../views/GoodTrainingWishView.vue'
    import ReceiveMoneyView from '../views/ReceiveMoneyView.vue'
    import SuccessTransactionView from '../views/SuccessTransactionView.vue'
    import CompletedTrainingsView from '../views/CompletedTrainingsView.vue'
    import CompletedTrainingView from '../views/CompletedTrainingView.vue'
    import AddNewTraining from '../views/AddNewTraining.vue'
    import GroupView from '../views/GroupView.vue'
    import CurrentStudentView from '../views/CurrentStudentView.vue'
    import AddNewStudent from '../views/AddNewStudent.vue'
    import FullDayTrainingView from '../views/FullDayTrainingView.vue'
    import StatisticsView from '../views/StatisticsView.vue'
    import NewMessageView from '../views/NewMessageView.vue'
    import AllMessagesView from '../views/AllMessagesView.vue'
    import CurrentMessageView from '../views/CurrentMessageView.vue'
    import TrainingSummaryView from '../views/TrainingSummaryView.vue'

    import { ref } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { onMounted, onUpdated } from 'vue'

    export default {
        name: 'MainLayout',
        
        setup() {
            const currentComponent = ref('LoginView')
            const router = useRouter()
            const route = useRoute()


            onMounted(async () => {
                if (localStorage.access_token && localStorage.refresh_token) {                    
                    currentComponent.value = route.meta.component
                } else {
                    currentComponent.value = 'LoginView'
                }
            })

            onUpdated(() => {
                if (localStorage.access_token && localStorage.refresh_token) {
                    currentComponent.value = route.meta.component
                }
            })

            return {
                currentComponent
            }
        },

        components: {
            MainView,
            LoginView,
            AllTrainingsView,
            StudentRollCallView,
            GoodTrainingWishView,
            ReceiveMoneyView,
            SuccessTransactionView,
            CompletedTrainingsView,
            CompletedTrainingView,
            AddNewTraining,
            GroupView,
            CurrentStudentView,
            AddNewStudent,
            FullDayTrainingView,
            StatisticsView,
            NewMessageView,
            AllMessagesView,
            CurrentMessageView,
            TrainingSummaryView
        }
    }
</script>