<template>
    <div>
      <div v-if="updateSchemeFlag" class="absolute flex flex-col left-[50%] translate-x-[-50%] px-[20px] justify-evenly top-[50%] translate-y-[-50%] ml-[150px] rounded-[10px] w-[300px] h-[200px] bg-[#252526]">
        <div class="flex justify-center items-center">
          <div class="text-white text-[21px] font-semibold">
            Схема оновлена
          </div>
        </div>
        <div @click="updateSchemeFlag = false" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
          OK
        </div>
      </div>
      <div v-if="saveSchemeFlag" class="absolute flex flex-col left-[50%] translate-x-[-50%] px-[20px] justify-evenly top-[50%] translate-y-[-50%] ml-[150px] rounded-[10px] w-[300px] h-[200px] bg-[#252526]">
        <div class="flex justify-center items-center">
          <div class="text-white text-[21px] font-semibold">
            Схема збережена
          </div>
        </div>
        <div @click="saveSchemeFlag = false" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
          OK
        </div>
      </div>
      <div v-if="nameErrorFlag" class="absolute flex flex-col left-[50%] translate-x-[-50%] px-[20px] justify-evenly top-[50%] translate-y-[-50%] ml-[150px] rounded-[10px] w-[300px] h-[200px] bg-[#252526]">
        <div class="flex justify-center items-center">
          <div class="text-white text-[21px] text-center font-semibold">
            Схема з такою назвою вже існує
          </div>
        </div>
        <div @click="nameErrorFlag = false" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
          OK
        </div>
      </div>
      <div class="flex justify-center mb-[10px]">
        <input placeholder="Введіть назву нової схеми" v-model="name" class="text-center text-white bg-transparent outline-none font-semibold text-[21px]"/>
      </div>
      <div class="flex justify-center select-none" :class="{'flex-col-reverse items-center' : screenWidth <= 880}">
        <div v-if="!selectShowFlag" id="components" :class="{'mt-[20px] w-[80%]' : screenWidth <= 880, 'mr-[20px]' : screenWidth > 880}" class="flex flex-col justify-between py-[10px] border-[1px] px-[20px] border-[#363636] rounded-md">
          <div class="w-[300px]">
            <div class="text-center text-[21px] text-white border-white border-b-[1px] pb-[5px]">
              Компоненти
            </div>
            <div class="mt-[20px]">
              <div class="flex flex-col justify-center relative">
                <div class="flex ">
                  <div @click="() => {selectNewComponent('player1'); countPlayers.firstPlayer++}" id="player1" class=" w-max pr-[10px] flex justify-center align-middle cursor-pointer">
                    <player-one :screenWidth="screenWidth" :number="countPlayers.firstPlayer"/>
                  </div>
                  <!-- <div @click="() => {selectNewComponent('player2'); countPlayers.secondPlayer++}" id="player2" class=" w-max pr-[10px] flex justify-center align-middle cursor-pointer">
                    <player-two :screenWidth="screenWidth" :number="countPlayers.secondPlayer"/>
                  </div> -->
                  <div @click="() => {selectNewComponent('small-player'); countPlayers.secondPlayer++}" id="small-player" class=" w-max pr-[10px] flex justify-center align-middle cursor-pointer">
                    <small-player :screenWidth="screenWidth" :number="countPlayers.secondPlayer"/>
                  </div>
                </div>
                <div class="flex items-center mt-[20px]">
                  <div @click="selectNewComponent('circle1')" id="circle1" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <dot :screenWidth="screenWidth" :color="'#FFF'" />
                  </div>
                  <div @click="selectNewComponent('linear-dot1')" id="linear-dot1" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <linear-dot :screenWidth="screenWidth" :color="'#FFF'" />
                  </div>
                  <div @click="selectNewComponent('ball')" id="ball" class="w-max py-[20px] flex justify-center items-center cursor-pointer">
                    <ball :screenWidth="screenWidth"/>
                  </div>
                  <div @click="selectNewComponent('cone')" id="cone" class="w-max py-[20px] ml-[10px] flex justify-center items-center cursor-pointer">
                    <cone :screenWidth="screenWidth"/>
                  </div>
                </div>

                <div class="flex items-center mt-[20px]">
                  <div @click="selectNewComponent('ballon-player')" id="ballon-player" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <baloon-player />
                  </div>
                  <div @click="selectNewComponent('metal-player')" id="metal-player" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <metal-player />
                  </div>
                  <div @click="selectNewComponent('stairs')" id="stairs" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <Stairs />
                  </div>
                  <div @click="selectNewComponent('barrier')" id="barrier" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <Barrier />
                  </div>
                  <div @click="selectNewComponent('cone-barrier')" id="cone-barrier" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <ConeBarrier />
                  </div>
                  <div @click="selectNewComponent('flag1')" id="flag1" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <flag :screenWidth="screenWidth" :color="'#BD9135'"/>
                  </div>
                  <div @click="selectNewComponent('stick')" id="stick" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <Stick :screenWidth="screenWidth" :color="'#BD9135'"/>
                  </div>
                </div>
                <div class="flex mt-[20px]">
                  <div @click="selectNewComponent('right-gates')" id="right-gates" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <right-gates :screenWidth="screenWidth" :color="'#FF0000'" />
                  </div>
                  <div @click="selectNewComponent('front-gates')" id="front-gates" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <front-gates :screenWidth="screenWidth" :color="'#FF9900'" />
                  </div>
                  <div @click="selectNewComponent('left-gates')" id="left-gates" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                    <left-gates :screenWidth="screenWidth" :color="'#52FF00'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="flex justify-between bg-[#0f0f0f] mt-[10px] px-[10px] py-[10px] rounded-[9px]">
              <div class="flex">
                <div @click="deleteLastElement" class="px-[3px] py-[3px] rounded-md"><img @click="" src="./assets/back_icon.svg" class="w-[25px] cursor-pointer"></div>
                <div @click="startDrawing" class="px-[3px] py-[3px] rounded-md" :class="{'bg-yellow-700' : startsDrawingFlag}"><img @click="" src="./assets/pen_icon.svg" class="w-[25px] cursor-pointer"></div>
                <div @click="() => {nowDrawingLine = !nowDrawingLine; nowDrawingCurveLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false; startAddingTextFieldFlag = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingLine}"><img @click="" src="./assets/arrow_icon.svg" class="w-[25px] cursor-pointer"></div>
                <div @click="() => {nowDrawingCurveLine = !nowDrawingCurveLine; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false; startAddingTextFieldFlag = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingCurveLine}"><img @click="" src="./assets/curve_arrow_icon.svg" class="w-[25px] cursor-pointer"></div>
                <div @click="() => {nowDrawingBrokenLine = !nowDrawingBrokenLine; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingSinusoidLine = false; startAddingTextFieldFlag = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingBrokenLine}"><img @click="" src="./assets/broken_arrow_icon.svg" class="w-[25px] cursor-pointer"></div>
                <div @click="() => {nowDrawingSinusoidLine = !nowDrawingSinusoidLine; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; startAddingTextFieldFlag = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingSinusoidLine}"><img @click="" src="./assets/sinusoidal_line_icon.svg" class="w-[25px] cursor-pointer"></div>
                <div @click="() => {startAddingTextFieldFlag = !startAddingTextFieldFlag; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : startAddingTextFieldFlag}"><img @click="" src="./assets/text_icon.svg" class="w-[18px] cursor-pointer"></div>
                <div @click="() => {nowDrawingSquareSection = !nowDrawingSquareSection; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingSquareSection}"><img @click="" src="./assets/square_icon.svg" class="w-[18px] cursor-pointer"></div>
                <div @click="() => {nowDrawingCircleSection = !nowDrawingCircleSection; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingCircleSection}"><img @click="" src="./assets/circle_icon.svg" class="w-[18px] cursor-pointer"></div>
              </div>
              <div @click="clearAllField" class="px-[3px] py-[3px] rounded-md"><img @click="" src="./assets/trash_icon.svg" class="w-[25px] cursor-pointer"></div>
            </div>
            <div @click="downloadImageAsPNG" class="bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Завантажити
            </div>
            <div v-if="savedSchemaFlag" @click="createEmptySchema" class="bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Нова схема
            </div>
            <div v-if="savedSchemaFlag" class="flex w-full justify-between">
              <div @click="updateSchema" class="w-[48%] bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Оновити
              </div>
              <div @click="deleteSchema" class="w-[48%] bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Видалити
              </div>
            </div>
            <button v-else-if="!elementId" :disabled="name.length == 0" @click="saveNewSchema" class="w-full disabled:opacity-40 bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Зберегти
            </button>
            <button v-else-if="elementId" :disabled="name.length == 0" @click="addToElement" class="w-full disabled:opacity-40 bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Додати до елемента
            </button>
            <div @click="selectShowFlag = true" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Вибрати з каталога
            </div>
            <div v-if="addedToElementSchema" @click="$router.push(`/training-element/${elementIdRouteParam}`)" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              До елемента
            </div>
          </div>
        </div>
        <SchemasSelect v-else @return="selectShowFlag = false" @selectSchema="schema => selectSchema(schema)" />
        <div id="schema-container" class="relative">
          <div v-if="startInputTextFieldFlag" class="absolute" :style="{'left' : newTextFieldCoords.x + 'px', 'top' : newTextFieldCoords.y + 'px'}">
            <div class="flex justify-center items-center">
              <input v-model="newTextFieldValue" class="w-[90%] outline-none"/>
              <div @click="addTextFieldToCanvas" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold w-[65px]">
                OK
              </div>
            </div>
          </div>
          <Field :screenWidth="screenWidth" />
        </div>
      </div>
      <div v-if="lineSelectedFlag" class="bg-black rounded-md w-[75%] mx-auto py-[10px] mt-[15px]">
        <div class="w-full text-white text-center py-[10px]">Редагування прямої</div>

        <div class="px-[10px] flex">

          <div class="w-[25%]">
            <div @click="changeLineStrokeStyle('dotted')" :class="{'bg-[#BD9135]' : currentLineSettings.strokeStyle == 'dotted'}" class="p-[10px] w-max rounded-md cursor-pointer">
              <svg width="193" height="4" viewBox="0 0 193 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round" stroke-dasharray="6 6"/>
              </svg>
            </div>
            <div @click="changeLineStrokeStyle('solid')" :class="{'bg-[#BD9135]' : currentLineSettings.strokeStyle == 'solid'}" class="p-[10px] w-max rounded-md cursor-pointer">
              <svg width="193" height="4" viewBox="0 0 193 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>

          <div class="w-[25%]">

            <div class="flex">
              <div @click="changeLineStartStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentLineSettings.startStyle == 'arrow'}">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0.5L1 6L7.5 11.5" stroke="white"/>
                </svg>
              </div>
              <div @click="changeLineEndStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentLineSettings.endStyle == 'arrow'}">
                <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 12L7.5 6.5L1 1" stroke="white"/>
                </svg>
              </div>
            </div>
          
            <div class="flex">
              <div @click="changeLineStartStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentLineSettings.startStyle == 'none'}">
                <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>

              </div>
              <div @click="changeLineEndStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentLineSettings.endStyle == 'none'}">
                <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
          
          </div>

          <div class="w-[25%]">
            <div class="text-white">
              Ширина лінії
            </div>
            <div class="flex items-center">
              <div class="cursor-pointer" @click="changeLineStrokeWeight(+currentLineSettings.strokeWeight + 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="6" fill="white"/>
                  <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                </svg>
              </div>
              <div class="text-white text-center font-bold mx-[10px]">{{currentLineSettings.strokeWeight}}</div>
              <div class="cursor-pointer" @click="changeLineStrokeWeight(+currentLineSettings.strokeWeight - 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="white"/>
                <path d="M6 6H3H9" stroke="black"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="w-[25%]">
            <div @click="deleteLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Видалити
            </div>
          </div>

        </div>
      </div>
      <div v-if="curveLineSelectedFlag" class="bg-black rounded-md w-[75%] mx-auto py-[10px] mt-[15px]">
        <div class="w-full text-white text-center py-[10px]">Редагування кривої</div>

        <div class="px-[10px] flex">

          <div class="w-[25%]">
            <div @click="changeCurveLineStrokeStyle('dotted')" :class="{'bg-[#BD9135]' : currentCurveLineSettings.strokeStyle == 'dotted'}" class="p-[10px] w-max rounded-md cursor-pointer">
              <svg width="193" height="4" viewBox="0 0 193 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round" stroke-dasharray="6 6"/>
              </svg>
            </div>
            <div @click="changeCurveLineStrokeStyle('solid')" :class="{'bg-[#BD9135]' : currentCurveLineSettings.strokeStyle == 'solid'}" class="p-[10px] w-max rounded-md cursor-pointer">
              <svg width="193" height="4" viewBox="0 0 193 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>

          <div class="w-[25%]">

            <div class="flex">
              <div @click="changeCurveLineStartStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentCurveLineSettings.startStyle == 'arrow'}">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0.5L1 6L7.5 11.5" stroke="white"/>
                </svg>
              </div>
              <div @click="changeCurveLineEndStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentCurveLineSettings.endStyle == 'arrow'}">
                <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 12L7.5 6.5L1 1" stroke="white"/>
                </svg>
              </div>
            </div>
          
            <div class="flex">
              <div @click="changeCurveLineStartStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentCurveLineSettings.startStyle == 'none'}">
                <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>

              </div>
              <div @click="changeCurveLineEndStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentCurveLineSettings.endStyle == 'none'}">
                <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
          
          </div>

          <div class="w-[25%]">
            <div class="text-white">
              Ширина лінії
            </div>
            <div class="flex items-center">
              <div class="cursor-pointer" @click="changeCurveLineStrokeWeight(+currentCurveLineSettings.strokeWeight + 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="6" fill="white"/>
                  <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                </svg>
              </div>
              <div class="text-white text-center font-bold mx-[10px]">{{currentCurveLineSettings.strokeWeight}}</div>
              <div class="cursor-pointer" @click="changeCurveLineStrokeWeight(+currentCurveLineSettings.strokeWeight - 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="white"/>
                <path d="M6 6H3H9" stroke="black"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="w-[25%]">
            <div @click="deleteCurveLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Видалити
            </div>
          </div>

        </div>
      </div>
      <div v-if="brokenLineSelectedFlag" class="bg-black rounded-md w-[75%] mx-auto py-[10px] mt-[15px]">
        <div class="w-full text-white text-center py-[10px]">Редагування ламаної</div>

        <div class="px-[10px] flex">

          <div class="w-[25%]">
            <div @click="changeBrokenLineStrokeStyle('dotted')" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.strokeStyle == 'dotted'}" class="p-[10px] w-max rounded-md cursor-pointer">
              <svg width="193" height="4" viewBox="0 0 193 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round" stroke-dasharray="6 6"/>
              </svg>
            </div>
            <div @click="changeBrokenLineStrokeStyle('solid')" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.strokeStyle == 'solid'}" class="p-[10px] w-max rounded-md cursor-pointer">
              <svg width="193" height="4" viewBox="0 0 193 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>

          <div class="w-[25%]">

            <div class="flex">
              <div @click="changeBrokenLineStartStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.startStyle == 'arrow'}">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0.5L1 6L7.5 11.5" stroke="white"/>
                </svg>
              </div>
              <div @click="changeBrokenLineEndStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.endStyle == 'arrow'}">
                <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 12L7.5 6.5L1 1" stroke="white"/>
                </svg>
              </div>
            </div>
          
            <div class="flex">
              <div @click="changeBrokenLineStartStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.startStyle == 'none'}">
                <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>

              </div>
              <div @click="changeBrokenLineEndStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.endStyle == 'none'}">
                <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
          
          </div>

          <div class="w-[25%]">
            <div class="text-white">
              Ширина лінії
            </div>
            <div class="flex items-center">
              <div class="cursor-pointer" @click="changeBrokenLineStrokeWeight(+currentBrokenLineSettings.strokeWeight + 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="6" fill="white"/>
                  <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                </svg>
              </div>
              <div class="text-white text-center font-bold mx-[10px]">{{currentBrokenLineSettings.strokeWeight}}</div>
              <div class="cursor-pointer" @click="changeBrokenLineStrokeWeight(+currentBrokenLineSettings.strokeWeight - 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="white"/>
                <path d="M6 6H3H9" stroke="black"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="w-[25%]">
            <div @click="deleteBrokenLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Видалити
            </div>
          </div>

        </div>
      </div>
      <div v-if="sinusoidLineSelectedFlag" class="bg-black rounded-md w-[75%] mx-auto py-[10px] mt-[15px]">
        <div class="w-full text-white text-center py-[10px]">Редагування сінусоїди</div>

        <div class="px-[10px] flex">

          <div class="w-[25%]">
            <div @click="changeSinusoidLineStrokeStyle('dotted')" :class="{'bg-[#BD9135]' : currentSinusoidLineSettings.strokeStyle == 'dotted'}" class="p-[10px] w-max rounded-md cursor-pointer">
              <svg width="193" height="4" viewBox="0 0 193 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round" stroke-dasharray="6 6"/>
              </svg>
            </div>
            <div @click="changeSinusoidLineStrokeStyle('solid')" :class="{'bg-[#BD9135]' : currentSinusoidLineSettings.strokeStyle == 'solid'}" class="p-[10px] w-max rounded-md cursor-pointer">
              <svg width="193" height="4" viewBox="0 0 193 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>

          <div class="w-[25%]">

            
          
          </div>

          <div class="w-[25%]">
            <div class="text-white">
              Ширина лінії
            </div>
            <div class="flex items-center">
              <div class="cursor-pointer" @click="changeSinusoidLineStrokeWeight(+currentSinusoidLineSettings.strokeWeight + 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="6" fill="white"/>
                  <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                </svg>
              </div>
              <div class="text-white text-center font-bold mx-[10px]">{{currentSinusoidLineSettings.strokeWeight}}</div>
              <div class="cursor-pointer" @click="changeSinusoidLineStrokeWeight(+currentSinusoidLineSettings.strokeWeight - 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="white"/>
                <path d="M6 6H3H9" stroke="black"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="w-[25%]">
            <div @click="deleteSinusoidLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Видалити
            </div>
          </div>

        </div>
      </div>
      <div v-if="textSelectedFlag" class="bg-black rounded-md w-[75%] mx-auto py-[10px] mt-[15px]">
        <div class="w-full text-white text-center py-[10px]">Редагування тексту</div>

        <div class="px-[10px] flex">

          <div class="w-[25%]">
            <div class="text-white">
              Колір тексту
            </div>
            <div class="flex">
              <div @click="changeTextColor('black')" class="bg-black border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'black'}"></div>
              <div @click="changeTextColor('white')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'white'}"></div>
              <div @click="changeTextColor('red')" class="bg-red-600 h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'red'}"></div>
              <div @click="changeTextColor('green')" class="bg-green-600 h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'green'}"></div>
              <div @click="changeTextColor('blue')" class="bg-blue-600 h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'blue'}"></div>
              <div @click="changeTextColor('yellow')" class="bg-yellow-600 h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'yellow'}"></div>
            </div>
          </div>

          <div class="w-[25%]">
            <div class="text-white">
              Стиль шрифту
            </div>
            <div>
              <div @click="changeTextFontStyle('normal')" class="p-[5px] text-white h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentTextSettings.fontStyle == 'normal'}">
                Звичайний
              </div>
              <div @click="changeTextFontStyle('italic')" class="p-[5px] text-white h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentTextSettings.fontStyle == 'italic'}">
                Курсив
              </div>
              <div @click="changeTextFontWeight('800')" class="p-[5px] text-white h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentTextSettings.fontStyle == 'bold'}">
                Жирний
              </div>
            </div>
          </div>

          <div class="w-[25%]">
            <div class="text-white">
              Розмір шрифту
            </div>
            <div class="flex items-center">
              <div class="cursor-pointer" @click="changeTextFontSize(+currentTextSettings.fontSize + 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="6" fill="white"/>
                  <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                </svg>
              </div>
              <div class="text-white text-center font-bold mx-[10px]">{{currentTextSettings.fontSize}}</div>
              <div class="cursor-pointer" @click="changeTextFontSize(+currentTextSettings.fontSize - 1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="white"/>
                <path d="M6 6H3H9" stroke="black"/>
                </svg>
              </div>
            </div>
            <div class="flex items-center">
              <input @change="e => changeTextValue(e.target.value)" :value="currentTextSettings.text" class="bg-slate-300" placeholder="Введіть текст..."  />
            </div>
          </div>

          <div class="w-[25%]">
            <div @click="deleteSinusoidLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Видалити
            </div>
          </div>

        </div>
      </div>
      <div v-if="selectedComponentSettingsFlag" class="bg-black rounded-md w-[75%] mx-auto py-[10px] mt-[15px] select-none">
        <div class="w-full text-white text-center py-[10px]">Редагування тексту</div>

        <div class="px-[10px] flex">

          <div class="w-[25%]">
            <div class="text-white">
              Колір елементу
            </div>
            <div class="flex">
              <div @click="changeComponentColor('#474849')" class="bg-[#474849] border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'black'}"></div>
              <div @click="changeComponentColor('white')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'white'}"></div>
              <div @click="changeComponentColor('#C59014')" class="bg-[#C59014] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'red'}"></div>
              <div @click="changeComponentColor('#8FC408')" class="bg-[#8FC408] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'green'}"></div>
              <div @click="changeComponentColor('#0ABFC2')" class="bg-[#0ABFC2] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
              <div @click="changeComponentColor('#DA4167')" class="bg-[#DA4167] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
              <div @click="changeComponentColor('#1B4079')" class="bg-[#1B4079] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'yellow'}"></div>
            </div>
          </div>

          <div class="w-[25%]">
            <div class="text-white">
              Розмір елементу
            </div>
            <div class="flex items-center">
              <div class="cursor-pointer" @click="changeComponentScale(+selectedComponentSettings.scale + 0.05)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="6" fill="white"/>
                  <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                </svg>
              </div>
              <div class="text-white text-center font-bold mx-[10px]">{{selectedComponentSettings.scale * 100}}%</div>
              <div class="cursor-pointer" @click="changeComponentScale(+selectedComponentSettings.scale - 0.05)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="white"/>
                <path d="M6 6H3H9" stroke="black"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="w-[25%]">
            <div class="text-white">
              Поворот елементу
            </div>
            <div class="flex items-center">
              <div class="cursor-pointer" @click="changeComponentRotate(+selectedComponentSettings.rotate - 5)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.63523 10.117C0.937534 9.3773 0.437455 8.47039 0.185387 7.48018C-0.104303 6.34217 -0.0536836 5.14417 0.330971 4.03465C0.715626 2.92514 1.41741 1.95289 2.34933 1.2384C3.28126 0.523908 4.40237 0.0985928 5.5737 0.0151644C6.74504 -0.0682639 7.9151 0.193862 8.93889 0.769056C9.96267 1.34425 10.7952 2.20722 11.3332 3.25102C11.8712 4.29482 12.0911 5.47355 11.9656 6.64113C11.8402 7.80871 11.3748 8.9138 10.6273 9.81945L8.48791 8.05358C8.88983 7.56665 9.14002 6.97248 9.20748 6.34472C9.27495 5.71696 9.15671 5.0832 8.86744 4.52199C8.57816 3.96078 8.13056 3.4968 7.58011 3.18754C7.02966 2.87828 6.40056 2.73734 5.77078 2.7822C5.141 2.82706 4.53823 3.05573 4.03717 3.43988C3.53611 3.82404 3.15879 4.34678 2.95197 4.94332C2.74516 5.53986 2.71794 6.18398 2.8737 6.79585C2.99267 7.2632 3.21439 7.69604 3.52054 8.06385L4.4974 7.00003L4.67134 11.0822L0.589186 11.2561L1.63523 10.117Z" fill="white"/>
                </svg>
              </div>
              <div class="text-white text-center font-bold mx-[10px] w-[100px]">{{selectedComponentSettings.rotate}}°</div>
              <div class="cursor-pointer" @click="changeComponentRotate(+selectedComponentSettings.rotate + 5)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3644 10.4967C11.062 9.75706 11.5621 8.85017 11.8141 7.85999C12.1038 6.72198 12.0532 5.52398 11.6685 4.41447C11.2839 3.30495 10.5821 2.3327 9.65018 1.61821C8.71825 0.903722 7.59714 0.478407 6.42581 0.394979C5.25448 0.31155 4.08441 0.573676 3.06062 1.14887C2.03684 1.72406 1.20434 2.58704 0.666318 3.63083C0.128293 4.67463 -0.0916091 5.85336 0.0338652 7.02094C0.159339 8.18852 0.624674 9.29362 1.3722 10.1993L3.5116 8.43339C3.10968 7.94646 2.85949 7.3523 2.79203 6.72454C2.72457 6.09677 2.8428 5.46302 3.13207 4.90181C3.42135 4.3406 3.86895 3.87661 4.4194 3.56735C4.96985 3.25809 5.59895 3.11716 6.22873 3.16201C6.85851 3.20687 7.46128 3.43555 7.96234 3.8197C8.4634 4.20385 8.84073 4.72659 9.04754 5.32314C9.25435 5.91968 9.28157 6.5638 9.12581 7.17566C9.00685 7.64298 8.78515 8.0758 8.47904 8.44358L7.50225 7.37984L7.3283 11.462L11.4105 11.6359L10.3644 10.4967Z" fill="white"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="w-[25%]">
            <div @click="deletePresetComponent" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
              Видалити
            </div>
          </div>

        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, reactive, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { createNewTrainingSchemaRequest, getSvgCodeOfSchema, deleteSchemaByIdRequest, updateSchemaByIdRequest, getSchemaById } from '@/desktopApp/services/apiRequests'

  import SchemasSelect from './components/SchemasSelect.vue'
  
  import Field from './components/Field.vue'
  import PlayerOne from './components/PlayerOne.vue'
  import PlayerTwo from './components/PlayerTwo.vue'
  import Ball from './components/Ball.vue'
  import Flag from './components/Flag.vue'
  import Dot from './components/Dot.vue'
  import SmallPlayer from './components/SmallPlayer.vue'
  import LinearDot from './components/LinearDot.vue'
  import BaloonPlayer from './components/BaloonPlayer.vue'
  import MetalPlayer from './components/MetalPlayer.vue'
  import Stairs from './components/Stairs.vue'
  import Barrier from './components/Barrier.vue'
  import ConeBarrier from './components/ConeBarrier.vue'
  import Stick from './components/Stick.vue'
  import RightGates from './components/RightGates.vue'
  import LeftGates from './components/LeftGates.vue'
  import FrontGates from './components/FrontGates.vue'
  import Cone from './components/Cone.vue'

  function isPointOnSegment(A, B, K) {
    // Функция проверяет, находится ли точка K на отрезке AB

    // Рассчитываем уравнение прямой через точки A и B: y = mx + c
    let m = (B[1] - A[1]) / (B[0] - A[0]);
    let c = A[1] - m * A[0];

    // Проверяем, лежит ли точка K на прямой AB
    if (K[1] === m * K[0] + c) {
      console.log('in line')
        // Проверяем, находится ли точка K между точками A и B по оси X
        if ((K[0] >= A[0] && K[0] <= B[0]) || (K[0] <= A[0] && K[0] >= B[0])) {
            // Проверяем, находится ли точка K между точками A и B по оси Y
            if ((K[1] >= A[1] && K[1] <= B[1]) || (K[1] <= A[1] && K[1] >= B[1])) {
                return true;
            }
        }
    }

    return false;
  }
  
  export default {
    name: 'TrainingSchemes',
  
    setup() {  
      const startsDrawingFlag = ref(false)
      const processDrawingFlag = ref(false)

      const newPathId = ref(null)

      //line vars
      const newLinePathId = ref(null)
      const newCurveLinePathId = ref(null)
      const newBrokenLinePathId = ref(null)
      
      const nowDrawingLine = ref(false)
      const nowDrawingCurveLine = ref(false)
      const nowDrawingBrokenLine = ref(false)

      const newLineD = ref('')
      const newCurveLineD = ref('')
      const newBrokenLineD = ref('')

      const startLineDrawingCoords = ref({ x: 0, y: 0 })
      const startCurveLineDrawingCoords = ref({ x: 0, y: 0 })
      const startBrokenLineDrawingCoords = ref({ x: 0, y: 0 })

      const curveProjectingFlag = ref(false)
      const curveProjectingPoint = ref(null)
      
      const brokenProjectingFlag = ref(false)
      const brokenProjectingPoint = ref(null)

      //sinusoid line
      const nowDrawingSinusoidLine = ref(false)
      const newSinusoidLinePathId = ref(null)
      const newSinusoidLineD = ref('')
      const startSinusoidLineDrawingCoords = ref({ x: 0, y: 0 })

      const drawingLinesListeners = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')

        schemaContainer.addEventListener('mousedown', function (e) {
          if (nowDrawingLine.value) {
            newLinePathId.value = new Date().getTime()

            const newGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            newGroup.setAttribute('id', newLinePathId.value + '_group');

            const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            const newLineEl = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            newLineEl.setAttribute('x1', e.offsetX);
            newLineEl.setAttribute('y1', e.offsetY);
            newLineEl.setAttribute('x2', e.offsetX);
            newLineEl.setAttribute('y2', e.offsetY);

            newLineEl.setAttribute('stroke', 'none');
            newLineEl.setAttribute('stroke-width', '2');
            newLineEl.setAttribute('id', newLinePathId.value + '_start-line');

            newGroup.appendChild(newLineEl)
            
            newLine.setAttribute('d', `M${e.offsetX} ${e.offsetY} `);
            newLine.setAttribute('fill', 'none');
            newLine.setAttribute('style', 'cursor: pointer;');

            newLine.setAttribute('stroke', 'black');
            newLine.setAttribute('stroke-width', '2');
            newLine.setAttribute('id', newLinePathId.value);
            
            newGroup.appendChild(newLine)
            schemaContainer.appendChild(newGroup)
          }
          else if (nowDrawingCurveLine.value) {
            newCurveLinePathId.value = new Date().getTime()

            const newGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            newGroup.setAttribute('id', newCurveLinePathId.value + '_group');

            const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            newLine.setAttribute('d', `M${e.offsetX} ${e.offsetY} `);
            newLine.setAttribute('fill', 'none');

            newLine.setAttribute('stroke', 'black');
            newLine.setAttribute('stroke-width', '2');
            newLine.setAttribute('style', 'cursor: pointer;');
            newLine.setAttribute('id', newCurveLinePathId.value);

            const startCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            startCircle.setAttribute('cx', e.offsetX);
            startCircle.setAttribute('cy', e.offsetY);
            startCircle.setAttribute('r', '5');
            startCircle.setAttribute('fill', 'black');
            startCircle.setAttribute('id', newCurveLinePathId.value + '_circle-curve-line_' + 0);
            startCircle.setAttribute('class', 'curve-move-point_' + newCurveLinePathId.value);
            startCircle.setAttribute('style', 'display: none;');

            newGroup.appendChild(newLine)
            newGroup.appendChild(startCircle)

            schemaContainer.appendChild(newGroup)

            startCurveLineDrawingCoords.value = { x: e.offsetX, y: e.offsetY }
          }
          else if (nowDrawingBrokenLine.value) {
            newBrokenLinePathId.value = new Date().getTime()

            const newGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            newGroup.setAttribute('id', newBrokenLinePathId.value + '_group');

            const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            newLine.setAttribute('d', `M${e.offsetX} ${e.offsetY} `);
            newLine.setAttribute('fill', 'none');

            newLine.setAttribute('stroke', 'black');
            newLine.setAttribute('stroke-width', '2');
            newLine.setAttribute('style', 'cursor: pointer;');
            newLine.setAttribute('id', newBrokenLinePathId.value);

            const startCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            startCircle.setAttribute('cx', e.offsetX);
            startCircle.setAttribute('cy', e.offsetY);
            startCircle.setAttribute('r', '5');
            startCircle.setAttribute('fill', 'black');
            startCircle.setAttribute('id', newBrokenLinePathId.value + '_circle-broken-line_' + 0);
            startCircle.setAttribute('class', 'broken-move-point_' + newBrokenLinePathId.value);
            startCircle.setAttribute('style', 'display: none;');

            newGroup.appendChild(newLine)
            newGroup.appendChild(startCircle)

            schemaContainer.appendChild(newGroup)

            startBrokenLineDrawingCoords.value = { x: e.offsetX, y: e.offsetY }
          }
          else if (nowDrawingSinusoidLine.value) {
            newSinusoidLinePathId.value = new Date().getTime()

            const newGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            newGroup.setAttribute('id', newSinusoidLinePathId.value + '_group');

            const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            newLine.setAttribute('d', `M${e.offsetX} ${e.offsetY} `);
            newLine.setAttribute('fill', 'none');

            newLine.setAttribute('stroke', 'black');
            newLine.setAttribute('stroke-width', '2');
            newLine.setAttribute('style', 'cursor: pointer;');
            newLine.setAttribute('id', newSinusoidLinePathId.value);

            newGroup.appendChild(newLine)
            schemaContainer.appendChild(newGroup)

            startSinusoidLineDrawingCoords.value = { x: e.offsetX, y: e.offsetY }
          }
        })

        schemaContainer.addEventListener('mousemove', function (e) {
          if (nowDrawingLine.value && newLinePathId.value) {
            const formattedLineD = schemaContainer.getElementById(newLinePathId.value).getAttribute('d').replace(newLineD.value, '');
            const newStartLine = schemaContainer.getElementById(newLinePathId.value + '_start-line')

            newStartLine.setAttribute('x2', e.offsetX);
            newStartLine.setAttribute('y2', e.offsetY);


            newLineD.value = `L${e.offsetX} ${e.offsetY} `;

            schemaContainer.getElementById(newLinePathId.value).setAttribute('d', formattedLineD + newLineD.value);
          } 
          else if (nowDrawingCurveLine.value && newCurveLinePathId.value) {
            const formattedLineD = schemaContainer.getElementById(newCurveLinePathId.value).getAttribute('d').replace(newCurveLineD.value, '');

            const mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            const mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;

            newCurveLineD.value = `L${mouseX} ${mouseY} `;

            schemaContainer.getElementById(newCurveLinePathId.value).setAttribute('d', formattedLineD + newCurveLineD.value);
          }
          else if (nowDrawingBrokenLine.value && newBrokenLinePathId.value) {
            const formattedLineD = schemaContainer.getElementById(newBrokenLinePathId.value).getAttribute('d').replace(newBrokenLineD.value, '');

            newBrokenLineD.value = `L${e.offsetX} ${e.offsetY} `;

            schemaContainer.getElementById(newBrokenLinePathId.value).setAttribute('d', formattedLineD + newBrokenLineD.value);
          }
          else if (nowDrawingSinusoidLine.value && newSinusoidLinePathId.value) {
            const currentLine = schemaContainer.getElementById(newSinusoidLinePathId.value)

            const currentUserCursor = {
              x: e.offsetX,
              y: e.offsetY
            }

            const sinusoidalDots = []
            const rotateAngle = Math.atan((currentUserCursor.y - startSinusoidLineDrawingCoords.value.y) / (currentUserCursor.x - startSinusoidLineDrawingCoords.value.x)) * (180 / Math.PI)
            const rotateAngle360 = currentUserCursor.x < startSinusoidLineDrawingCoords.value.x ? rotateAngle + 180 : rotateAngle
            const pointFromStartToCursor = Math.sqrt(Math.pow(currentUserCursor.x - startSinusoidLineDrawingCoords.value.x, 2) + Math.pow(currentUserCursor.y - startSinusoidLineDrawingCoords.value.y, 2))

            for (let i = 0; i < pointFromStartToCursor; i++) {
              const x = startSinusoidLineDrawingCoords.value.x + i
              const y = Math.sin(x / 3) * 5 + startSinusoidLineDrawingCoords.value.y
              sinusoidalDots.push({ x: x, y: y })
            }

            currentLine.setAttribute('transform', `rotate(${rotateAngle360})`)
            currentLine.setAttribute('transform-origin', `${startSinusoidLineDrawingCoords.value.x}px ${startSinusoidLineDrawingCoords.value.y}px`)

            let d = `M${sinusoidalDots[0].x} ${sinusoidalDots[0].y} `
            for (let i = 1; i < sinusoidalDots.length; i++) {
              d += `L${sinusoidalDots[i].x} ${sinusoidalDots[i].y} `
            }

            currentLine.setAttribute('d', d)
          }

          if (curveProjectingFlag.value) {
            // if (!elementMoveFlag.value) {
              const pathId = curveProjectingPoint.value.id.split('_')[0]
              let circleId = curveProjectingPoint.value.id.split('_')[2]
              
              const mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
              const mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
              curveProjectingPoint.value.setAttribute('cx', mouseX)
              curveProjectingPoint.value.setAttribute('cy', mouseY)
            // }
          }
          if (brokenProjectingFlag.value) {
            const pathId = brokenProjectingPoint.value.id.split('_')[0]
            let circleId = brokenProjectingPoint.value.id.split('_')[2]
            
            const mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            const mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            brokenProjectingPoint.value.setAttribute('cx', mouseX)
            brokenProjectingPoint.value.setAttribute('cy', mouseY)
          }
        })

        schemaContainer.addEventListener('mouseup', function (e) {
          if (nowDrawingLine.value && newLinePathId.value) {
            selectionOfLine(e)
            newLinePathId.value = null
          } 
          else if (nowDrawingCurveLine.value && newCurveLinePathId.value) {
            const currentGroup = schemaContainer.getElementById(newCurveLinePathId.value + '_group')

            let startLinePointCoords = []

            startLinePointCoords.push({ x: startCurveLineDrawingCoords.value.x, y: startCurveLineDrawingCoords.value.y })

            for (let i = 1; i < 5; i++) {
              const newCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
              newCircle.setAttribute('cx', startCurveLineDrawingCoords.value.x + (e.offsetX - startCurveLineDrawingCoords.value.x) / 4 * i);
              newCircle.setAttribute('cy', startCurveLineDrawingCoords.value.y + (e.offsetY - startCurveLineDrawingCoords.value.y) / 4 * i);
              newCircle.setAttribute('r', '5');
              newCircle.setAttribute('fill', 'black');
              newCircle.setAttribute('id', newCurveLinePathId.value + '_circle-curve-line_' + i);
              newCircle.setAttribute('class', 'curve-move-point_' + newCurveLinePathId.value);
              newCircle.setAttribute('style', 'display: none;');

              startLinePointCoords.push({ x: startCurveLineDrawingCoords.value.x + (e.offsetX - startCurveLineDrawingCoords.value.x) / 4 * i, y: startCurveLineDrawingCoords.value.y + (e.offsetY - startCurveLineDrawingCoords.value.y) / 4 * i })

              newCircle.addEventListener('mousedown', function (e) {
                curveProjectingFlag.value = true
                curveProjectingPoint.value = e.target
              })

              document.getElementById(newCurveLinePathId.value + '_circle-curve-line_' + 0).addEventListener('mousedown', function (e) {
                curveProjectingFlag.value = true
                curveProjectingPoint.value = e.target
              })

              currentGroup.appendChild(newCircle)
            }

            const firstStartLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            firstStartLine.setAttribute('x1', startLinePointCoords[0].x);
            firstStartLine.setAttribute('y1', startLinePointCoords[0].y);
            firstStartLine.setAttribute('x2', startLinePointCoords[1].x);
            firstStartLine.setAttribute('y2', startLinePointCoords[1].y);

            firstStartLine.setAttribute('stroke', 'none');
            firstStartLine.setAttribute('stroke-width', '2');
            firstStartLine.setAttribute('id', newCurveLinePathId.value + '_start-line_0');

            currentGroup.appendChild(firstStartLine)

            const secondStartLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            secondStartLine.setAttribute('x1', startLinePointCoords[3].x);
            secondStartLine.setAttribute('y1', startLinePointCoords[3].y);
            secondStartLine.setAttribute('x2', startLinePointCoords[4].x);
            secondStartLine.setAttribute('y2', startLinePointCoords[4].y);

            secondStartLine.setAttribute('stroke', 'none');
            secondStartLine.setAttribute('stroke-width', '2');
            secondStartLine.setAttribute('id', newCurveLinePathId.value + '_start-line_1');

            currentGroup.appendChild(secondStartLine)

            console.log('startLinePointCoords -------- ', startLinePointCoords)

            selectionOfCurveLine(e)

            newCurveLinePathId.value = null
            nowDrawingCurveLine.value = false
          }
          else if (nowDrawingBrokenLine.value && newBrokenLinePathId.value) {
            const currentGroup = schemaContainer.getElementById(newBrokenLinePathId.value + '_group')

            let startLinePointCoords = []
            startLinePointCoords.push({ x: startBrokenLineDrawingCoords.value.x, y: startBrokenLineDrawingCoords.value.y })


            for (let i = 1; i < 5; i++) {
              const newCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
              newCircle.setAttribute('cx', startBrokenLineDrawingCoords.value.x + (e.offsetX - startBrokenLineDrawingCoords.value.x) / 4 * i);
              newCircle.setAttribute('cy', startBrokenLineDrawingCoords.value.y + (e.offsetY - startBrokenLineDrawingCoords.value.y) / 4 * i);
              newCircle.setAttribute('r', '5');
              newCircle.setAttribute('fill', 'black');
              newCircle.setAttribute('id', newBrokenLinePathId.value + '_circle-broken-line_' + i);
              newCircle.setAttribute('class', 'broken-move-point_' + newBrokenLinePathId.value);
              newCircle.setAttribute('style', 'display: none;');

              startLinePointCoords.push({ x: startBrokenLineDrawingCoords.value.x + (e.offsetX - startBrokenLineDrawingCoords.value.x) / 4 * i, y: startBrokenLineDrawingCoords.value.y + (e.offsetY - startBrokenLineDrawingCoords.value.y) / 4 * i })

              newCircle.addEventListener('mousedown', function (e) {
                brokenProjectingFlag.value = true
                brokenProjectingPoint.value = e.target
              })

              document.getElementById(newBrokenLinePathId.value + '_circle-broken-line_' + 0).addEventListener('mousedown', function (e) {
                brokenProjectingFlag.value = true
                brokenProjectingPoint.value = e.target
              })

              currentGroup.appendChild(newCircle)
            }

            const firstStartLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            firstStartLine.setAttribute('x1', startLinePointCoords[0].x);
            firstStartLine.setAttribute('y1', startLinePointCoords[0].y);
            firstStartLine.setAttribute('x2', startLinePointCoords[1].x);
            firstStartLine.setAttribute('y2', startLinePointCoords[1].y);

            firstStartLine.setAttribute('stroke', 'none');
            firstStartLine.setAttribute('stroke-width', '2');
            firstStartLine.setAttribute('id', newBrokenLinePathId.value + '_start-line_0');

            currentGroup.appendChild(firstStartLine)

            const secondStartLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            secondStartLine.setAttribute('x1', startLinePointCoords[3].x);
            secondStartLine.setAttribute('y1', startLinePointCoords[3].y);
            secondStartLine.setAttribute('x2', startLinePointCoords[4].x);
            secondStartLine.setAttribute('y2', startLinePointCoords[4].y);

            secondStartLine.setAttribute('stroke', 'none');
            secondStartLine.setAttribute('stroke-width', '2');
            secondStartLine.setAttribute('id', newBrokenLinePathId.value + '_start-line_1');

            currentGroup.appendChild(secondStartLine)

            console.log('startLinePointCoords -------- ', startLinePointCoords)

            selectionOfBrokenLine(e)

            newBrokenLinePathId.value = null
            nowDrawingBrokenLine.value = false
          }
          else if (nowDrawingSinusoidLine.value && newSinusoidLinePathId.value) {
            selectionOfSinusoidLine(e)
            newSinusoidLinePathId.value = null
          }

          if (curveProjectingFlag.value) {
            curveProjectingFlag.value = false
            const pathId = curveProjectingPoint.value.id.split('_')[0]
            
            const currentLine = schemaContainer.getElementById(pathId)
            const currentGroup = schemaContainer.getElementById(pathId + '_group')
            currentLine.setAttribute('d', '')

            const allCircles = schemaContainer.querySelectorAll('.curve-move-point_' + pathId)
            console.log('allCircles -------- ', allCircles)

            let d = `M${allCircles[0].getAttribute('cx')} ${allCircles[0].getAttribute('cy')} `
            for (let i = 1; i < allCircles.length - 1; i+= 2) {
              d += `Q${+allCircles[i].getAttribute('cx')},${+allCircles[i].getAttribute('cy')} ${+allCircles[i + 1].getAttribute('cx')},${+allCircles[i + 1].getAttribute('cy')} `
            }

            const firstStartLine = schemaContainer.getElementById(pathId + '_start-line_0')
            const secondStartLine = schemaContainer.getElementById(pathId + '_start-line_1')

            firstStartLine.setAttribute('x1', allCircles[0].getAttribute('cx'));
            firstStartLine.setAttribute('y1', allCircles[0].getAttribute('cy'));
            firstStartLine.setAttribute('x2', allCircles[1].getAttribute('cx'));
            firstStartLine.setAttribute('y2', allCircles[1].getAttribute('cy'));

            secondStartLine.setAttribute('x1', allCircles[3].getAttribute('cx'));
            secondStartLine.setAttribute('y1', allCircles[3].getAttribute('cy'));
            secondStartLine.setAttribute('x2', allCircles[4].getAttribute('cx'));
            secondStartLine.setAttribute('y2', allCircles[4].getAttribute('cy'));

            currentLine.setAttribute('d', d)
          }
          if (brokenProjectingFlag.value) {
            brokenProjectingFlag.value = false
            const pathId = brokenProjectingPoint.value.id.split('_')[0]
            
            const currentLine = schemaContainer.getElementById(pathId)
            const currentGroup = schemaContainer.getElementById(pathId + '_group')
            currentLine.setAttribute('d', '')

            const allCircles = schemaContainer.querySelectorAll('.broken-move-point_' + pathId)

            let d = `M${allCircles[0].getAttribute('cx')} ${allCircles[0].getAttribute('cy')} `
            for (let i = 1; i < allCircles.length - 1; i+= 2) {
              d += `L${+allCircles[i].getAttribute('cx')},${+allCircles[i].getAttribute('cy')} ${+allCircles[i + 1].getAttribute('cx')},${+allCircles[i + 1].getAttribute('cy')} `
            }

            const firstStartLine = schemaContainer.getElementById(pathId + '_start-line_0')
            const secondStartLine = schemaContainer.getElementById(pathId + '_start-line_1')

            firstStartLine.setAttribute('x1', allCircles[0].getAttribute('cx'));
            firstStartLine.setAttribute('y1', allCircles[0].getAttribute('cy'));
            firstStartLine.setAttribute('x2', allCircles[1].getAttribute('cx'));
            firstStartLine.setAttribute('y2', allCircles[1].getAttribute('cy'));

            secondStartLine.setAttribute('x1', allCircles[3].getAttribute('cx'));
            secondStartLine.setAttribute('y1', allCircles[3].getAttribute('cy'));
            secondStartLine.setAttribute('x2', allCircles[4].getAttribute('cx'));
            secondStartLine.setAttribute('y2', allCircles[4].getAttribute('cy'));

            currentLine.setAttribute('d', d)
          }
        })
      }

      //text field variables
      const startAddingTextFieldFlag = ref(false)
      const newTextFieldId = ref(null)
      const newTextFieldValue = ref(null)
      const newTextFieldCoords = ref({ x: 0, y: 0 })
      const startInputTextFieldFlag = ref(false)

      const addTextField = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        newTextFieldId.value = new Date().getTime()
        console.log('newTextFieldId.value -------- ', newTextFieldId.value)

        schemaContainer.addEventListener('mousedown', function (e) {
          if (startAddingTextFieldFlag.value) {
            startInputTextFieldFlag.value = true
            newTextFieldCoords.value = { x: e.offsetX, y: e.offsetY }
          }
        })
      }

      const addTextFieldToCanvas = () => {
        startInputTextFieldFlag.value = false
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const newTextField = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        newTextField.setAttribute('x', newTextFieldCoords.value.x);
        newTextField.setAttribute('y', newTextFieldCoords.value.y);
        newTextField.setAttribute('fill', 'black');
        newTextField.setAttribute('font-size', '20');
        newTextField.setAttribute('style', 'cursor: pointer;');
        newTextField.setAttribute('id', 'text-field-' + newTextFieldId.value);
        newTextField.textContent = newTextFieldValue.value;
        selectionOfText(newTextField)
        schemaContainer.appendChild(newTextField)
      }

      //sections
      const nowDrawingSquareSection = ref(false)
      const nowDrawingCircleSection = ref(false)
      const nowDrawingTriangleSection = ref(false)

      const newSectionId = ref(null)

      const squareSectionStartCoords = ref({ x: 0, y: 0 })
      const circleSectionStartCoords = ref({ x: 0, y: 0 })
      const triangleSectionStartCoords = ref({ x: 0, y: 0 })

      const squareSectionProcess = ref(false)
      const circleSectionProcess = ref(false)
      const triangleSectionProcess = ref(false)

      const addSections = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')

        schemaContainer.addEventListener('mousedown', function (e) {
          if (nowDrawingSquareSection.value) {
            newSectionId.value = new Date().getTime()
            squareSectionStartCoords.value = { x: e.offsetX, y: e.offsetY }
            squareSectionProcess.value = true
          }
          else if (nowDrawingCircleSection.value) {
            newSectionId.value = new Date().getTime()
            circleSectionStartCoords.value = { x: e.offsetX, y: e.offsetY }
            circleSectionProcess.value = true
          }
          else if (nowDrawingTriangleSection.value) {
            newSectionId.value = new Date().getTime()
            triangleSectionStartCoords.value = { x: e.offsetX, y: e.offsetY }
            triangleSectionProcess.value = true
          }
        })

        schemaContainer.addEventListener('mousemove', function (e) {
          if (squareSectionProcess.value) {
            const currentSection = schemaContainer.getElementById(newSectionId.value)

            if (currentSection) {
              if (e.offsetX - squareSectionStartCoords.value.x < 0) {
                currentSection.setAttribute('x', e.offsetX)
                currentSection.setAttribute('width', Math.abs(e.offsetX - squareSectionStartCoords.value.x))
              }
              else {
                currentSection.setAttribute('x', squareSectionStartCoords.value.x)
                currentSection.setAttribute('width', Math.abs(e.offsetX - squareSectionStartCoords.value.x))
              }

              if (e.offsetY - squareSectionStartCoords.value.y < 0) {
                currentSection.setAttribute('y', e.offsetY)
                currentSection.setAttribute('height', Math.abs(e.offsetY - squareSectionStartCoords.value.y))
              }
              else {
                currentSection.setAttribute('y', squareSectionStartCoords.value.y)
                currentSection.setAttribute('height', Math.abs(e.offsetY - squareSectionStartCoords.value.y))
              }
            } else {
              const newSection = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
              newSection.setAttribute('x', squareSectionStartCoords.value.x);
              newSection.setAttribute('y', squareSectionStartCoords.value.y);
              newSection.setAttribute('width', e.offsetX - squareSectionStartCoords.value.x);
              newSection.setAttribute('height', e.offsetY - squareSectionStartCoords.value.y);
              newSection.setAttribute('style', 'cursor: pointer;');
              newSection.setAttribute('fill', 'rgba(0,0,0,0.15)');
              newSection.setAttribute('stroke', 'black');
              newSection.setAttribute('stroke-width', '2');
              newSection.setAttribute('id', newSectionId.value);

              schemaContainer.appendChild(newSection)
              selectionOfSection(newSection)
            }
          }
          else if (circleSectionProcess.value) {
            const currentSection = schemaContainer.getElementById(newSectionId.value)

            const currentSectionCoords = {
              cx: circleSectionStartCoords.value.x + (e.offsetX - circleSectionStartCoords.value.x) / 2,
              cy: circleSectionStartCoords.value.y + (e.offsetY - circleSectionStartCoords.value.y) / 2,
              rx: Math.abs(e.offsetX - circleSectionStartCoords.value.x),
              ry: Math.abs(e.offsetY - circleSectionStartCoords.value.y)  
            }

            if (currentSection) {
              currentSection.setAttribute('cx', currentSectionCoords.cx)
              currentSection.setAttribute('cy', currentSectionCoords.cy)
              currentSection.setAttribute('rx', currentSectionCoords.rx)
              currentSection.setAttribute('ry', currentSectionCoords.ry)
            } else {
              const newSection = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse');
              newSection.setAttribute('cx', currentSectionCoords.cx);
              newSection.setAttribute('cy', currentSectionCoords.cy);
              newSection.setAttribute('rx', currentSectionCoords.rx);
              newSection.setAttribute('ry', currentSectionCoords.ry);
              newSection.setAttribute('style', 'cursor: pointer;');
              newSection.setAttribute('fill', 'rgba(0,0,0,0.15)');
              newSection.setAttribute('stroke', 'black');
              newSection.setAttribute('stroke-width', '2');
              newSection.setAttribute('id', newSectionId.value);

              schemaContainer.appendChild(newSection)
              selectionOfSection(newSection)
            }

          }
          // else if (triangleSectionProcess.value) {
          //   const currentSection = schemaContainer.getElementById(newSectionId.value)
          //   const currentSectionCoords = {
          //     x1: triangleSectionStartCoords.value.x,
          //     y1: triangleSectionStartCoords.value.y,
          //     x2: e.offsetX,
          //     y2: e.offsetY,
          //     x3: triangleSectionStartCoords.value.x + (e.offsetX - triangleSectionStartCoords.value.x) / 2,
          //     y3: triangleSectionStartCoords.value.y
          //   }

          //   currentSection.setAttribute('points', `${currentSectionCoords.x1},${currentSectionCoords.y1} ${currentSectionCoords.x2},${currentSectionCoords.y2} ${currentSectionCoords.x3},${currentSectionCoords.y3}`)
        })

        schemaContainer.addEventListener('mouseup', function (e) {
          if (squareSectionProcess.value) {
            squareSectionProcess.value = false
          }
          else if (circleSectionProcess.value) {
            circleSectionProcess.value = false
          }
          else if (triangleSectionProcess.value) {
            triangleSectionProcess.value = false
          }
        })
      }

      //element selection logic
      const selectedElement = ref(null)
      const selectedElementLastColor = ref(null)
      const selectedElementFlag = ref(false)
      const selectedElementType = ref('')

      const elementMoveFlag = ref(false)

      const addSelectionOfElements = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')

        schemaContainer.addEventListener('mousemove', function (e) {
          if (elementMoveFlag.value) {
            if (selectedElementType.value == 'line') {
              if (selectedElement.value) {
                const path = selectedElement.value.getElementsByTagName('path')[0]
                
                const startX = +path.getAttribute('d').split('M')[1].split(' ')[0]
                const startY = +path.getAttribute('d').split('M')[1].split(' ')[1]

                const endX = +path.getAttribute('d').split('L')[1].split(' ')[0]
                const endY = +path.getAttribute('d').split('L')[1].split(' ')[1]

                const dX = e.offsetX - ((startX + endX) / 2) + 100
                const dY = e.offsetY - ((startY + endY) / 2) + 100

                selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
              }
            }
            else if (selectedElementType.value == 'curve-line') {
              if (selectedElement.value) {
                if (e.target.tagName === 'circle') {
                  return
                }
                const path = selectedElement.value.getElementsByTagName('path')[0]
                const allCircles = selectedElement.value.getElementsByTagName('circle')

                const dX = e.offsetX - (+allCircles[2].getAttribute('cx') - 100)
                const dY = e.offsetY - (+allCircles[2].getAttribute('cy') - 100)

                selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
              }
            }
            else if (selectedElementType.value == 'broken-line') {
              if (selectedElement.value) {
                if (e.target.tagName === 'circle') {
                  return
                }
                const path = selectedElement.value.getElementsByTagName('path')[0]
                const allCircles = selectedElement.value.getElementsByTagName('circle')

                const dX = e.offsetX - (+allCircles[2].getAttribute('cx') - 100)
                const dY = e.offsetY - (+allCircles[2].getAttribute('cy') - 100)

                selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
              }
            }
            else if (selectedElementType.value == 'sinusoid-line') {
              if (selectedElement.value) {
                const path = selectedElement.value.getElementsByTagName('path')[0]
                
                const startX = +path.getAttribute('d').split('M')[1].split(' ')[0]
                const startY = +path.getAttribute('d').split('M')[1].split(' ')[1]

                const endX = +path.getAttribute('d').split('L')[1].split(' ')[0]
                const endY = +path.getAttribute('d').split('L')[1].split(' ')[1]

                const dX = e.offsetX - ((startX + endX) / 2) + 100
                const dY = e.offsetY - ((startY + endY) / 2) + 100

                selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
              }
            }
            else if (selectedElementType.value == 'text') {
              if (selectedElement.value) {
                const dX = e.offsetX - (+selectedElement.value.getAttribute('x'))
                const dY = e.offsetY - (+selectedElement.value.getAttribute('y'))

                selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
              }
            }
            else if (selectedElementType.value == 'section') {
              if (selectedElement.value) {
                const dX = e.offsetX - (+selectedElement.value.getAttribute('x'))
                const dY = e.offsetY - (+selectedElement.value.getAttribute('y'))

                selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
              }
            }

          }
        })

        schemaContainer.addEventListener('mouseup', function (e) {
          if (selectedElement.value) {
            elementMoveFlag.value = false
          }
        })  
      }

      //line selection logic
      const lineSelectedFlag = ref(false)
      const currentLineSettings = ref({
        strokeStyle: 'dotted',
        startStyle: 'none',
        endStyle: 'none',
        strokeWeight: 2
      })

      const changeLineStrokeStyle = (strokeStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentLineSettings.value.strokeStyle = strokeStyle

        if (strokeStyle == 'solid') {
          path.setAttribute('stroke-dasharray', 'none')
          currentLineSettings.value.strokeStyle = 'solid'
        }
        else if (strokeStyle == 'dotted') {
          path.setAttribute('stroke-dasharray', '5,5')
          currentLineSettings.value.strokeStyle = 'dotted'
        }
      }

      const changeLineStartStyle = (startStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        
        if (startStyle == 'none') {
          startLine.setAttribute('marker-start', 'none')
          currentLineSettings.value.startStyle = 'none'
        }
        else if (startStyle == 'arrow') {
          startLine.setAttribute('marker-start', 'url(#arrow-start)')
          currentLineSettings.value.startStyle = 'arrow'
        }
      }

      const changeLineEndStyle = (endStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        if (endStyle == 'none') {
          startLine.setAttribute('marker-end', 'none')
          currentLineSettings.value.endStyle = 'none'
        }
        else if (endStyle == 'arrow') {
          startLine.setAttribute('marker-end', 'url(#arrow-end)')
          currentLineSettings.value.endStyle = 'arrow'
        }
      }

      const changeLineStrokeWeight = (strokeWeight) => {
        if (strokeWeight < 1) {
          return
        }
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentLineSettings.value.strokeWeight = strokeWeight
        path.setAttribute('stroke-width', strokeWeight)
      }

      const deleteLine = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedElement.value)
        selectedElement.value = null
        lineSelectedFlag.value = false
      }

      const selectionOfLine = (e) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.getElementById(newLinePathId.value + '_group').addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
          }

          if (e.target.parentElement.tagName === 'g') {
            selectedElement.value = e.target.parentElement
            const path = selectedElement.value.getElementsByTagName('path')[0]
            const startLine = selectedElement.value.getElementsByTagName('line')[0]

            currentLineSettings.value.strokeStyle = path.getAttribute('stroke-dasharray') ? 'dotted' : 'solid'
            currentLineSettings.value.startStyle = startLine.getAttribute('marker-start') ? 'arrow' : 'none'
            currentLineSettings.value.endStyle = startLine.getAttribute('marker-end') ? 'arrow' : 'none'
            currentLineSettings.value.strokeWeight = path.getAttribute('stroke-width')
            console.log('currentLineSettings.value -------- ', currentLineSettings.value)
            lineSelectedFlag.value = true
  
            const centerCircleX = (+path.getAttribute('d').split('M')[1].split(' ')[0] + +path.getAttribute('d').split('L')[1].split(' ')[0]) / 2
            const centerCircleY = (+path.getAttribute('d').split('M')[1].split(' ')[1] + +path.getAttribute('d').split('L')[1].split(' ')[1]) / 2
  
            const movePoint = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  
            movePoint.innerHTML = `<path d="M8.48713 17.349C8.67986 17.5467 8.99641 17.5508 9.19418 17.3581L12.4169 14.2174C12.6147 14.0247 12.6188 13.7081 12.4261 13.5104C12.2333 13.3126 11.9168 13.3085 11.719 13.5012L8.85434 16.293L6.06263 13.4283C5.8699 13.2305 5.55334 13.2264 5.35558 13.4192C5.15782 13.6119 5.15373 13.9284 5.34646 14.1262L8.48713 17.349ZM9.4097 0.651037C9.21697 0.453272 8.90042 0.449188 8.70265 0.641916L5.47989 3.78259C5.28213 3.97531 5.27804 4.29187 5.47077 4.48963C5.6635 4.6874 5.98005 4.69148 6.17782 4.49876L9.0425 1.70705L11.8342 4.57172C12.0269 4.76949 12.3435 4.77357 12.5413 4.58084C12.739 4.38812 12.7431 4.07156 12.5504 3.8738L9.4097 0.651037ZM9.34517 17.0064L9.55157 1.00645L8.55166 0.993551L8.34526 16.9936L9.34517 17.0064Z" fill="black"/> <path d="M0.646447 8.69808C0.451184 8.89334 0.451184 9.20993 0.646447 9.40519L3.82843 12.5872C4.02369 12.7824 4.34027 12.7824 4.53553 12.5872C4.7308 12.3919 4.7308 12.0753 4.53553 11.8801L1.70711 9.05164L4.53553 6.22321C4.7308 6.02795 4.7308 5.71136 4.53553 5.5161C4.34027 5.32084 4.02369 5.32084 3.82843 5.5161L0.646447 8.69808ZM17.4568 9.40519C17.6521 9.20993 17.6521 8.89334 17.4568 8.69808L14.2748 5.5161C14.0796 5.32084 13.763 5.32084 13.5677 5.5161C13.3724 5.71136 13.3724 6.02795 13.5677 6.22321L16.3961 9.05164L13.5677 11.8801C13.3724 12.0753 13.3724 12.3919 13.5677 12.5872C13.763 12.7824 14.0796 12.7824 14.2748 12.5872L17.4568 9.40519ZM1 9.55164L17.1032 9.55164L17.1032 8.55164L1 8.55164L1 9.55164Z" fill="black"/>`
  
            movePoint.setAttribute('transform', `translate(${centerCircleX - 100}, ${centerCircleY - 100})`);
            movePoint.setAttribute('style', 'cursor: pointer;');
            movePoint.setAttribute('id', newBrokenLinePathId.value + '_move-point');
  
            selectedElement.value.appendChild(movePoint)
  
            movePoint.addEventListener('mousedown', function (e) {
              if (selectedElement.value) {
                elementMoveFlag.value = true
                selectedElementType.value == 'line'
              }
            })
  
            selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')
            selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'red')
            selectedElementType.value = 'line'
          }
        })
      }

      const stopSelectionOfLine = () => {
        selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', selectedElementLastColor.value)
        selectedElement.value.getElementsByTagName('g')[0].remove()
        lineSelectedFlag.value = false
      }
      //end of line selection logic


      //curve line selection logic
      const curveLineSelectedFlag = ref(false)
      const currentCurveLineSettings = ref({
        strokeStyle: 'dotted',
        startStyle: 'none',
        endStyle: 'none',
        strokeWeight: 2
      })

      const changeCurveLineStrokeStyle = (strokeStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentCurveLineSettings.value.strokeStyle = strokeStyle

        if (strokeStyle == 'solid') {
          path.setAttribute('stroke-dasharray', 'none')
          currentCurveLineSettings.value.strokeStyle = 'solid'
        }
        else if (strokeStyle == 'dotted') {
          path.setAttribute('stroke-dasharray', '5,5')
          currentCurveLineSettings.value.strokeStyle = 'dotted'
        }
      }

      const changeCurveLineStartStyle = (startStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        
        if (startStyle == 'none') {
          startLine.setAttribute('marker-start', 'none')
          currentCurveLineSettings.value.startStyle = 'none'
        }
        else if (startStyle == 'arrow') {
          startLine.setAttribute('marker-start', 'url(#arrow-start)')
          currentCurveLineSettings.value.startStyle = 'arrow'
        }
      }

      const changeCurveLineEndStyle = (endStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[1]

        if (endStyle == 'none') {
          startLine.setAttribute('marker-end', 'none')
          currentCurveLineSettings.value.endStyle = 'none'
        }
        else if (endStyle == 'arrow') {
          startLine.setAttribute('marker-end', 'url(#arrow-start)')
          currentCurveLineSettings.value.endStyle = 'arrow'
        }
      }

      const changeCurveLineStrokeWeight = (strokeWeight) => {
        if (strokeWeight < 1) {
          return
        }
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentCurveLineSettings.value.strokeWeight = strokeWeight
        path.setAttribute('stroke-width', strokeWeight)
      }

      const deleteCurveLine = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedElement.value)
        selectedElement.value = null
        curveLineSelectedFlag.value = false
      }

      const selectionOfCurveLine = (e) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.getElementById(newCurveLinePathId.value + '_group').addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
          }
          if (e.target.parentElement.tagName === 'g') {
            selectedElement.value = e.target.parentElement
            const allCircles = selectedElement.value.getElementsByTagName('circle')

            allCircles.forEach(circle => {
              circle.setAttribute('style', 'display: block;')
            })

            const centerCircleX = +allCircles[2].getAttribute('cx')
            const centerCircleY = +allCircles[2].getAttribute('cy')

            currentCurveLineSettings.value = {
              strokeStyle: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-dasharray') ? 'dotted' : 'solid',
              startStyle: selectedElement.value.getElementsByTagName('line')[0].getAttribute('marker-start') ? 'arrow' : 'none',
              endStyle: selectedElement.value.getElementsByTagName('line')[1].getAttribute('marker-end') ? 'arrow' : 'none',
              strokeWeight: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-width')
            }
            curveLineSelectedFlag.value = true

            if (selectedElement.value.getElementsByClassName('move-point').length == 0) {
              const movePoint = document.createElementNS('http://www.w3.org/2000/svg', 'g');

              movePoint.innerHTML = `<path d="M8.48713 17.349C8.67986 17.5467 8.99641 17.5508 9.19418 17.3581L12.4169 14.2174C12.6147 14.0247 12.6188 13.7081 12.4261 13.5104C12.2333 13.3126 11.9168 13.3085 11.719 13.5012L8.85434 16.293L6.06263 13.4283C5.8699 13.2305 5.55334 13.2264 5.35558 13.4192C5.15782 13.6119 5.15373 13.9284 5.34646 14.1262L8.48713 17.349ZM9.4097 0.651037C9.21697 0.453272 8.90042 0.449188 8.70265 0.641916L5.47989 3.78259C5.28213 3.97531 5.27804 4.29187 5.47077 4.48963C5.6635 4.6874 5.98005 4.69148 6.17782 4.49876L9.0425 1.70705L11.8342 4.57172C12.0269 4.76949 12.3435 4.77357 12.5413 4.58084C12.739 4.38812 12.7431 4.07156 12.5504 3.8738L9.4097 0.651037ZM9.34517 17.0064L9.55157 1.00645L8.55166 0.993551L8.34526 16.9936L9.34517 17.0064Z" fill="black"/> <path d="M0.646447 8.69808C0.451184 8.89334 0.451184 9.20993 0.646447 9.40519L3.82843 12.5872C4.02369 12.7824 4.34027 12.7824 4.53553 12.5872C4.7308 12.3919 4.7308 12.0753 4.53553 11.8801L1.70711 9.05164L4.53553 6.22321C4.7308 6.02795 4.7308 5.71136 4.53553 5.5161C4.34027 5.32084 4.02369 5.32084 3.82843 5.5161L0.646447 8.69808ZM17.4568 9.40519C17.6521 9.20993 17.6521 8.89334 17.4568 8.69808L14.2748 5.5161C14.0796 5.32084 13.763 5.32084 13.5677 5.5161C13.3724 5.71136 13.3724 6.02795 13.5677 6.22321L16.3961 9.05164L13.5677 11.8801C13.3724 12.0753 13.3724 12.3919 13.5677 12.5872C13.763 12.7824 14.0796 12.7824 14.2748 12.5872L17.4568 9.40519ZM1 9.55164L17.1032 9.55164L17.1032 8.55164L1 8.55164L1 9.55164Z" fill="black"/>`

              movePoint.setAttribute('transform', `translate(${centerCircleX - 100}, ${centerCircleY - 100})`);
              movePoint.setAttribute('style', 'cursor: pointer;');
              movePoint.setAttribute('class', 'move-point');

              selectedElement.value.appendChild(movePoint)

              movePoint.addEventListener('mousedown', function (e) {
                if (selectedElement.value) {
                  elementMoveFlag.value = true
                  selectedElementType.value == 'curve-line'
                }
              }) 
            } else {
              const currentMovePoint = selectedElement.value.getElementsByClassName('move-point')[0]
              currentMovePoint.setAttribute('transform', `translate(${centerCircleX - 100}, ${centerCircleY - 100})`);
              currentMovePoint.setAttribute('style', 'display: block; cursor: pointer;');
            }

            selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')
            selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'red')

            //selection of curve line
            selectedElement.value.getElementsByTagName('circle').forEach(circle => {
              circle.setAttribute('fill', 'red')
            })
            selectedElementType.value = 'curve-line'
          }
        })
      }

      const stopSelectionOfCurveLine = () => {
        curveLineSelectedFlag.value = false
        selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'black')
        selectedElement.value.getElementsByTagName('circle').forEach(circle => {
          circle.setAttribute('fill', 'black')
          circle.setAttribute('style', 'display: none;')
        })
        console.log('selectedElement.value', selectedElement.value.getElementsByTagName('g')[0])
        selectedElement.value.getElementsByTagName('g').forEach(el => el.setAttribute('style', 'display: none;'))
      }
      //end curve line selection logic

      //start broken line selection logic
      const brokenLineSelectedFlag = ref(false)
      const currentBrokenLineSettings = ref({
        strokeStyle: 'dotted',
        startStyle: 'none',
        endStyle: 'none',
        strokeWeight: 2
      })

      const selectionOfBrokenLine = (e) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.getElementById(newBrokenLinePathId.value + '_group').addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
          }
          if (e.target.parentElement.tagName === 'g') {            
            selectedElement.value = e.target.parentElement

            // show circles
            const allCircles = selectedElement.value.getElementsByTagName('circle')

            allCircles.forEach(circle => {
              circle.setAttribute('style', 'display: block;')
            })

            //show move point
            const centerCircleX = +allCircles[2].getAttribute('cx')
            const centerCircleY = +allCircles[2].getAttribute('cy')

            currentBrokenLineSettings.value = {
              strokeStyle: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-dasharray') ? 'dotted' : 'solid',
              startStyle: selectedElement.value.getElementsByTagName('line')[0].getAttribute('marker-start') ? 'arrow' : 'none',
              endStyle: selectedElement.value.getElementsByTagName('line')[1].getAttribute('marker-end') ? 'arrow' : 'none',
              strokeWeight: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-width')
            }
            brokenLineSelectedFlag.value = true

            if (selectedElement.value.getElementsByClassName('move-point').length == 0) {
              const movePoint = document.createElementNS('http://www.w3.org/2000/svg', 'g');

              movePoint.innerHTML = `<path d="M8.48713 17.349C8.67986 17.5467 8.99641 17.5508 9.19418 17.3581L12.4169 14.2174C12.6147 14.0247 12.6188 13.7081 12.4261 13.5104C12.2333 13.3126 11.9168 13.3085 11.719 13.5012L8.85434 16.293L6.06263 13.4283C5.8699 13.2305 5.55334 13.2264 5.35558 13.4192C5.15782 13.6119 5.15373 13.9284 5.34646 14.1262L8.48713 17.349ZM9.4097 0.651037C9.21697 0.453272 8.90042 0.449188 8.70265 0.641916L5.47989 3.78259C5.28213 3.97531 5.27804 4.29187 5.47077 4.48963C5.6635 4.6874 5.98005 4.69148 6.17782 4.49876L9.0425 1.70705L11.8342 4.57172C12.0269 4.76949 12.3435 4.77357 12.5413 4.58084C12.739 4.38812 12.7431 4.07156 12.5504 3.8738L9.4097 0.651037ZM9.34517 17.0064L9.55157 1.00645L8.55166 0.993551L8.34526 16.9936L9.34517 17.0064Z" fill="black"/> <path d="M0.646447 8.69808C0.451184 8.89334 0.451184 9.20993 0.646447 9.40519L3.82843 12.5872C4.02369 12.7824 4.34027 12.7824 4.53553 12.5872C4.7308 12.3919 4.7308 12.0753 4.53553 11.8801L1.70711 9.05164L4.53553 6.22321C4.7308 6.02795 4.7308 5.71136 4.53553 5.5161C4.34027 5.32084 4.02369 5.32084 3.82843 5.5161L0.646447 8.69808ZM17.4568 9.40519C17.6521 9.20993 17.6521 8.89334 17.4568 8.69808L14.2748 5.5161C14.0796 5.32084 13.763 5.32084 13.5677 5.5161C13.3724 5.71136 13.3724 6.02795 13.5677 6.22321L16.3961 9.05164L13.5677 11.8801C13.3724 12.0753 13.3724 12.3919 13.5677 12.5872C13.763 12.7824 14.0796 12.7824 14.2748 12.5872L17.4568 9.40519ZM1 9.55164L17.1032 9.55164L17.1032 8.55164L1 8.55164L1 9.55164Z" fill="black"/>`

              movePoint.setAttribute('transform', `translate(${centerCircleX - 100}, ${centerCircleY - 100})`);
              movePoint.setAttribute('style', 'cursor: pointer;');
              movePoint.setAttribute('class', 'move-point');

              selectedElement.value.appendChild(movePoint)

              movePoint.addEventListener('mousedown', function (e) {
                if (selectedElement.value) {
                  elementMoveFlag.value = true
                  selectedElementType.value == 'broken-line'
                }
              }) 
            } else {
              const currentMovePoint = selectedElement.value.getElementsByClassName('move-point')[0]
              currentMovePoint.setAttribute('transform', `translate(${centerCircleX - 100}, ${centerCircleY - 100})`);
              currentMovePoint.setAttribute('style', 'display: block; cursor: pointer;');
            }

            //selection of broken line
            selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')
            selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'red')
            selectedElement.value.getElementsByTagName('circle').forEach(circle => {
              circle.setAttribute('fill', 'red')
            })
            selectedElementType.value = 'broken-line'
          }
        })
      }

      const changeBrokenLineEndStyle = (endStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[1]

        if (endStyle == 'none') {
          startLine.setAttribute('marker-end', 'none')
          currentBrokenLineSettings.value.endStyle = 'none'
        }
        else if (endStyle == 'arrow') {
          startLine.setAttribute('marker-end', 'url(#arrow-start)')
          currentBrokenLineSettings.value.endStyle = 'arrow'
        }
      }

      const changeBrokenLineStrokeStyle = (strokeStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentBrokenLineSettings.value.strokeStyle = strokeStyle

        if (strokeStyle == 'solid') {
          path.setAttribute('stroke-dasharray', 'none')
          currentBrokenLineSettings.value.strokeStyle = 'solid'
        }
        else if (strokeStyle == 'dotted') {
          path.setAttribute('stroke-dasharray', '5,5')
          currentBrokenLineSettings.value.strokeStyle = 'dotted'
        }
      }

      const changeBrokenLineStartStyle = (startStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        
        if (startStyle == 'none') {
          startLine.setAttribute('marker-start', 'none')
          currentBrokenLineSettings.value.startStyle = 'none'
        }
        else if (startStyle == 'arrow') {
          startLine.setAttribute('marker-start', 'url(#arrow-start)')
          currentBrokenLineSettings.value.startStyle = 'arrow'
        }
      }

      const changeBrokenLineStrokeWeight = (strokeWeight) => {
        if (strokeWeight < 1) {
          return
        }
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentBrokenLineSettings.value.strokeWeight = strokeWeight
        path.setAttribute('stroke-width', strokeWeight)
      }

      const deleteBrokenLine = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedElement.value)
        selectedElement.value = null
        brokenLineSelectedFlag.value = false
      }

      const stopSelectionOfBrokenLine = () => {
        selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'black')
        selectedElement.value.getElementsByTagName('circle').forEach(circle => {
          circle.setAttribute('fill', 'black')
          circle.setAttribute('style', 'display: none;')
        })
        selectedElement.value.getElementsByTagName('g').forEach(el => el.setAttribute('style', 'display: none;'))
        brokenLineSelectedFlag.value = false
      }
      //end of broken line selection logic

      //start sinusoid line selection logic
      const sinusoidLineSelectedFlag = ref(false)
      const currentSinusoidLineSettings = ref({
        strokeStyle: 'solid',
        startStyle: 'none',
        endStyle: 'none',
        strokeWeight: 2
      })

      const changeSinusoidLineStrokeStyle = (strokeStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentSinusoidLineSettings.value.strokeStyle = strokeStyle

        if (strokeStyle == 'solid') {
          path.setAttribute('stroke-dasharray', 'none')
          currentSinusoidLineSettings.value.strokeStyle = 'solid'
        }
        else if (strokeStyle == 'dotted') {
          path.setAttribute('stroke-dasharray', '5,5')
          currentSinusoidLineSettings.value.strokeStyle = 'dotted'
        }
      }

      const changeSinusoidLineStartStyle = (startStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        
        if (startStyle == 'none') {
          startLine.setAttribute('marker-start', 'none')
          currentSinusoidLineSettings.value.startStyle = 'none'
        }
        else if (startStyle == 'arrow') {
          startLine.setAttribute('marker-start', 'url(#arrow-start)')
          currentSinusoidLineSettings.value.startStyle = 'arrow'
        }
      }

      const changeSinusoidLineEndStyle = (endStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[1]

        if (endStyle == 'none') {
          startLine.setAttribute('marker-end', 'none')
          currentSinusoidLineSettings.value.endStyle = 'none'
        }
        else if (endStyle == 'arrow') {
          startLine.setAttribute('marker-end', 'url(#arrow-start)')
          currentSinusoidLineSettings.value.endStyle = 'arrow'
        }
      }

      const changeSinusoidLineStrokeWeight = (strokeWeight) => {
        if (strokeWeight < 1) {
          return
        }
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentSinusoidLineSettings.value.strokeWeight = strokeWeight
        path.setAttribute('stroke-width', strokeWeight)
      }

      const deleteSinusoidLine = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedElement.value)
        selectedElement.value = null
        sinusoidLineSelectedFlag.value = false
      }

      const selectionOfSinusoidLine = (e) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.getElementById(newSinusoidLinePathId.value + '_group').addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
          }

          if (e.target.parentElement.tagName === 'g') {
            selectedElement.value = e.target.parentElement
            const path = selectedElement.value.getElementsByTagName('path')[0]
  
            const centerCircleX = (+path.getAttribute('d').split('M')[1].split(' ')[0] + +path.getAttribute('d').split('L')[1].split(' ')[0]) / 2
            const centerCircleY = (+path.getAttribute('d').split('M')[1].split(' ')[1] + +path.getAttribute('d').split('L')[1].split(' ')[1]) / 2
  
            const movePoint = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  
            movePoint.innerHTML = `<path d="M8.48713 17.349C8.67986 17.5467 8.99641 17.5508 9.19418 17.3581L12.4169 14.2174C12.6147 14.0247 12.6188 13.7081 12.4261 13.5104C12.2333 13.3126 11.9168 13.3085 11.719 13.5012L8.85434 16.293L6.06263 13.4283C5.8699 13.2305 5.55334 13.2264 5.35558 13.4192C5.15782 13.6119 5.15373 13.9284 5.34646 14.1262L8.48713 17.349ZM9.4097 0.651037C9.21697 0.453272 8.90042 0.449188 8.70265 0.641916L5.47989 3.78259C5.28213 3.97531 5.27804 4.29187 5.47077 4.48963C5.6635 4.6874 5.98005 4.69148 6.17782 4.49876L9.0425 1.70705L11.8342 4.57172C12.0269 4.76949 12.3435 4.77357 12.5413 4.58084C12.739 4.38812 12.7431 4.07156 12.5504 3.8738L9.4097 0.651037ZM9.34517 17.0064L9.55157 1.00645L8.55166 0.993551L8.34526 16.9936L9.34517 17.0064Z" fill="black"/> <path d="M0.646447 8.69808C0.451184 8.89334 0.451184 9.20993 0.646447 9.40519L3.82843 12.5872C4.02369 12.7824 4.34027 12.7824 4.53553 12.5872C4.7308 12.3919 4.7308 12.0753 4.53553 11.8801L1.70711 9.05164L4.53553 6.22321C4.7308 6.02795 4.7308 5.71136 4.53553 5.5161C4.34027 5.32084 4.02369 5.32084 3.82843 5.5161L0.646447 8.69808ZM17.4568 9.40519C17.6521 9.20993 17.6521 8.89334 17.4568 8.69808L14.2748 5.5161C14.0796 5.32084 13.763 5.32084 13.5677 5.5161C13.3724 5.71136 13.3724 6.02795 13.5677 6.22321L16.3961 9.05164L13.5677 11.8801C13.3724 12.0753 13.3724 12.3919 13.5677 12.5872C13.763 12.7824 14.0796 12.7824 14.2748 12.5872L17.4568 9.40519ZM1 9.55164L17.1032 9.55164L17.1032 8.55164L1 8.55164L1 9.55164Z" fill="black"/>`
  
            movePoint.setAttribute('transform', `translate(${centerCircleX - 100}, ${centerCircleY - 100})`);
            movePoint.setAttribute('style', 'cursor: pointer;');
            movePoint.setAttribute('id', newBrokenLinePathId.value + '_move-point');
  
            selectedElement.value.appendChild(movePoint)

            sinusoidLineSelectedFlag.value = true
  
            movePoint.addEventListener('mousedown', function (e) {
              if (selectedElement.value) {
                elementMoveFlag.value = true
                selectedElementType.value == 'sinusoid-line'
              }
            })
  
            selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')
            selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'red')
            selectedElementType.value = 'sinusoid-line'
          }
        })
      }

      const stopSelectionOfSinusoidLine = () => {
        selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', selectedElementLastColor.value)
        selectedElement.value.getElementsByTagName('g')[0].remove()
        sinusoidLineSelectedFlag.value = false
      }
      //end sinusoid line selection logic

      //start text selection logic
      const currentTextSettings = ref({
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAnchor: 'start',
        fill: 'black',
        text: ''
      })
      const textSelectedFlag = ref(false)
      const newTextColor = ref('black')

      const selectionOfText = (textField) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        textField.addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
            else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            }
            else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            }
          }
          if (e.target.tagName === 'text') {
            selectedElement.value = e.target
            selectedElementLastColor.value = selectedElement.value.getAttribute('fill')
            // selectedElement.value.setAttribute('fill', 'red')
            selectedElementType.value = 'text'

            currentTextSettings.value = {
              fontSize: +selectedElement.value.getAttribute('font-size'),
              fontFamily: selectedElement.value.getAttribute('font-family'),
              fontWeight: selectedElement.value.getAttribute('font-weight'),
              fontStyle: selectedElement.value.getAttribute('font-style'),
              textDecoration: selectedElement.value.getAttribute('text-decoration'),
              textAnchor: selectedElement.value.getAttribute('text-anchor'),
              fill: selectedElement.value.getAttribute('fill'),
              text: selectedElement.value.innerHTML
            }

            newTextColor.value = selectedElement.value.getAttribute('fill')
            textSelectedFlag.value = true

            selectedElement.value.addEventListener('mousedown', function (e) {
              if (selectedElement.value) {
                elementMoveFlag.value = true
                selectedElementType.value == 'text'
              }
            })
          }
        })
      }

      const changeTextValue = (text) => {
        selectedElement.value.innerHTML = text
        currentTextSettings.value.text = text
      } 

      const changeTextFontSize = (fontSize) => {
        if (fontSize < 1) {
          return
        }
        selectedElement.value.setAttribute('font-size', fontSize)
        currentTextSettings.value.fontSize = fontSize
      }

      const changeTextColor = (color) => {
        selectedElement.value.setAttribute('fill', color)
        newTextColor.value = color
        currentTextSettings.value.fill = color
      }

      const changeTextFontWeight = (fontWeight) => {
        if (fontWeight == '800') {
          currentTextSettings.value.fontStyle = 'bold'
          selectedElement.value.setAttribute('font-style', 'normal')
        } 
        selectedElement.value.setAttribute('font-weight', fontWeight)
        currentTextSettings.value.fontWeight = fontWeight
      }

      const changeTextTextDecoration = (textDecoration) => {
        selectedElement.value.setAttribute('text-decoration', textDecoration)
        currentTextSettings.value.textDecoration = textDecoration
      }

      const changeTextFontStyle = (fontStyle) => {
        if (fontStyle == 'normal') {
          currentTextSettings.value.fontStyle = 'italic'
          selectedElement.value.setAttribute('font-weight', 'normal')
        }
        selectedElement.value.setAttribute('font-style', fontStyle)
        currentTextSettings.value.fontStyle = fontStyle
      }

      const stopSelectionOfText = () => {
        selectedElement.value.setAttribute('fill', newTextColor.value)
        textSelectedFlag.value = false
      }
      //end text selection logic
      
      const selectionOfSection = (section) => {
        section.addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
            else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            }
            else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            }
          }
          console.log('e.target.tagName -------- ', e.target.tagName)
          selectedElement.value = e.target
          selectedElementLastColor.value = selectedElement.value.getAttribute('fill')
          selectedElement.value.setAttribute('fill', 'rgba(255, 0, 0, 0.5)')
          selectedElement.value.setAttribute('stroke', 'red')

          selectedElementType.value = 'section'

          selectedElement.value.addEventListener('mousedown', function (e) {
            if (selectedElement.value) {
              elementMoveFlag.value = true
              selectedElementType.value == 'section'
            }
          })
        })
      }

      const stopSelectionOfSection = () => {
        selectedElement.value.setAttribute('fill', 'rgba(0, 0, 0, 0.15)')
        selectedElement.value.setAttribute('stroke', 'black')
      }

      //other shit
      const startsLineDrawingFlag = ref(false)
      const processLineDrawingFlag = ref(false)

      const screenWidth = ref(window.innerWidth)

      const router = useRouter()
      const route = useRoute()

      const name = ref('')
      const selectShowFlag = ref(false)
      const savedSchemaFlag = ref(false)
      const openSchema = ref({})
      const updateSchemeFlag = ref(false)
      const saveSchemeFlag = ref(false)

      const addedToElementSchema = ref(false)

      const elementId = ref(route.params.id)
      const elementIdRouteParam = ref(route.query.element_id)

      const nameErrorFlag = ref(false)

      const countPlayers = ref({
        firstPlayer: 1,
        secondPlayer: 1
      })

      const selectedComponent = ref(null)
      const selectedComponentForChange = ref(null)
      const selectedComponentSettings = ref({
        rotate: 0,
        scale: 1,
        color: '#FFF'
      })
      const startX = ref(0)
      const startY = ref(0)
      const selectedComponentSettingsFlag = ref(false)

      const selectedElementCenter = ref({
        x: 0,
        y: 0
      })

      onMounted(async () => {
        window.addEventListener('resize', () => {
          screenWidth.value = window.innerWidth
        })
        elementId.value = route.params.element_id
        console.log('elementId.value -------- ', elementId.value)
        elementIdRouteParam.value = route.query.element_id
        console.log('elementIdRouteParam.value -------- ', elementIdRouteParam.value)

        if (elementIdRouteParam.value) {
          addedToElementSchema.value = true
        }

        if (route.query.schema_id) {
          const schema = await getSchemaById(route.query.schema_id)
          name.value = schema.name
          console.log('schema -------- ', schema)
          const res = await getSvgCodeOfSchema(schema.filename)
          console.log('res -------- ', res)
          document.getElementById('schema-container').innerHTML = res
          savedSchemaFlag.value = true
          openSchema.value = schema
          //set for all <g> element event listener
          const schemaContainer = document.getElementById('schema-container').querySelector('svg')
          const allGElements = schemaContainer.querySelectorAll('g')

          allGElements.forEach((element, i) => {
            if (i == 0) return
            element.addEventListener('mousedown', function (e) {
              console.log('element -------- ', element)
              selectedComponent.value = element
            })
            element.addEventListener('touchstart', function (e) {
              selectedComponent.value = element
            })
          });

          document.addEventListener('mousemove', function (e) {
            if (selectedComponent.value) {
              const currentElement = selectedComponent.value
                
              var mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
              var mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
              
              var rectWidth = currentElement.getBoundingClientRect().width;
              var rectHeight = currentElement.getBoundingClientRect().height;
    
              currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
            }
          })
    
          document.addEventListener('mouseup', function () {
            selectedComponent.value = null;
          });

        }

        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.querySelectorAll('g')[0].addEventListener('mousedown', function () {
          if (selectedElement.value) {
            if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            } else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            } else if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            } else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            } else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            } else if (selectedElementType.value == 'section') {
              stopSelectionOfSection()
            }

            selectedElement.value = null
            selectedElementLastColor.value = null
            selectedElementType.value = ''
            selectedElementFlag.value = false
          }

          selectedComponentSettingsFlag.value = false
        });

        drawingLinesListeners()
        addTextField()
        addSections()
        addSelectionOfElements()
      })
  
      const selectNewComponent = (componentName) => {
        const component = document.getElementById(componentName).querySelector('g')
        const componentClone = component.cloneNode(true)
        componentClone.setAttribute('transform', 'translate(219.1, 219.54)')
        const elementId = new Date().getTime()
        componentClone.setAttribute('id', elementId)
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
  
        componentClone.addEventListener('mousedown', function (e) {
          selectedComponent.value = document.getElementById(elementId)
        })

        componentClone.addEventListener('click', function (e) {
          selectedComponentForChange.value = document.getElementById(elementId)
          let selectedComponentRotate = 0
          let selectedComponentScale = 1
          
          if (document.getElementById(elementId).getAttribute('transform')) {
            if (document.getElementById(elementId).getAttribute('transform').includes('rotate')) {
              selectedComponentRotate = document.getElementById(elementId).getAttribute('transform')?.split('rotate(')[1].split(')')[0].split(',')[0]
            }
            if (document.getElementById(elementId).getAttribute('transform').includes('scale')) {
              selectedComponentRotate = document.getElementById(elementId).getAttribute('transform')?.split('scale(')[1].split(')')[0]
            }
          }
          
          const selectedComponentColor = document.getElementById(elementId).getElementsByTagName('path')[0]?.getAttribute('fill')

          selectedComponentSettings.value = {
            rotate: selectedComponentRotate,
            scale: selectedComponentScale,
            color: selectedComponentColor
          }

          selectedElementCenter.value = {
            x: document.getElementById(elementId).getBoundingClientRect().width / 2,
            y: document.getElementById(elementId).getBoundingClientRect().height / 2
          }

          selectedComponentSettingsFlag.value = true
        })
  
        document.addEventListener('mousemove', function (e) {
          if (selectedComponent.value) {
            const currentElement = selectedComponent.value
  
            var mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            
            var rectWidth = currentElement.getBoundingClientRect().width;
            var rectHeight = currentElement.getBoundingClientRect().height;

            let currentScale = null
            if (currentElement.getAttribute('transform').includes('scale')) {
              currentScale = currentElement.getAttribute('transform').split('scale(')[1].split(')')[0]
            }

            let currentRotate = null
            if (currentElement.getAttribute('transform').includes('rotate')) {
              currentRotate = currentElement.getAttribute('transform').split('rotate(')[1].split(')')[0]
            }

            if (currentScale && currentRotate) {
              currentElement.setAttribute('transform', `translate(${mouseX - (rectWidth / 2)}, ${mouseY - (rectHeight / 2)}) scale(${currentScale}) rotate(${currentRotate})`)
            } else if (currentScale) {
              currentElement.setAttribute('transform', `translate(${mouseX - (rectWidth / 2)}, ${mouseY - (rectHeight / 2)}) scale(${currentScale})`)
            } else if (currentRotate) {
              currentElement.setAttribute('transform', `translate(${mouseX - (rectWidth / 2)}, ${mouseY - (rectHeight / 2)}) rotate(${currentRotate})`)
            } else {
              currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
            }
          }
        })
  
        document.addEventListener('mouseup', function () {
          selectedComponent.value = null;
        });
  
        componentClone.addEventListener('touchstart', function (e) {
          selectedComponent.value = document.getElementById(elementId)
        })
  
        componentClone.addEventListener('touchmove', function (e) {
          if (selectedComponent.value) {
            const currentElement = selectedComponent.value
            
            var mouseX = e.touches[0].clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.touches[0].clientY - schemaContainer.getBoundingClientRect().top;
            
            var rectWidth = currentElement.getBoundingClientRect().width;
            var rectHeight = currentElement.getBoundingClientRect().height;
  
            currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
          }
        })
  
        document.addEventListener('touchend', function () {
          selectedComponent.value = null;
        });
        schemaContainer.appendChild(componentClone)
      }

      const changeComponentColor = (color) => {
        const elementsForFill = selectedComponentForChange.value.getElementsByClassName('color_changed_fill')
        const elementsForStroke = selectedComponentForChange.value.getElementsByClassName('color_changed_stroke')

        if (elementsForFill) {
          elementsForFill.forEach(el => el.setAttribute('fill', color))
        }

        if (elementsForStroke) {
          elementsForStroke.forEach(el => el.setAttribute('stroke', color))
        }

        selectedComponentSettings.value.color = color
      }

      const changeComponentRotate = (rotate) => {
        let currentTranslate = null
        if (selectedComponentForChange.value.getAttribute('transform').includes('translate')) {
          currentTranslate = selectedComponentForChange.value.getAttribute('transform').split('translate(')[1].split(')')[0]
        }
        let currentScale = null
        if (selectedComponentForChange.value.getAttribute('transform').includes('scale')) {
          currentScale = selectedComponentForChange.value.getAttribute('transform').split('scale(')[1].split(')')[0]
        }

        const centerElementX = selectedComponentForChange.value.getBoundingClientRect().width / 2
        const centerElementY = selectedComponentForChange.value.getBoundingClientRect().height / 2
        
        if (currentTranslate && currentScale) {
          selectedComponentForChange.value.setAttribute('transform', `translate(${currentTranslate}) scale(${currentScale}) rotate(${rotate}, ${selectedElementCenter.value.x}, ${selectedElementCenter.value.y})`)
        } else if (currentTranslate) {
          selectedComponentForChange.value.setAttribute('transform', `translate(${currentTranslate}) rotate(${rotate}, ${selectedElementCenter.value.x}, ${selectedElementCenter.value.y})`)
        } else if (currentScale) {
          selectedComponentForChange.value.setAttribute('transform', `scale(${currentScale}) rotate(${rotate}, ${selectedElementCenter.value.x}, ${selectedElementCenter.value.y})`)
        } else {
          selectedComponentForChange.value.setAttribute('transform', `rotate(${rotate}, ${selectedElementCenter.value.x}, ${selectedElementCenter.value.y})`)
        }

        selectedComponentSettings.value.rotate = Math.round(parseFloat(rotate) * 100) / 100;
      }

      const changeComponentScale = (scale) => {
        let currentTranslate = null
        if (selectedComponentForChange.value.getAttribute('transform').includes('translate')) {
          currentTranslate = selectedComponentForChange.value.getAttribute('transform').split('translate(')[1].split(')')[0]
        }

        let currentRotate = null
        if (selectedComponentForChange.value.getAttribute('transform').includes('rotate')) {
          currentRotate = selectedComponentForChange.value.getAttribute('transform').split('rotate(')[1].split(')')[0]
        }

        if (currentTranslate && currentRotate) {
          selectedComponentForChange.value.setAttribute('transform', `translate(${currentTranslate}) rotate(${currentRotate}) scale(${scale})`)
        } else if (currentTranslate) {
          selectedComponentForChange.value.setAttribute('transform', `translate(${currentTranslate}) scale(${scale})`)
        } else if (currentRotate) {
          selectedComponentForChange.value.setAttribute('transform', `rotate(${currentRotate}) scale(${scale})`)
        } else {
          selectedComponentForChange.value.setAttribute('transform', `scale(${scale})`)
        }

        selectedComponentSettings.value.scale = Math.round(parseFloat(scale) * 100) / 100;
      }

      const deletePresetComponent = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedComponentForChange.value)
        selectedComponentForChange.value = null
        selectedComponentSettingsFlag.value = false
      }
  
      const startDrawing = () => {
        if (startsDrawingFlag.value) {
          startsDrawingFlag.value = false
          return
        }
        startsDrawingFlag.value = true
        startsLineDrawingFlag.value = false
  
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
  
        schemaContainer.addEventListener('mousedown', function (e) {
          if (startsDrawingFlag.value && newPathId.value == null) {
            processDrawingFlag.value = true;
            const { offsetX, offsetY } = event;
            newPathId.value = new Date().getTime()
  
            const newPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            newPath.setAttribute('d', `M${offsetX} ${offsetY} `);
            newPath.setAttribute('fill', 'none');
            newPath.setAttribute('stroke', 'black');
            newPath.setAttribute('stroke-width', '2');
            newPath.setAttribute('id', newPathId.value);
            newPath.setAttribute('style', 'cursor: pointer;');
  
            schemaContainer.appendChild(newPath)
          }
        })
  
        schemaContainer.addEventListener('mousemove', function (e) {
          if (processDrawingFlag.value && newPathId.value) {
            const { offsetX, offsetY } = event;
            schemaContainer.getElementById(newPathId.value).setAttribute('d', schemaContainer.getElementById(newPathId.value).getAttribute('d') + `L${offsetX} ${offsetY} `);
          }
        })
  
        schemaContainer.addEventListener('mouseup', function (e) {
          processDrawingFlag.value = false;
          newPathId.value = null
        })
  
        schemaContainer.addEventListener('mouseleave', function (e) {
          processDrawingFlag.value = false;
          newPathId.value = null
        })
  
        schemaContainer.addEventListener('touchstart', function (e) {
          if (startsDrawingFlag.value && newPathId.value == null) {
            processDrawingFlag.value = true;
            newPathId.value = new Date().getTime()
  
            var mouseX = e.touches[0].clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.touches[0].clientY - schemaContainer.getBoundingClientRect().top;
  
            const newPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            newPath.setAttribute('d', `M${mouseX} ${mouseY} `);
            newPath.setAttribute('fill', 'none');
            newPath.setAttribute('stroke', 'black');
            newPath.setAttribute('stroke-width', '2');
            newPath.setAttribute('id', newPathId.value);
            newPath.setAttribute('style', 'cursor: pointer;');
  
            schemaContainer.appendChild(newPath)
          }
        })
  
        schemaContainer.addEventListener('touchmove', function (e) {
          if (processDrawingFlag.value && newPathId.value) {
            var mouseX = e.touches[0].clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.touches[0].clientY - schemaContainer.getBoundingClientRect().top;
            schemaContainer.getElementById(newPathId.value).setAttribute('d', schemaContainer.getElementById(newPathId.value).getAttribute('d') + `L${mouseX} ${mouseY} `);
          }
        })
  
        schemaContainer.addEventListener('touchend', function (e) {
          processDrawingFlag.value = false;
          newPathId.value = null
        })
      }

      const deleteLastElement = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const lastElement = schemaContainer.lastChild
        if (lastElement) {
          schemaContainer.removeChild(lastElement)
        }
      }
  
      const clearAllField = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const defs = schemaContainer.querySelector('defs')
        const fieldGroup = schemaContainer.querySelector('g')
        schemaContainer.innerHTML = '<defs>' + defs.innerHTML + '</defs>' + '<g>' + fieldGroup.innerHTML + '</g>'

        schemaContainer.querySelectorAll('g')[0].addEventListener('mousedown', function () {
          if (selectedElement.value) {
            if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            } else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            } else if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            } else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            } else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            } else if (selectedElementType.value == 'section') {
              stopSelectionOfSection()
            }

            selectedElement.value = null
            selectedElementLastColor.value = null
            selectedElementType.value = ''
            selectedElementFlag.value = false
          }
        });
      }
  
      const downloadImage = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const svgData = schemaContainer.outerHTML;
        const preface = '<?xml version="1.0" standalone="no"?>\r\n';
        const svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
        const svgUrl = URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "schema1.svg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
  
      const downloadImageAsPNG = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const svgData = schemaContainer.outerHTML;
        const canvas = document.createElement("canvas");
        const svgSize = schemaContainer.getBoundingClientRect();
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;
        const ctx = canvas.getContext("2d");
        const img = document.createElement("img");
        img.setAttribute("src", "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData))));
        img.onload = function() {
          ctx.drawImage(img, 0, 0);
          const imgsrc = canvas.toDataURL("image/png");
          const a = document.createElement("a");
          a.download = "schema1.png";
          a.href = imgsrc;
          document.body.appendChild(a);
          a.click();
        };
      }

      const createEmptySchema = () => {
        savedSchemaFlag.value = false
        name.value = ''
        clearAllField()
      }

      const saveNewSchema = async () => {
        const svgCode = document.getElementById('schema-container').querySelector('svg').outerHTML
        const svgFile = new Blob([svgCode], {type:"image/svg+xml;charset=utf-8"});
        
        const res = await createNewTrainingSchemaRequest(
          {
            name: name.value,
            info: '' 
          },
          svgFile
        )

        if (res.error) {
          nameErrorFlag.value = true
        }
        else {
          saveSchemeFlag.value = true
          return res
        }
      }

      const updateSchema = async () => {
        const svgCode = document.getElementById('schema-container').querySelector('svg').outerHTML
        const svgFile = new Blob([svgCode], {type:"image/svg+xml;charset=utf-8"});
        
        const res = await updateSchemaByIdRequest(
          openSchema.value.id,
          {
            name: name.value,
            info: '' 
          },
          svgFile
        )
        updateSchemeFlag.value = true
      }

      const deleteSchema = async () => {
        const res = await deleteSchemaByIdRequest(openSchema.value.id)
        savedSchemaFlag.value = false
        name.value = ''
        clearAllField()
      }

      const selectSchema = async (schema) => {
        selectShowFlag.value = false
        name.value = schema.name

        const res = await getSvgCodeOfSchema(schema.filename)
        document.getElementById('schema-container').innerHTML = res
        savedSchemaFlag.value = true
        openSchema.value = schema
        //set for all <g> element event listener
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const allGElements = schemaContainer.querySelectorAll('g')

        allGElements.forEach((element, i) => {
          if (i == 0) return
          element.addEventListener('mousedown', function (e) {
            console.log('element -------- ', element)
            selectedComponent.value = element
          })
          element.addEventListener('touchstart', function (e) {
            selectedComponent.value = element
          })
        });

        document.addEventListener('mousemove', function (e) {
          if (selectedComponent.value) {
            const currentElement = selectedComponent.value
              
            var mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            
            var rectWidth = currentElement.getBoundingClientRect().width;
            var rectHeight = currentElement.getBoundingClientRect().height;
  
            currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
          }
        })
  
        document.addEventListener('mouseup', function () {
          selectedComponent.value = null;
        });
        
      }

      const addToElement = async () => {
        const result = await saveNewSchema()
        console.log('result -------- ', result)
        const schemaId = result.schema_id
        const elementId = route.params.element_id
        router.push({ path: '/training-element/' + elementId, query: { schema_id: schemaId } })
      }
  
      return {
        selectNewComponent,
        startDrawing,
        startsDrawingFlag,
        deleteLastElement,
        clearAllField,
        startsLineDrawingFlag,
        downloadImage,
        screenWidth,
        downloadImageAsPNG,
        name,
        saveNewSchema,
        selectShowFlag,
        selectSchema,
        savedSchemaFlag,
        updateSchema,
        deleteSchema,
        updateSchemeFlag,
        createEmptySchema,
        saveSchemeFlag,
        elementId,
        addToElement,
        elementIdRouteParam,
        addedToElementSchema,
        nameErrorFlag,
        countPlayers,
        processLineDrawingFlag,
        newPathId,
        selectedComponent,
        nowDrawingLine,
        nowDrawingCurveLine,
        nowDrawingBrokenLine,
        nowDrawingSinusoidLine,
        addTextField,
        startAddingTextFieldFlag,
        newTextFieldId,
        newTextFieldValue,
        newTextFieldCoords,
        startInputTextFieldFlag,
        addTextFieldToCanvas,
        nowDrawingSquareSection,
        nowDrawingCircleSection,
        nowDrawingTriangleSection,
        selectedElement,
        selectedElementFlag,
        lineSelectedFlag,
        currentLineSettings,
        changeLineStrokeStyle,
        changeLineStartStyle,
        changeLineEndStyle,
        changeLineStrokeWeight,
        deleteLine,
        changeCurveLineStrokeStyle,
        currentCurveLineSettings,
        curveLineSelectedFlag,
        changeCurveLineStartStyle,
        changeCurveLineEndStyle,
        changeCurveLineStrokeWeight,
        deleteCurveLine,
        brokenLineSelectedFlag,
        currentBrokenLineSettings,
        changeBrokenLineStrokeStyle,
        changeBrokenLineStartStyle,
        changeBrokenLineEndStyle,
        changeBrokenLineStrokeWeight,
        deleteBrokenLine,
        sinusoidLineSelectedFlag,
        currentSinusoidLineSettings,
        changeSinusoidLineStrokeStyle,
        changeSinusoidLineStartStyle,
        changeSinusoidLineEndStyle,
        changeSinusoidLineStrokeWeight,
        deleteSinusoidLine,
        currentTextSettings,
        textSelectedFlag,
        selectionOfText,
        changeTextFontSize,
        changeTextColor,
        changeTextFontWeight,
        changeTextTextDecoration,
        stopSelectionOfText,
        changeTextFontStyle,
        changeTextValue,
        changeComponentColor,
        selectedComponentSettingsFlag,
        selectedComponentSettings,
        changeComponentRotate,
        changeComponentScale,
        deletePresetComponent
      }
    },
  
    components: {
      Field,
      PlayerOne,
      PlayerTwo,
      SmallPlayer,
      Ball,
      Flag,
      Dot,
      SchemasSelect,
      LinearDot,
      BaloonPlayer,
      MetalPlayer,
      Stairs,
      Stick,
      Barrier,
      ConeBarrier,
      RightGates,
      LeftGates,
      FrontGates,
      Cone
    }
  }
  </script>