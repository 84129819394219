<template>
    <div class="flex justify-between items-center w-full  px-[58px] py-[10px]">
        <div :key="key" class="text-zinc-100 text-[20px] font-extrabold">
            {{ pageName }}
        </div>
        <div class="flex">
            <div class="text-[#BD9135] mr-[35px] text-[15px] font-medium flex justify-center items-center">
                <div>{{ store.trainer.name + ' ' + store.trainer.surname }}</div>
            </div>
            <div class="w-[46px] overflow-hidden h-[46px] flex items-center justify-center rounded-full border-[#BD9135] border-[1.5px]">
                <img v-if="store.trainer.photo?.length > 0" :src="apiURL + 'static/' + store.trainer.photo">
                <div v-else class="w-full h-full bg-[#BD9135] bg-opacity-40 text-[#BD9135] font-semibold flex justify-center items-center">
                    {{ store.trainer.name[0] + store.trainer.surname[0] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { uuid } from 'vue3-uuid'
    import { ref, onMounted, watch } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { useMainStore } from '../stores/mainStore'

    export default {
        name: 'Header',
        
        setup() {
            const store = useMainStore()
            const router = useRouter()
            const route = useRoute()
            const pageName = ref(route.meta.name)
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const key = ref(uuid.v4())

            // watch(() => route.meta.name, () => {
            //     pageName.value = route.meta.name
            //     key.value = uuid.v4()
            // })

            onMounted(() => {
                switch(route.meta.component) {
                    case 'MainView':
                        pageName.value = 'Показники'
                        break
                    case 'StudentsView':
                        pageName.value = 'Учні'
                        break
                    case 'GroupsView':
                        pageName.value = 'Групи'
                        break
                    case 'Trainings':
                        pageName.value = 'Тренування'
                        break
                    case 'TrainingPrograms':
                        pageName.value = 'Програми тренувань'
                        break
                    case 'TrainingSchemes':
                        pageName.value = 'Схеми тренувань'
                        break
                    case 'TrainingConstructor':
                        pageName.value = 'Конструктор тренувань'
                        break
                    case 'TrainingElements':
                        pageName.value = 'Елементи тренувань'
                        break
                }
            })

            return {
                store,
                pageName,
                apiURL,
                key
            }
        },

        components: {

        }
    }
</script>

<style lang="scss" scoped>

</style>