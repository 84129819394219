<template>
    <div class="absolute left-[50%] top-[50%] translate-x-[-50%] z-[9999]">
        <div v-if="deleteFileFlag" class="bg-[#252526] w-[200px] h-[150px] flex flex-col justify-between rounded-[10px] py-[15px] border-[1px] border-[#474849] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
            <div class="text-[#FFF] text-center px-[20px]">Бажаєте видалити файл?</div>
            <div class="flex justify-center mt-[15px]">
                <div @click="deleteFile" class="bg-[#DA4167] w-[50px] mr-[10px] text-center rounded-[5px] cursor-pointer">Так</div>
                <div @click="deleteFileFlag = false" class="bg-[#339989] w-[50px] text-center rounded-[5px] cursor-pointer">Ні</div>
            </div>
        </div>
        <div v-if="imageForViewFlag" class="bg-[#252526] w-[405px] p-[10px] flex flex-col justify-between rounded-[10px] border-[1px] border-[#474849] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
            <div @click="imageForViewFlag = false" class="flex justify-end mb-[10px] cursor-pointer">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.0002 4.99994L5.00021 18.9999" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.00021 4.99994L19.0002 18.9999" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="h-[90%] flex justify-center mb-[10px]">
                <!-- mockup image -->
                <img :src="imageForViewSrc" v-if="imageForViewFlag" class="h-[306px] object-cover" alt="">
            </div>
            <div @click="downloadFileObject(imageForViewFile)" class="bg-[#339989] w-[100%] mr-[10px] text-center rounded-[5px] cursor-pointer">Завантажити</div>
        </div>
        <div class="h-[350px] w-[405px] bg-[#252526] flex flex-col justify-between rounded-[10px] border-[1px] border-[#474849]">
            <div>
                <div class="flex justify-end mb-[10px] pt-[10px] pr-[10px] cursor-pointer" @click="$emit('close')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.0002 4.99994L5.00021 18.9999" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.00021 4.99994L19.0002 18.9999" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text-white text-[21px] font-semibold text-center my-[10px]">Файли</div>
        
                <div class="px-[22px]">
                    <div v-for="file in filesObjects" class="flex mb-[10px] cursor-pointer text-white justify-between hover:bg-[#474849] hover:bg-opacity-30 p-[5px] rounded-[6px]">
                        <div @click="isFileImage(file) ? selectImageForView(file) : downloadFileObject(file)" class="text-[#C59014] font-semibold truncate w-[25%]">{{ file.name }}</div>
                        <div @click="isFileImage(file) ? selectImageForView(file) : downloadFileObject(file)">{{ parseDate(file.lastModified) }}</div>
                        
                        <div class="flex w-[87px] justify-end">
                            <div v-if="isFileImage(file)" @click="selectImageForView(file)" class="mr-[10px] cursor-pointer">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.5435 11.045C21.8476 11.4713 21.9996 11.6845 21.9996 12C21.9996 12.3155 21.8476 12.5287 21.5435 12.955C20.1775 14.8706 16.6888 19 11.9996 19C7.31035 19 3.82167 14.8706 2.45561 12.955C2.15159 12.5287 1.99957 12.3155 1.99957 12C1.99957 11.6845 2.15159 11.4713 2.45561 11.045C3.82167 9.12944 7.31035 5 11.9996 5C16.6888 5 20.1775 9.12944 21.5435 11.045Z" stroke="#339989" stroke-width="1.5"/>
                                    <path d="M15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12Z" stroke="#339989" stroke-width="1.5"/>
                                </svg>
                            </div>
                            <div @click="downloadFileObject(file)" class="mr-[10px] cursor-pointer">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 11V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6L20 11M8 16L12 20M12 20L16 16M12 20L12 8" stroke="#BD9135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div @click="() => { deleteFileFlag = true; fileForDelete = file }" class="cursor-pointer">
                                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.6013 4.5L16.9706 14.5251C16.8095 17.0864 16.729 18.3671 16.0756 19.2879C15.7526 19.7431 15.3367 20.1273 14.8544 20.416C13.8789 21 12.5732 21 9.96172 21C7.3468 21 6.03934 21 5.06319 20.4149C4.58057 20.1257 4.16456 19.7408 3.84166 19.2848C3.18854 18.3626 3.10975 17.0801 2.95218 14.5152L2.33691 4.5" stroke="#DA4167" stroke-linecap="round"/>
                                    <path d="M19.1278 4.5H0.810547" stroke="#DA4167" stroke-linecap="round"/>
                                    <path d="M14.096 4.5L13.4013 3.09173C12.9398 2.15626 12.7091 1.68852 12.3111 1.39681C12.2228 1.3321 12.1293 1.27454 12.0315 1.2247C11.5908 1 11.0618 1 10.0039 1C8.91945 1 8.37721 1 7.92915 1.23412C7.82985 1.28601 7.73509 1.3459 7.64585 1.41317C7.24323 1.7167 7.01832 2.20155 6.56851 3.17126L5.95215 4.5" stroke="#DA4167" stroke-linecap="round"/>
                                    <path d="M7.42578 15.5L7.42578 9.5" stroke="#DA4167" stroke-linecap="round"/>
                                    <path d="M12.5137 15.5L12.5137 9.5" stroke="#DA4167" stroke-linecap="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div v-for="file in filesUrls" class="flex mb-[10px] cursor-pointer text-white justify-between hover:bg-[#474849] hover:bg-opacity-30 p-[5px] rounded-[6px]">
                        <div class="text-[#C59014] font-semibold truncate w-[25%]">{{ file }}</div>
                        <div></div>
                        
                        <div class="flex w-[87px] justify-end">
                            <div @click="downloadFileByURL(file)" class="mr-[10px] cursor-pointer">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 11V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6L20 11M8 16L12 20M12 20L16 16M12 20L12 8" stroke="#BD9135" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div v-if="canDelete" @click="() => { deleteFileFlag = true; fileForDelete = file }" class="cursor-pointer">
                                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.6013 4.5L16.9706 14.5251C16.8095 17.0864 16.729 18.3671 16.0756 19.2879C15.7526 19.7431 15.3367 20.1273 14.8544 20.416C13.8789 21 12.5732 21 9.96172 21C7.3468 21 6.03934 21 5.06319 20.4149C4.58057 20.1257 4.16456 19.7408 3.84166 19.2848C3.18854 18.3626 3.10975 17.0801 2.95218 14.5152L2.33691 4.5" stroke="#DA4167" stroke-linecap="round"/>
                                    <path d="M19.1278 4.5H0.810547" stroke="#DA4167" stroke-linecap="round"/>
                                    <path d="M14.096 4.5L13.4013 3.09173C12.9398 2.15626 12.7091 1.68852 12.3111 1.39681C12.2228 1.3321 12.1293 1.27454 12.0315 1.2247C11.5908 1 11.0618 1 10.0039 1C8.91945 1 8.37721 1 7.92915 1.23412C7.82985 1.28601 7.73509 1.3459 7.64585 1.41317C7.24323 1.7167 7.01832 2.20155 6.56851 3.17126L5.95215 4.5" stroke="#DA4167" stroke-linecap="round"/>
                                    <path d="M7.42578 15.5L7.42578 9.5" stroke="#DA4167" stroke-linecap="round"/>
                                    <path d="M12.5137 15.5L12.5137 9.5" stroke="#DA4167" stroke-linecap="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div @click="uploadFile" class="bg-[#339989] w-[90%] mx-auto text-center rounded-[5px] cursor-pointer mb-[20px]">Додати файл</div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { getBlobFileByUrl } from '@/desktopApp/services/apiRequests';

export default {
    name: 'file-manager',    

    props: {
        filesObjects: {
            type: Array,
            default: () => []
        },

        filesUrls: {
            type: Array,
            default: () => []
        },

        marker: {
            type: Object,
            default: () => {}
        },

        canDelete: {
            type: Boolean,
            default: true
        }
    },

    setup(props, { emit }) {
        const isModalOpen = ref(false);

        const deleteFileFlag = ref(false);
        const fileForDelete = ref(null);

        const imageForViewSrc = ref(null);
        const imageForViewFile = ref(null);
        const imageForViewFlag = ref(false);

        const apiURL = process.env.VUE_APP_API_URL;

        const selectImageForView = (file) => {
            const image = URL.createObjectURL(file);
            imageForViewSrc.value = image;
            imageForViewFlag.value = true;
            imageForViewFile.value = file;
        }

        const parseDate = (date) => {
            const newDate = new Date(date);
            const day = newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`;
            const month = newDate.getMonth() > 9 ? newDate.getMonth() : `0${newDate.getMonth()}`;

            return `${day}.${month}.${newDate.getFullYear()}`;
        }

        const isFileImage = (file) => {
            return file.type.includes('image');
        }

        const deleteFile = () => {
            emit('delete', {
                file: fileForDelete.value,
                marker: props.marker
            })
            deleteFileFlag.value = false;
        }

        const uploadFile = () => {
            emit('upload', {
                marker: props.marker
            })
        }

        const downloadFileObject = (file) => {
            const fileUrl = URL.createObjectURL(file);
            const link = document.createElement('a');

            link.href = fileUrl;
            link.download = file.name;

            document.body.appendChild(link);

            link.click();
        }

        const downloadFileByURL = async (file) => {
            const blob = await getBlobFileByUrl(apiURL + 'static/' + file);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = file.split('/').pop();

            document.body.appendChild(link);

            link.click();
        }

        return {
            isModalOpen,
            downloadFileObject,
            isFileImage,
            parseDate,
            deleteFile,
            deleteFileFlag,
            fileForDelete,
            selectImageForView,
            imageForViewSrc,
            imageForViewFlag,
            imageForViewFile,
            uploadFile,
            downloadFileByURL
        }
    }
}
</script>

<style>
    
</style>