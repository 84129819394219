<template>
    <div>
        <div>
            <div @click="$emit('close')" class="flex text-[#BD9135] items-center ml-3 mt-2">
                <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                <div class="ml-2">
                    Назад
                </div>
            </div>
        </div>

        <div class="pb-[12px] border-b-[1px] border-[#BD9135] mt-[10px]">
            <div class="text-[#BD9135] font-bold text-center">{{ selectedSummary.name }}</div>
        </div>

        <div class="rounded-lg px-[10px] w-full h-[20px] flex overflow-hidden mt-[20px]">
            <div 
                v-for="el in elements" 
                class="h-full px-[5px] text-center text-xs truncate" 
                :style="{width: el.width + '%'}"
                :class="{ 'text-white': currentElementIndex === elements.indexOf(el), 'text-gray-400 opacity-30': currentElementIndex !== elements.indexOf(el) }"    
            >{{ el.name }}</div>
        </div>

        <div class="rounded-lg w-full h-[20px] relative flex overflow-hidden mt-[20px]">
            <div class="w-[0] h-[120%] absolute bg-[#DA4167]" :style="{width: trainingProccessProcent + '%'}"></div>
            <div v-for="el in elements" class="bg-[#BD9135] h-full border-r-[1px] border-[#000]" :style="{width: el.width + '%'}"></div>
        </div>

        <div class="rounded-lg px-[10px] w-full h-[20px] items-center flex overflow-hidden mt-[20px]">
            <div class="h-[1px] w-[33%] bg-gray-400"></div>
            <div class="h-full w-[33%] mt-[10px] text-gray-400 text-center text-xs truncate">{{ parsedDuration }}</div>
            <div class="h-[1px] w-[33%] bg-gray-400"></div>
        </div>

        <div class="px-[10px]">
            <div class="mt-[20px] px-[10px]">
                <div class="mx-auto border-[#000] border-opacity-10 bg-[#47484966] py-[10px] mt-[15px] rounded-[10px]">
                    <div class="font-bold text-white uppercase text-center">{{ elements[currentElementIndex].name }}</div>
                    <button v-if="!showElementInfoFull" @click="showElementInfoFull = true" class="bg-[#BD9135] mt-3 mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                        <div class="w-full text-[#252526] font-semibold">Розгорнути</div>

                        <svg width="8" height="10" transform="rotate(90)" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#252526"/>
                        </svg>
                    </button>

                    <div v-if="showElementInfoFull" class="text-white mt-3 px-[15px] mb-[10px]">
                    
                        <div>
                            {{ elements[currentElementIndex].info }}
                        </div>
                    
                        <div class="w-[100%] mt-[20px]">
                            <div v-if="elements[currentElementIndex].additional_images.length > 0">
                                <div class="font-bold text-[#BD9135]">Ілюстрації</div>
                                <carousel :items-to-show="1">
                                    <slide v-for="slide in elements[currentElementIndex].additional_images" :key="slide">
                                        <img class="w-full" :src="apiURL + 'static/' + slide" alt="">
                                    </slide>
                                </carousel>
                            </div>
                            <div v-if="elements[currentElementIndex].schemas.length > 0" class="mt-[35px]">
                                <div class="font-bold text-[#BD9135]">Схеми</div>
                                <carousel :items-to-show="1">
                                    <slide v-for="slide in elements[currentElementIndex].schemas" :key="slide">
                                        <img class="w-full" :src="apiURL + 'static/' + slide + '.svg'" alt="">
                                    </slide>
                                </carousel>
                            </div>
                            <div v-if="elements[currentElementIndex].additional_videos.length > 0" class="mt-[35px]">
                                <div class="font-bold text-[#BD9135]">Відео</div>
                                <div class="flex mt-[15px] justify-center">
                                    <carousel :items-to-show="1">
                                        <slide v-for="slide in elements[currentElementIndex].additional_videos" :key="slide">
                                            <div class="w-full flex justify-center">
                                                <YouTube 
                                                    :width = "'100%'" 
                                                    :src="slide" 
                                                    @ready="onReady"
                                                    ref="youtube" 
                                                />
                                            </div>
                                        </slide>
                                    </carousel>
                                </div>
                            </div>
                        </div>

                        <button v-if="showElementInfoFull" @click="showElementInfoFull = false" class="bg-[#BD9135] mt-5 mb-2 rounded-[9px] text-[#252526] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                            <div class="w-full text-[#252526] font-semibold">Згорнути</div>

                            <svg width="8" height="10" transform="rotate(-90)" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#252526"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            
            <div class="mt-[20px] px-[10px]">
                <div class="mx-auto border-[#000] border-opacity-10 bg-[#47484966] py-[10px] mt-[15px] rounded-[10px]">
    
                    <div class="mt-[10px] flex justify-evenly">
                        <div class="flex flex-col items-center justify-center">
                            <div @click="previousElement">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="24.6154" transform="rotate(-180 26 26)" fill="#1B4079" stroke="#474849" stroke-width="2.76923"/>
                                <path d="M21.2002 31.6C21.2002 33.2971 21.2002 34.1456 20.673 34.6728C20.1458 35.2 19.2973 35.2 17.6002 35.2C15.9031 35.2 15.0546 35.2 14.5274 34.6728C14.0002 34.1456 14.0002 33.2971 14.0002 31.6L14.0002 19.6C14.0002 17.903 14.0002 17.0544 14.5274 16.5272C15.0546 16 15.9031 16 17.6002 16C19.2973 16 20.1458 16 20.673 16.5272C21.2002 17.0544 21.2002 17.903 21.2002 19.6L21.2002 31.6Z" fill="white" stroke="white" stroke-width="1.5"/>
                                <path d="M21.3313 24.5172C21.7554 22.7981 23.76 21.5833 27.7691 19.1538C31.6447 16.8052 33.5826 15.6309 35.1442 16.1029C35.7898 16.2981 36.3781 16.6687 36.8525 17.1793C38 18.4142 38 20.8095 38 25.6C38 30.3905 38 32.7858 36.8525 34.0207C36.3781 34.5313 35.7898 34.9019 35.1442 35.0971C33.5826 35.5691 31.6447 34.3948 27.7691 32.0462C23.76 29.6167 21.7554 28.4019 21.3313 26.6829C21.1562 25.9733 21.1562 25.2267 21.3313 24.5172Z" fill="white" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                                </svg>    
                            </div>
                            <div class="text-[#8C8C8C] text-center text-xs">попередній<br>елемент</div>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                            <div @click="nextElement">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26" cy="26" r="24.6154" fill="#1B4079" stroke="#474849" stroke-width="2.76923"/>
                                <path d="M30.8 19.6C30.8 17.9029 30.8 17.0544 31.3273 16.5272C31.8545 16 32.703 16 34.4 16C36.0971 16 36.9456 16 37.4728 16.5272C38 17.0544 38 17.9029 38 19.6V31.6C38 33.2971 38 34.1456 37.4728 34.6728C36.9456 35.2 36.0971 35.2 34.4 35.2C32.703 35.2 31.8545 35.2 31.3273 34.6728C30.8 34.1456 30.8 33.2971 30.8 31.6V19.6Z" fill="white" stroke="white" stroke-width="1.5"/>
                                <path d="M30.6687 26.6828C30.2446 28.4019 28.24 29.6167 24.2309 32.0462C20.3553 34.3948 18.4174 35.5691 16.8558 35.0971C16.2102 34.9019 15.6219 34.5313 15.1475 34.0207C14 32.7858 14 30.3905 14 25.6C14 20.8095 14 18.4142 15.1475 17.1793C15.6219 16.6687 16.2102 16.2981 16.8558 16.1029C18.4174 15.6309 20.3553 16.8052 24.2309 19.1538C28.24 21.5833 30.2446 22.7981 30.6687 24.5172C30.8438 25.2267 30.8438 25.9733 30.6687 26.6828Z" fill="white" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="text-[#8C8C8C] text-center text-xs">наступний<br>елемент</div>
                        </div>
                    </div>
                </div>
            </div>
            <button v-if="addToTrainingFlag" @click="$emit('addSummary', summary)" class="bg-[#339989] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
                <div class="w-full">Додати до тренування</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed, watch } from 'vue'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import YouTube from 'vue3-youtube'

    export default {
        name: 'TrainingSummaryView',

        props: {
            summary: {
                type: Object,
                required: true
            },
            addToTrainingFlag: {
                type: Boolean,
                required: true
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL)

            const selectedSummary = ref(props.summary)

            const elements = ref(selectedSummary.value.elements)

            const currentElement = ref(elements.value[0])

            const totalDuration = ref(0)

            const trainingProccess = ref(0)
            const currentElementTime = ref(0)

            const startedTraining = ref(false)

            const currentElementIndex = ref(0)
            const showElementInfoFull = ref(false)

            const endTrainingFlag = ref(false)

            const intervalTimer = ref(null)

            watch(() => currentElementTime.value, (newValue) => {
                if (currentElementIndex.value === elements.value.length) {
                    stopTraining()
                }
                
                if (newValue >= elements.value[currentElementIndex.value].time * 60) {
                    currentElementIndex.value += 1
                    currentElementTime.value = 0
                }
            })

            const parsedDuration = computed(() => {
                return Math.floor(totalDuration.value / 60) + ' год ' + totalDuration.value % 60 + ' хв'
            })

            const trainingProccessProcent = computed(() => {
                return (trainingProccess.value / (totalDuration.value * 60)) * 100
            })

            const addOneMinuteToElement = () => {
                elements.value[currentElementIndex.value].time = +elements.value[currentElementIndex.value].time + 1
                totalDuration.value += 1
                countWidthOfElements()
            }

            const addFiveMinuteToElement = () => {
                elements.value[currentElementIndex.value].time = +elements.value[currentElementIndex.value].time + 5
                totalDuration.value += 5
                countWidthOfElements()
            }
            
            const addTenMinuteToElement = () => {
                elements.value[currentElementIndex.value].time = +elements.value[currentElementIndex.value].time + 10
                totalDuration.value += 10
                countWidthOfElements()
            }

            const nextElement = () => {
                if (currentElementIndex.value === elements.value.length - 1) {
                    return
                } 
                currentElementIndex.value += 1
            }

            const previousElement = () => {
                if (currentElementIndex.value === 0) {
                    return
                } 
                currentElementIndex.value -= 1
            }

            const startTraining = () => {
                startedTraining.value = true

                intervalTimer.value = setInterval(() => {
                    trainingProccess.value += 1
                    currentElementTime.value += 1
                }, 1000);
            }

            const stopTraining = () => {
                trainingProccess.value = totalDuration.value * 60
                startedTraining.value = false
                endTrainingFlag.value = true

                clearInterval(intervalTimer.value)
            }

            const countWidthOfElements = () => {
                elements.value = elements.value.map(el => {
                    el.width = (el.time / totalDuration.value) * 100
                    return el
                })
            }

            onMounted(() => {
                totalDuration.value = elements.value.reduce((acc, el) => acc + +el.time, 0)

                elements.value = elements.value.map(el => {
                    el.width = (el.time / totalDuration.value) * 100
                    return el
                })
            })

            return {
                selectedSummary,
                elements,
                totalDuration,
                parsedDuration,
                startedTraining,
                trainingProccessProcent,
                startTraining,
                currentElementIndex,
                showElementInfoFull,
                apiURL,
                addOneMinuteToElement,
                addFiveMinuteToElement,
                addTenMinuteToElement,
                nextElement,
                stopTraining,
                endTrainingFlag,
                currentElement,
                previousElement
            }
        },
        
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            YouTube
        },
    }
</script>

<style lang="scss" scoped>

</style>