<template>
  <div class="testing-tab">

    <!-- <div class=" text-center text-gray-400">
      В розробці
    </div> -->
    <TableWrapper>
      <TableComponent>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell v-for="item in header" :key="item" :value="item" />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(row, index) in standartResults" :key="index">
            <TableCell :value="row.skill" />
            <TableCell :asChild="true">
              <div class="testing-tab__results-cell w-[300px]">
                <div v-for="(result, index) in row.results" :key="index">
                  <div class="testing-tab__value">{{ result.value }}</div>
                  <div class="testing-tab__date">{{ result.date }}</div>
                </div>
              </div>
            </TableCell>
            <!-- <TableCell :asChild="true" :width="50"> -->
              <!-- <div class="testing-tab__rating-cell">
                {{ row.rating }}
              </div> -->
            <!-- </TableCell> -->
            <TableCell :asChild="true">
              <LineChart :values="row.results.map(e => +e.value)" />
            </TableCell>
          </TableRow>
        </TableBody>
      </TableComponent>
    </TableWrapper>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect } from "vue";
import { getAllStandardsRequest } from '@/desktopApp/services/apiRequests'
import graph from "@/desktopApp/assets/refactored_assets/img/players/graph.png";
import LineChart from "../widgets/LineChart.vue";
import * as echarts from "echarts";

import { getAllStudentStandardsByStudentId } from '@/desktopApp/services/apiRequests'
import { all } from "axios";

function groupById(arr) {
    return arr.reduce((acc, obj) => {
        // Если группа с таким id еще не существует, создаем ее
        if (!acc[obj.base_standard_id]) {
            acc[obj.base_standard_id] = [];
        }
        // Добавляем объект в соответствующую группу
        acc[obj.base_standard_id].push(obj);
        return acc;
    }, {});
}

export default {
  name: "TestingTab",
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      graph: graph,
      header: ["назва нормативу", "результат", "динаміка"],
      rows: [
        {
          id: 1,
          skill: "Cтрибок у довжину з місця (см)",
          results: [
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
          ],
          rating: 25,
          dynamic: null,
        },
        {
          id: 1,
          skill: "Cтрибок у довжину з місця (см)",
          results: [
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
          ],
          rating: 5,
          dynamic: null,
        },
        {
          id: 1,
          skill: "Cтрибок у довжину з місця (см)",
          results: [
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
          ],
          rating: 5,
          dynamic: null,
        },
        {
          id: 1,
          skill: "Cтрибок у довжину з місця (см)",
          results: [
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
            {
              value: 200,
              date: "01.01.2023",
            },
          ],
          rating: 5,
          dynamic: null,
        },
      ],
    };
  },

  setup(props) {
    const standards = ref([]);

    const standartResults = ref([]);

    const chartContainer = ref(null);

    const allBaseStandards = ref([]);

    watchEffect(() => {

    });

    onMounted(async () => {
      console.log('props.student.student_id', props.student);
      const res = await getAllStudentStandardsByStudentId(props.student.student_id);
      allBaseStandards.value = await getAllStandardsRequest();
      console.log(allBaseStandards.value);
      standards.value = res;

      const standardsByGroup = groupById(standards.value)

      for(let baseStandardId in standardsByGroup) {
        if (allBaseStandards.value.find(standard => standard.id === baseStandardId) === undefined) {
          continue;
        }
        const results = standardsByGroup[baseStandardId].map(standard => {
          console.log('standard.report_id', standard.report_id)
          return {
            value: standard.result,
            date: new Date(+standard.report_id).toISOString().slice(0, 10).split('-').reverse().join('.'),
          }
        })

        const currentSkillName = allBaseStandards.value.find(standard => standard.id === baseStandardId).name;
        console.log('currentSkillName', currentSkillName);

        standartResults.value.push({
          skill: currentSkillName,
          results: results,
          rating: 5,
          dynamic: null,
        })
      }


    });

    return {
      standards,
      standartResults,
      chartContainer
    }
  },

  components: {
    LineChart,
  },
};
</script>
