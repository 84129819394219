<template>

<svg width="36" height="112" viewBox="0 0 36 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
        <path d="M3.33075 104.121H4.02998L3.09767 98.9934L2.63151 94.0988L2.39844 86.4073L3.33075 55.6411L4.96228 33.1492L6.36075 22.6607L8.3419 12.5218L10.09 7.27762L12.4207 2.26646L15.1011 1.21761L18.1311 1.10107L21.2777 1.21761L23.8415 2.26646L26.2888 7.27762L28.0369 12.5218L30.0181 22.6607L31.4165 33.1492L33.0481 55.6411L33.9804 86.4073L33.7473 94.0988L33.2811 98.9934L32.3488 104.121H33.0481V110.88H3.33075V104.121Z" fill="white" class="color_changed_fill" stroke="black" stroke-width="0.910275" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.605 13.2214L12.5373 11.9395L12.7704 12.4056V15.6687L12.3042 16.0183L11.8381 15.7852V14.0371L11.605 13.2214Z" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.4214 13.5698V14.8517L12.0718 15.0848" stroke="black" stroke-width="0.233077"/>
        <path d="M25.0073 13.2214L24.075 11.9395L23.8419 12.4056V15.6687L24.3081 16.0183L24.7742 15.7852V14.0371L25.0073 13.2214Z" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.1914 13.5718V14.8537L24.541 15.0868" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.9834 21.0293L16.1488 23.5931L17.4307 24.5255" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5479 22.4272L18.3636 22.8934L19.2959 22.4272" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.5591 19.8628L20.811 22.077" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.9091 21.3799L20.6948 23.7107" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.61182 80.4644L6.12682 59.604L11.371 61.119L18.4799 60.6529H26.4045L28.9684 60.0702L30.4834 59.604L32.348 80.4644L22.0926 84.6598L20.5776 75.5698L18.4799 70.9082L16.3822 75.5698L14.8672 84.6598L4.61182 80.4644Z" fill="black" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.3041 83.6094L11.1387 85.7071L9.39062 86.4063" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M10.5552 85.9419L10.0891 87.2238L8.45752 89.205" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M9.39065 88.1558L9.8568 96.3135L8.9245 99.9262L7.99219 104.122" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.96194 80.6978L4.61233 84.3104L3.68002 88.3893L3.21387 93.1674L4.26271 101.092L4.84541 104.122" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M3.6663 89.7313C3.63602 89.6745 3.56543 89.653 3.50863 89.6833C3.45184 89.7136 3.43036 89.7842 3.46065 89.841L3.6663 89.7313ZM5.77771 92.6996L5.69932 92.7858L5.71121 92.7966L5.72559 92.8038L5.77771 92.6996ZM7.64232 93.6319L7.59021 93.7361L7.61178 93.7469L7.63586 93.7483L7.64232 93.6319ZM4.49578 91.5342L4.39296 91.5891L4.40246 91.6069L4.41739 91.6204L4.49578 91.5342ZM5.72559 92.8038L7.59021 93.7361L7.69444 93.5277L5.82983 92.5954L5.72559 92.8038ZM7.63586 93.7483L9.73355 93.8648L9.74648 93.6321L7.64879 93.5155L7.63586 93.7483ZM3.46065 89.841L4.39296 91.5891L4.59861 91.4794L3.6663 89.7313L3.46065 89.841ZM4.41739 91.6204L5.69932 92.7858L5.8561 92.6134L4.57418 91.448L4.41739 91.6204Z" fill="black"/>
        <path d="M3.43349 92.1788C3.40279 92.1222 3.33205 92.1012 3.27548 92.1319C3.21891 92.1626 3.19793 92.2334 3.22862 92.29L3.43349 92.1788ZM5.94152 95.5557L5.86467 95.6433L5.87765 95.6547L5.89338 95.6619L5.94152 95.5557ZM7.84003 96.4168L7.7919 96.5229L7.80818 96.5303L7.82593 96.5325L7.84003 96.4168ZM4.39897 94.2026L4.29654 94.2582L4.30647 94.2765L4.32212 94.2902L4.39897 94.2026ZM5.89338 95.6619L7.7919 96.5229L7.88817 96.3107L5.98965 95.4496L5.89338 95.6619ZM7.82593 96.5325L9.8431 96.7785L9.87132 96.5472L7.85414 96.3011L7.82593 96.5325ZM3.22862 92.29L4.29654 94.2582L4.5014 94.147L3.43349 92.1788L3.22862 92.29ZM4.32212 94.2902L5.86467 95.6433L6.01837 95.4681L4.47582 94.115L4.32212 94.2902Z" fill="black"/>
        <path d="M4.96257 84.3086L5.07911 86.4063L4.72949 87.5717V89.0867" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M5.54492 87.3389C5.73138 87.4321 5.93338 87.6108 6.01108 87.6885" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M8.45803 87.2236L7.40918 88.1559" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M5.19482 87.6885L6.24367 88.2712" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M24.4244 83.7266L25.5898 85.7077L27.3379 86.4069" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M26.1713 85.9419L26.6375 87.2238L28.269 89.0884" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M27.3379 88.0376L26.8717 96.3118L27.804 99.9245L28.7363 104.12" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.8831 80.6978L32.1162 84.3104L33.0485 88.3893L33.5146 93.1674L32.4658 101.092L31.8831 104.122" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.1646 90.3701L32.2322 91.5355L30.9503 92.7009L29.0857 93.6332L27.1045 93.7497" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.3965 92.4678L32.3476 94.4489L30.8326 95.7309L28.968 96.5466L26.9869 96.7797" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.7669 84.3091L31.6504 86.4068L32 87.5722V89.0872" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.1836 87.3398C30.9971 87.4331 30.7951 87.6118 30.7174 87.6895" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M28.2695 87.2236L29.3184 88.1559" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.5327 87.6885L30.4839 88.2712" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.52493 60.0693L5.77686 80.9297" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M28.9682 60.0693L30.7163 81.0463" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.12798 59.603L6.71067 56.8061L7.87606 40.6072L7.29336 33.7314V40.2576L7.17683 41.7726L6.47759 42.938L5.8949 45.2688L4.72952 47.7161V50.9791L3.33105 56.3399" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.96289 33.7316C5.58443 31.6339 6.94405 27.4152 6.94405 27.322C6.94405 27.2288 9.35251 25.6516 10.5567 24.8747L13.8198 22.8936" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M30.4835 59.603L29.9008 56.8061L28.7354 40.6072L29.3181 33.7314V40.2576L29.4347 41.7726L30.1339 42.938L30.7166 45.2688L31.882 47.7161V50.9791L33.0474 56.3399" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.416 33.4986C30.7945 31.4009 29.6679 27.4152 29.6679 27.322C29.6679 27.2288 27.2595 25.6516 26.0552 24.8747L22.7922 22.8936" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.4683 40.2438L7.82672 39.3335V40.6989L7.37158 48.4362L18.2949 49.8017L29.2182 48.4362L28.763 40.2438V39.3335L18.4683 40.2438Z" fill="black" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M28.6191 42.1001L18.4803 42.7993" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.3645 26.8562L23.7252 23.4766L24.8906 24.1758L18.3645 28.4877L11.7218 24.1758L12.8872 23.4766L18.3645 26.8562Z" fill="black" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.771 11.356V17.9987L16.5002 21.0287H20.346L23.8422 17.9987V11.356" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.4696 9.9573L13.0034 12.6377L12.0711 10.54L11.605 9.02499L13.0034 6.81076L13.4696 8.09269L14.635 9.02499L13.4696 9.9573Z" fill="black"/>
        <path d="M18.9469 8.32576C18.6672 8.32576 16.4219 8.55884 15.3342 8.67538L14.0523 7.62653L13.7027 6.22807L14.2854 5.06269L16.6161 4.13038L16.0334 4.82961L18.5973 3.78076L18.2477 4.47999L19.8792 4.13038L20.5784 4.82961L21.3942 4.13038L22.0934 4.82961L23.725 6.22807L25.1234 8.32576C25.2011 9.06384 25.3798 10.6099 25.4731 10.8896C25.5663 11.1693 25.2788 12.1715 25.1234 12.6377L24.5408 12.1715L23.9581 12.6377L23.725 10.3069L23.1423 9.37461C21.8604 9.02499 19.2266 8.32576 18.9469 8.32576Z" fill="black"/>
        <path d="M13.4696 9.9573L13.0034 12.6377L12.0711 10.54L11.605 9.02499L13.0034 6.81076L13.4696 8.09269L14.635 9.02499L13.4696 9.9573Z" stroke="black" stroke-width="0.233077" stroke-linejoin="round"/>
        <path d="M18.9469 8.32576C18.6672 8.32576 16.4219 8.55884 15.3342 8.67538L14.0523 7.62653L13.7027 6.22807L14.2854 5.06269L16.6161 4.13038L16.0334 4.82961L18.5973 3.78076L18.2477 4.47999L19.8792 4.13038L20.5784 4.82961L21.3942 4.13038L22.0934 4.82961L23.725 6.22807L25.1234 8.32576C25.2011 9.06384 25.3798 10.6099 25.4731 10.8896C25.5663 11.1693 25.2788 12.1715 25.1234 12.6377L24.5408 12.1715L23.9581 12.6377L23.725 10.3069L23.1423 9.37461C21.8604 9.02499 19.2266 8.32576 18.9469 8.32576Z" stroke="black" stroke-width="0.233077" stroke-linejoin="round"/>
        <path d="M17.0813 11.9391C16.988 11.9391 16.1101 11.7837 15.6828 11.7061L14.634 11.8226L13.7017 12.4053" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.3324 12.4053C14.8662 12.4053 14.3612 12.8714 14.167 13.1045L14.7497 13.3376L15.4489 13.4541L16.1481 13.1045L16.4978 12.6384C16.3035 12.5607 15.7985 12.4053 15.3324 12.4053Z" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.9834 12.755L15.2165 12.522H15.6826V12.755V12.9881L15.4496 13.1047H15.2165L14.9834 12.9881V12.755Z" stroke="black" stroke-width="0.233077"/>
        <path d="M19.4124 11.9391C19.5056 11.9391 20.3835 11.7837 20.8108 11.7061L21.8597 11.8226L22.792 12.4053" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.1608 12.4053C21.6269 12.4053 22.1319 12.8714 22.3262 13.1045L21.7435 13.3376L21.0442 13.4541L20.345 13.1045L19.9954 12.6384C20.1896 12.5607 20.6946 12.4053 21.1608 12.4053Z" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.5098 12.755L21.2767 12.522H20.8105V12.755V12.9881L21.0436 13.1047H21.2767L21.5098 12.9881V12.755Z" stroke="black" stroke-width="0.233077"/>
        <path d="M20.3448 18.1159H16.1494L17.8975 17.8828L18.2471 17.9994L18.7133 17.8828L20.3448 18.1159Z" fill="black" stroke="black" stroke-width="0.233077" stroke-linejoin="round"/>
        <path d="M19.8788 18.5815H16.6157L18.2473 18.8146L19.8788 18.5815Z" stroke="black" stroke-width="0.233077" stroke-linejoin="round"/>
        <path d="M18.9463 12.9888V16.2518" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M17.4307 13.9204V16.2512" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M16.8495 15.4351L16.3833 16.0178C16.4221 16.1343 16.5231 16.3674 16.6164 16.3674C16.7096 16.3674 17.1991 16.3674 17.4321 16.3674L17.8983 16.4839H18.2479H18.481L18.9471 16.3674H19.8795L19.996 16.0178L19.6464 15.6681" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.63154 80.5796L1 81.8615V86.7561L2.39846 87.9215" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.8636 80.5796L35.4951 81.8615V86.7561L33.9801 88.1546" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.8193 22.8935L13.4697 18.5815" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.7925 22.8935L23.1421 18.5815" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.02881 104.122H32.3477" stroke="black" stroke-width="0.910275" stroke-linecap="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1014 41.1576H17.9332V42.5113L18.9776 41.1533H19.8737L17.9332 43.6289V44.6157L20.7082 41.1555H21.5871L19.2838 44.1719L21.936 48.0883L21.0391 48.0864L18.7401 44.7274L18.3235 45.2362L20.1968 48.0883L19.2206 48.0864L17.9332 46.1242V48.0854H17.1014V44.8674L17.0288 44.7467L16.4146 45.6453V48.0854H15.5571V41.1533H16.3932V44.5107L17.1014 43.6044V41.1576Z" fill="white" class="color_changed_fill" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6714 41.2231H15.372V41.3506H14.8872V41.5144H15.2722V41.6419H14.8872V41.8878H14.6714V41.2231Z" fill="white" class="color_changed_fill"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6616 43.4985H14.871V43.8012L15.1735 43.4985H15.3787L15.072 43.7914L15.3829 44.1589H15.1649L14.9469 43.8848L14.871 43.9528V44.1589H14.6616V43.4985Z" fill="white" class="color_changed_fill"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8482 44.4194H15.1957C15.3159 44.4194 15.3901 44.4739 15.3901 44.5954V44.9336C15.3901 45.0171 15.3359 45.0991 15.1957 45.0991H14.8482C14.7603 45.0991 14.6538 45.0601 14.6538 44.9288V44.5971C14.6538 44.4911 14.7071 44.4194 14.8482 44.4194ZM14.8504 44.6025V44.9177C14.8504 44.9543 14.8722 44.9759 14.917 44.9759H15.1346C15.1774 44.9759 15.1957 44.9513 15.1957 44.9123V44.6049C15.1957 44.5648 15.1695 44.5426 15.1341 44.5426H14.9119C14.8745 44.5426 14.8504 44.5674 14.8504 44.6025Z" fill="white" class="color_changed_fill" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8482 46.3472H15.1957C15.3159 46.3472 15.3901 46.4017 15.3901 46.5232V46.8613C15.3901 46.9448 15.3359 47.0268 15.1957 47.0268H14.8482C14.7603 47.0268 14.6538 46.9878 14.6538 46.8566V46.5249C14.6538 46.4188 14.7071 46.3472 14.8482 46.3472ZM14.8504 46.5303V46.8454C14.8504 46.8821 14.8722 46.9036 14.917 46.9036H15.1346C15.1774 46.9036 15.1957 46.879 15.1957 46.84V46.5326C15.1957 46.4926 15.1695 46.4703 15.1341 46.4703H14.9119C14.8745 46.4703 14.8504 46.4951 14.8504 46.5303Z" fill="white" class="color_changed_fill" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7114 46.0566H15.3263V45.9335H14.9101V45.3877H14.7114V46.0566Z" fill="white" class="color_changed_fill" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8781 42.0669H15.3735V42.1901H14.9737C14.9069 42.1901 14.8888 42.2114 14.8888 42.2657V42.5441C14.8888 42.6142 14.9251 42.6179 14.9718 42.6169C15.1064 42.6141 15.241 42.6091 15.3757 42.602V42.7219C15.226 42.7275 15.0756 42.7354 14.9266 42.7387C14.6998 42.7436 14.6665 42.6553 14.6665 42.5591V42.2442C14.6665 42.1609 14.718 42.0669 14.8781 42.0669Z" fill="white" class="color_changed_fill" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.875 47.3169H15.3019V47.4422H14.9577C14.8912 47.4422 14.8729 47.4635 14.8728 47.5176C14.8728 47.577 14.9025 47.589 14.9577 47.589H15.2014C15.2676 47.589 15.3661 47.6216 15.3661 47.7556V47.7941C15.3661 47.8903 15.3586 47.9957 15.1317 47.9908C14.9827 47.9876 14.841 47.9796 14.6913 47.974V47.8541C14.8259 47.8612 14.952 47.8663 15.0866 47.8691C15.1332 47.8701 15.1724 47.8663 15.1717 47.7962L15.1709 47.7207H14.875C14.7299 47.7207 14.6846 47.64 14.6774 47.5629C14.6745 47.5328 14.6744 47.4969 14.6782 47.4667C14.6878 47.3921 14.735 47.3169 14.875 47.3169Z" fill="white" class="color_changed_fill"/>
    </g>
</svg>



</template>

<script>
    export default {
        name: 'BaloonPlayer',
        props: {
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Avenir';
        src: url('@/desktopApp/assets/fonts/AvenirLTStd-Black.otf');
    }
</style>