<template>
    <div class="fixed z-[300] w-full h-full backdrop-blur-md"></div>
    <FormBlock class="match-event-form">
        <div class="match-event-form__title">Додати заміну</div>
        <div class="match-event-form__time">
            <div class="input-component__label">введіть хвилини</div>
            <div class="input-component__field time-field">
                <input 
                    type="number"
                    class="text-center w-full"
                    v-model="newGoal.time"
                />
            </div>
        </div>

        <div v-if="whoReleasedGoal" class="match-event-form__our-goal-block">

            <div>
                <SelectComponent 
                    class="text-[#DA4167]"
                    :label="'покинув поле'" 
                    :options="teamStartNames" 
                    :name="'покинув поле'" 

                    :default="newGoal.data.player1.name"
                    @change="e => newGoal.data.player1.name = e"
                />
            </div>
            <div class="mt-[30px]">
                <SelectComponent
                    class="text-[#41BF74]" 
                    :label="'вийшов на поле'" 
                    :options="teamReserveNames" 
                    :name="'вийшов на поле'" 

                    :default="newGoal.data.player2.name"
                    @change="e => newGoal.data.player2.name = e"
                />
            </div>

            <div class="match-event-form__details">
                <img src="@/desktopApp/assets/icons/swipe_icon.svg" class="w-[60px] mt-[30px]">
                <div>{{ newGoal.time }}’’</div>
                <div>покинув поле - {{ newGoal.data.player1.name }}</div>
                <div>вийшов на поле - {{ newGoal.data.player2.name }}</div>
            </div>

        </div>
        <div v-else class="h-[234px] flex justify-center">
            <img src="@/desktopApp/assets/icons/red_ball_icon.svg" class="w-[60px]">
        </div>

        <div class="match-event-form__buttons">
            <ButtonComponent @click="save">
                Зберегти
            </ButtonComponent>
            <ButtonComponent @click="$emit('close')">
                Відмінити
            </ButtonComponent>
        </div>

    </FormBlock>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'GoalForm',

    props: {
        startTeam: {
            type: Array,
            default: () => []
        },
        reserveTeam: {
            type: Array,
            default: () => []
        }
    },

    setup(props, { emit }) {
        const whoReleasedGoal = ref(true);
        const isPenalty = ref(false);

        const teamStartNames = ref(props.startTeam.map(player => player.name))
        const teamReserveNames = ref(props.reserveTeam.map(player => player.name))

        const newGoal = ref(
            {
                id: new Date().getTime(),
                type: 'SwipeEvent',
                time: 0,
                data: {
                    player1: {
                        name: 'Не вибрано'
                    },
                    player2: {
                        name: 'Не вибрано'
                    }
                }
            }
        )

        const save = () => {
            emit('save', newGoal.value)
        }

        return {
            whoReleasedGoal,
            isPenalty,
            newGoal,
            teamStartNames,
            teamReserveNames,
            save
        }
    }
}
</script>

<style>
    
</style>