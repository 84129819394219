<template>
    <div class="flex items-end -z-10 h-[217px]">
        <div class="w-full">
            <div class="ml- flex items-end">
                <!-- <div class="max-w-[200px]">
                    <img :src="trainerPhoto" class="logo -z-10">
                </div> -->

                <div class="w-[230px] h-[190px] overflow-hidden flex items-end">
                    <img :src="trainerPhoto" class="object-contain w-full h-full" alt="Your Image">
                </div>
            </div>    

            <div class="text-start absolute top-[50px] right-0 flex flex-col justify-center align-middle items-end pr-[20px]">
                <div>
                    <div class="text-[#DA4167] text-end">
                        <span class="font-bold text-[28px]">{{ store.student_balance.toFixed(2) }}</span> <span class="font-bold">грн.</span>
                    </div>
                    <div class="text-[#9B9B9B] text-end text-[10px] leading-[0]">
                        Учнівський баланс
                    </div>
                </div>
                <div class="mt-2">
                    <div class="text-[#DA4167] text-end">
                        <span class="font-bold text-[28px]">{{ store.service_balance.toFixed(2) }}</span> <span class="font-bold">грн.</span>
                    </div>
                    <div class="text-[#9B9B9B] text-end text-[10px] leading-[0]">
                        Сервісний баланс
                    </div>
                </div>
                <div class="mt-6">
                    <button @click="$router.push('/statistics')" class="flex items-center border-[1px] border-[#BD9135] bg-transparent rounded-[6px] text-[#BD9135] px-3 py-1">
                        <svg class="mr-[5px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.66663" y="1.66663" width="6.66667" height="8.33333" rx="2" stroke="#BD9135" stroke-width="1.2"/>
                            <rect x="1.66663" y="13.3333" width="6.66667" height="5" rx="2" stroke="#BD9135" stroke-width="1.2"/>
                            <rect x="11.6666" y="10" width="6.66667" height="8.33333" rx="2" stroke="#BD9135" stroke-width="1.2"/>
                            <rect x="11.6666" y="1.66663" width="6.66667" height="5" rx="2" stroke="#BD9135" stroke-width="1.2"/>
                        </svg>

                        <div class="text-[14px]">мої показники</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from '../../stores/mainStore'
    import { onMounted, computed } from 'vue'

    export default {
        name: "trainer-preview", 

        setup() {
            const store = useMainStore()

            const trainerPhoto = computed(() => {
                if (store.trainer.photo) {
                    return process.env.VUE_APP_API_URL + 'static/' + store.trainer.photo
                } else {
                    return require('../../assets/student_photo_placeholder.svg')
                }
            })

            return {
                store,
                trainerPhoto
            }
        }
    }
</script>
