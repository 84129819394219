<template>
    <div class="overflow-y-auto mt-[10px]">
        <DeleteProgramFrom v-if="isDeleteFormVisible" :element="elementToDelete" @close="isDeleteFormVisible = false" @acceptDeleteElement="deleteElement"/>
        
        <div class="flex justify-between px-[84px]">
            <!-- <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 px-[10px] rounded-[6px]">
                <img src="@/desktopApp/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input v-model="searchQuery" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px] " placeholder="Пошук..." type="text">
            </div> -->

            <InputComponent v-model="searchQuery" placeholder="Пошук..." class="w-[250px]"/>

            <div class="w-max flex justify-end">
                <div class="ml-[20px] flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/desktopApp/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/desktopApp/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <!-- <div @click="$router.push('/create-training-program')" class="bg-[#BD9135] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Додати програму</span>
                </div> -->

                <div class="w-[228px]">
                    <ButtonComponent @click="$router.push('/create-training-program')" :fullWidth="true">Додати програму</ButtonComponent>
                </div>
            </div>
        </div>
        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[16px]">
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ trainingProgramsSum }}</span> програм
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ summariesWithMicrocycles }}</span> тренувань
            </div>
        </div>
        <keep-alive>
        <div class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                        <th class="px-3 py-2 w-[35%] text-start font-bold text-sm">Назва програми тренуваннь</th>
                        <th class="px-3 py-2 w-[20%] text-center font-bold text-sm">К-сть трен.</th>
                        <th class="w-[15%] py-2 cursor-pointer select-none text-end font-bold text-sm">
                            Додані групи
                        </th>
                        <th class="w-[5%]" ></th>
                        <th class="px-3 py-2 text-end w-[204px] relative font-bold text-sm">
                            <div class="cursor-pointer w-[178px] z-[-40] pr-[26px]">Редагувати</div>
                        </th>
                        <th class="px-3 w-[10%] py-2 text-end font-bold text-sm">ID програми</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="program in allTrainingProgram" :key="program.hash" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                            <div class="rounded-full border-[1px] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                <span>{{ program.name[0] }}</span>
                            </div>
                        </td>
                        <td class="px-3 py-2 w-[35%] text-start font-medium text-sm">{{ program.name }}</td>
                        <td class="px-3 py-2 w-[20%] text-end font-medium text-sm ">
                            <div class="w-full h-full flex justify-center">
                                <div v-if="program.trainings_count != null">{{ program.trainings_count }}</div>
                                <div v-else class="w-[30px] h-[10px] bg-slate-500 animate-pulse rounded-full"></div>
                            </div>
                        </td>
                        <td class="w-[15%] py-2 cursor-pointer select-none text-end font-medium text-sm">
                            --
                        </td>
                        <td class="w-[5%]" ></td>
                        <td class="px-3 py-2 text-end w-[204px] relative font-medium text-sm">
                            <div class="cursor-pointer w-[178px] z-[-40] pr-[26px]">

                                <button 
                                    @click="$router.push('/training-program/' + program.id)"
                                    class="py-1 rounded-[10px] text-sm font-medium"
                                    :class="{
                                        'px-2' : screenWidth > 1450,
                                        'px-[4px]' : screenWidth < 1450
                                    }"        
                                >
                                    <img 
                                        src="@/desktopApp/assets/icons/pen_icon.svg" 
                                        class="w-6 h-6"
                                    >
                                </button>
                                <button 
                                    class="py-1 rounded-[10px] ml-[20px] text-sm font-medium text-red-600"
                                    :class="{
                                        'px-2' : screenWidth > 1450,
                                        'px-[4px]' : screenWidth < 1450
                                    }"   
                                    @click="isDeleteFormVisible = true; elementToDelete = program"   
                                >
                                    <img 
                                        src="@/desktopApp/assets/icons/trash_icon.svg" 
                                        class="w-6 h-6"   
                                    >
                                </button>

                            </div>
                        </td>
                        <td class="px-3 w-[10%] py-2 text-end font-medium text-sm">#{{program.id.split('-')[0]}}...</td>
                    </tr>
                </tbody>
            </table>
        </div>
        </keep-alive>
    </div>
</template>

<script>
    import { getAllTrainingPrograms, deleteTrainingProgramById, countTrainingPrograms, countSummariesWithMicrocycleId, countSummariesInCurrentProgram } from '@/desktopApp/services/apiRequests'
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import DeleteProgramFrom from './components/DeleteProgramForm.vue'

    export default {
        name: 'TrainingPrograms',

        setup() {
            const allTrainingProgram = ref([])

            const isDeleteFormVisible = ref(false)
            const elementToDelete = ref({})

            const trainingProgramsSum = ref(0)
            const summariesWithMicrocycles = ref(0)

            const page = ref(1)
            const maxPage = ref(1)

            const searchQuery = ref('')

            const nextPage = () => {
                if (page.value < maxPage.value) {
                    page.value++;
                }
            }

            const prevPage = () => {
                if (page.value > 1) {
                    page.value--;
                }
            }

            watch(page, async () => {
                await updateTrainingPrograms()
            });

            watch(searchQuery, async () => {
                page.value = 1
                await updateTrainingPrograms()
            });

            const deleteElement = async (element) => {
                await deleteTrainingProgramById(element.id)
                await updateTrainingPrograms()
                isDeleteFormVisible.value = false
            }

            const updateTrainingPrograms = async () => {
                allTrainingProgram.value = (await getAllTrainingPrograms(20 * page.value, 20 * (page.value - 1), searchQuery.value)).map(el => {
                    el.hash = uuid.v4()
                    el.trainings_count = null
                    return el
                })
            }

            const countSummariesWithCurrentProgram = async (programId) => {
                return await countSummariesInCurrentProgram(programId)
            }

            onMounted(async () => {
                await updateTrainingPrograms()

                for (let i = 0; i < allTrainingProgram.value.length; i++) {
                    allTrainingProgram.value[i].trainings_count = await countSummariesInCurrentProgram(allTrainingProgram.value[i].id)
                    allTrainingProgram.value[i].hash = uuid.v4()
                }

                trainingProgramsSum.value = await countTrainingPrograms()

                maxPage.value = Math.ceil(trainingProgramsSum.value / 20)
                summariesWithMicrocycles.value = await countSummariesWithMicrocycleId()
            })

            return {
                allTrainingProgram,
                isDeleteFormVisible,
                elementToDelete,
                deleteElement,
                trainingProgramsSum,
                summariesWithMicrocycles,
                countSummariesWithCurrentProgram,
                page,
                maxPage,
                searchQuery,
                nextPage,
                prevPage
            }
        },

        components: {
            DeleteProgramFrom
        }
    }
</script>

<style lang="scss" scoped>

</style>