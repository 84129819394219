<template>
    <div class="px-[30px]">

        <div class="flex justify-end">
            <ButtonComponent @click="$router.push('/match-center/matches')">
                Назад
            </ButtonComponent>
        </div>
        <div class="flex justify-center mt-[20px] border-y-[1px] border-[#BD913533] items-center">
            <div class="w-[33.3333%] flex justify-center">
                <div class="match-report__banner" style="background-color: transparent; border: none;">
                    <div class="match-report__banner--title">
                        <div>{{ matchReport?.league }}</div>
                        <div>Тур {{ matchReport?.tour }}</div>
                    </div>

                    <div class="match-report__banner--teams">
                        <div class="match-report__banner--team">
                            <img :src="matchReport?.firstTeamLogo ? apiURL + 'static/' + matchReport?.firstTeamLogo : require('@/desktopApp/assets/kolos_team.svg')" class="w-max" alt="">
                            <div>{{ matchReport?.firstTeamName }}</div>
                        </div>
                        <div class="match-report__banner--score">
                            <div>{{ matchReport?.firstTeamScore }}:{{ matchReport?.secondTeamScore }}</div>
                            <div>{{ matchReport?.matchTime }}''</div>

                        </div>
                        <div class="match-report__banner--team">
                            <img :src="matchReport?.secondTeamLogo ? apiURL + 'static/' + matchReport?.secondTeamLogo : require('@/desktopApp/assets/kolos_team.svg')" class="w-max" alt="">
                            <div>{{ matchReport?.secondTeamName }}</div>
                        </div>
                    </div>

                    <div class="match-report__banner--info">
                        <div>({{ matchReport?.time }})</div>
                        <div>{{ matchReport?.stadium }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[15px] mb-[20px]">
            <div class="text-center font-bold">склад на матч</div>

            <div>
                <TableWrapper>
                    <TableComponent :fullWidth="true">
                        <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell :value="''" />
                            <TableHeaderCell :value="'амплуа'" />
                            <TableHeaderCell :value="'голів'" />
                            <TableHeaderCell :value="'автогол'" />
                            <TableHeaderCell :value="'асист'" />
                            <TableHeaderCell :value="'жовтих'" />
                            <TableHeaderCell :value="'черв.'" />
                            <TableHeaderCell :value="'хв на полі'" />
                            <TableHeaderCell :value="'заг. оцінка'" />
                            <TableHeaderCell :value="''" />
                        </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow  
                                v-for="player in startPlayers"
                            >
                                <TableCell :asChild="true" :width="50">
                                    <div class="text-start flex">
                                        <div class="text-[#C59014] font-bold w-[30px] text-end">
                                            {{ player.number }} 
                                        </div>
                                        <div class="text-[#339989] ml-[10px]">
                                            {{ player.name }}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.position || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_goals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_autogoals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_assist || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_yellow_card || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_red_card || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.goals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_average_score || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <button 
                                        class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                        @click="$router.push(`/player-mark/${player.playerId}/${matchId}`)"
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 20H21" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20H21" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>

                                </TableCell>
                            </TableRow>
                            <TableRow  
                                v-for="player in reserveTeam"
                            >
                                <TableCell :asChild="true" :width="50">
                                    <div class="text-start flex">
                                        <div class="text-[#FFFFFF99] font-bold w-[30px] text-end">
                                            {{ player.number }} 
                                        </div>
                                        <div class="text-[#FFFFFF99] ml-[10px]">
                                            {{ player.name }}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.position || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_goals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_autogoals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_assist || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_yellow_card || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_red_card || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.goals || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <div class="text-center">
                                        {{ player.player_average_score || '-' }}
                                    </div>
                                </TableCell>
                                <TableCell :asChild="true">
                                    <button 
                                        class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                        @click="$router.push(`/player-mark/${player.playerId}/${matchId}`)"
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 20H21" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20H21" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>

                                </TableCell>
                            </TableRow>
                            
                        </TableBody>
                    </TableComponent>
                </TableWrapper>
            </div>
        </div>

    </div>
</template>

<script>
import { getStudentById, getMatchReportById, getPlayerStatisticsByMatchReportIdAndPlayerId, getAdmin, updatePlayerStatisticsByMatchReportIdAndPlayerId } from '@/desktopApp/services/apiRequests';
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from "vue-router";

export default {
    name: 'PlayerMatchReportForm',
    
    props: {
        
    },

    setup(props, { emit }) {
        const route = useRoute();
        const router = useRouter();

        const matchId = route.params.match_id;

        const apiURL = process.env.VUE_APP_API_URL;

        const matchReport = ref(null);

        const startPlayers = ref([]);
        const reserveTeam = ref([]);

        onMounted(async () => {
            matchReport.value = await getMatchReportById(matchId);
            console.log('matchReport.value ----- ', matchReport.value);
            startPlayers.value = matchReport.value.startTeam;
            reserveTeam.value = matchReport.value.reserveTeam;

            for (let player of startPlayers.value) {
                const playerStatistics = await getPlayerStatisticsByMatchReportIdAndPlayerId(matchId, player.playerId);
                startPlayers.value = startPlayers.value.map(startPlayer => {
                    if (startPlayer.playerId == player.playerId) {
                        return {
                            ...startPlayer,
                            ...playerStatistics
                        }
                    }
                    return startPlayer;
                });
            }

            for (let player of reserveTeam.value) {
                const playerStatistics = await getPlayerStatisticsByMatchReportIdAndPlayerId(matchId, player.playerId);
                reserveTeam.value = reserveTeam.value.map(reservePlayer => {
                    if (reservePlayer.playerId == player.playerId) {
                        return {
                            ...reservePlayer,
                            ...playerStatistics
                        }
                    }
                    return reservePlayer;
                });
            }

            console.log('startPlayers.value ----- ', startPlayers.value);

        });

        return {
            matchReport,
            startPlayers,
            reserveTeam,
            matchId,
            apiURL
        }
    }
}
</script>

<style>
    
</style>

{
    balance_type: 'real',
    balance_id: '92f334b0-8fd5-4cd4-80d6-5597a62e9c0d',
    state: 0,
    label: 'Готівковий баланс адміна Andrij Kostenko'
},
{
    balance_type: 'real',
    balance_id: 'fa79a70b-d4eb-4302-ad42-651924065960',
    state: 0,
    label: 'Інвестиційний баланс адміна Andrij Kostenko'
},
