<template>
    <FileManager 
        v-if="fileViewFlag" 
        :filesObjects="documentsObjects" 
        :filesUrls="documentsUrls"
        :canDelete="true"
        @save="saveReport" 
        @close="fileViewFlag = false" 
        @delete="deleteFile"   
        @upload="uploadFile"
    />
    <FormBlock class="absolute w-[39vw] h-[83vh] top-[-230px] z-[999] left-[50%] translate-x-[-50%]"  >
        <div class="flex gap-3">
            <InputComponent 
                :label="'Назва документу'" 
                :placeholder="'Назва документу'" 
                :name="'Назва документу'"
                :negative="!validation.documentName"
                :value="documentName"
                @update:modelValue="e => documentName = e"
            />
            <InputComponent 
                :label="'Дата'" 
                :placeholder="'Дата'" 
                :name="'Дата'"
                :negative="!validation.date"
                :type="'datetime-local'"
                :value="date"
                @update:modelValue="e => date = e"
            />
        </div>

        <div>
            <InputUploadOnlyFile @upload="uploadFile" @view="fileViewFlag = true" label="Завантажити документ"/>
            <input 
                type="file" 
                class="hidden" 
                ref="file" 
                @change="e => addDocument(e)"
            >
        </div>

        <div class="h-[60vh] overflow-y-auto">
            <div v-for="(desc, id) in descriptions">
                <div class="flex select-none justify-end w-full relative top-[10px]">
                    <div class="justify-end flex w-full">
                        <svg @click="deleteDescription(desc)" class="cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill="#DA4167"/>
                            <path d="M3.76514 8.4707H8.00043L12.2357 8.4707" stroke="white" stroke-width="2"/>
                        </svg>
                    </div>
                </div>
                <TextArea 
                    :label="`Опис #${id + 1}`" 
                    :placeholder="`Опис #${id + 1}`"
                    :value="desc"
                    @update:modelValue="e => descriptions[id] = e"    
                />
            </div>
            <div @click="descriptions.push('')" class="flex select-none items-center gap-2 mt-[10px]">
                <div>
                    <svg class="cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill="#339989"/>
                        <path d="M3.76514 8.47266H8.00043L12.2357 8.47266" stroke="white" stroke-width="2"/>
                        <path d="M8 12.4707L8 8.23541L8 4.00012" stroke="white" stroke-width="2"/>
                    </svg>
                </div>
                <div class="text-[10px] text-[#BD9135]">Додати тестовий опис #{{ descriptions.length + 1 }}</div>
            </div>
        </div>

        <div class="w-full flex justify-center mt-[40px]">
            <ButtonComponent @click="saveReport" class="mx-auto">
                Зберегти
            </ButtonComponent>
        </div>


    </FormBlock>
</template>

<script>
import FileManager from './widgets/FileManager.vue';
import { getAdmin } from '@/desktopApp/services/apiRequests'
import { ref, onMounted, watch } from 'vue';

export default {
    name: 'MedicalReportForm',

    props: {
        report: {
            type: Object,
            default: () => {}
        }
    },  

    setup(props, { emit }) {
        const validation = ref({
            documentName: true,
            date: true,        
        })

        const documentName = ref(
            props.report?.document_name ? props.report?.document_name : ''
        );
        const date = ref(
            props.report?.date ? props.report?.date.split('.').reverse().join('-') + 'T' + props.report?.time : ''
        );

        const documentsObjects = ref(
            props.report?.documents ? 
                typeof props.report?.documents[0] == 'object' ? props.report?.documents : [] 
                : []
        );

        const documentsUrls = ref(
            props.report?.documents ? 
                typeof props.report?.documents[0] == 'string' ? props.report?.documents : [] 
                : []
        );

        const descriptions = ref(
            props.report?.descriptions ? props.report?.descriptions : []
        );
        const file = ref(null);

        const fileViewFlag = ref(false);

        const doctorName = ref(
            props.report?.doctor ? props.report?.doctor : ''
        );

        onMounted(async () => {
            const response = await getAdmin();
            console.log(props.report?.documents)
            console.log(documentsUrls.value)
            console.log(documentsObjects.value)
            doctorName.value = response.data.admin_name + ' ' + response.data.admin_surname;
        })

        const uploadFile = () => {
            file.value.click();
        }

        const addDocument = (e) => {
            const file = e.target.files[0];
            documentsObjects.value.push(file);
        }

        const deleteFile = (file) => {
            console.log('file ------- ', file.file);
            const index = documentsObjects.value.indexOf(file.file);
            documentsObjects.value.splice(index, 1);
        }

        const checkValidation = () => {
            let result = true;
            if (!documentName.value) {
                validation.value.documentName = false;
                result = false;
            }

            if (!date.value) {
                validation.value.date = false;
                result = false;
            }

            return result;
        }

        watch(() => documentName, () => {
            if (documentName.value) {
                validation.value.documentName = true;
            }
        })

        watch(() => date, () => {
            if (date.value) {
                validation.value.date = true;
            }
        })

        const saveReport = () => {
            const report = {
                id: props.report?.id ? props.report?.id : new Date().getTime(),
                document_name: documentName.value,
                date: date.value.split('T')[0].split('-').reverse().join('.'),
                time: date.value.split('T')[1],
                documentsObjects: documentsObjects.value,
                documentsUrls: documentsUrls.value,
                descriptions: descriptions.value,
                doctor: doctorName.value
            }

            if (checkValidation()) {
                emit('save', report);
            }
        }

        const deleteDescription = (desc) => {
            const index = descriptions.value.indexOf(desc);
            descriptions.value.splice(index, 1);
        }

        return {
            documentName,
            date,
            documentsObjects,
            deleteDescription,
            saveReport,
            uploadFile,
            addDocument,
            descriptions,
            fileViewFlag,
            file,
            deleteFile,
            checkValidation,
            validation,
            documentsUrls
        }
    },

    components: {
        FileManager
    }
}
</script>

<style>
    
</style>