<template>
    <div v-if="day.type != 'empty'" class="relative m-[2px] w-[160px] bg-[#010101] h-[140px] flex flex-col border-[1px] border-[#4E5568] rounded-[10px] group">
        <div 
            :class="{
                'text-gray-400' : day.weekDayId === 6 || day.weekDayId === 7,
                'text-[#BD9135]' : currentDay === today,
            }" 
            class="mx-[4px] mt-[2px] text-base align-middle w-6 h-6 rounded-full  text-[16px] text-center font-bold"
        >{{ day.dayNumber }}</div>
        <div class="flex flex-col px-1 overflow-auto w-full">
            <div class="w-full overflow-hidden h-[1] mt-[2px]">
                <button 
                    v-for="(training, id) in day.trainings.slice(0, 4)" 
                    :class="{
                        'border-[1px] rounded-[2px] border-[#1B4079] bg-[#1B4079] text-[#FFF]': id === 0 && training.status != 'missed' && training.status != 'completed',
                        'rounded-[2px] border-red-400 text-[#FFF] bg-red-400': training.status === 'missed',
                        'rounded-[2px] border-[#339989] text-[#FFF] bg-[#339989]': training.status === 'completed',
                        'bg-[#1B4079] border-[1px] rounded-[2px] border-[#1B4079] text-[#FFF]' : training.status != 'missed' && training.status != 'completed',
                        'rounded-[2px] border-[#BD9135] text-[#FFF] bg-[#BD9135]' : training.type === 'competition' || training.type === 'championship' || training.type === 'friendly-game'
                        }"
                    class="flex items-center w-[132px] h-[16px] flex-shrink-0 mb-[7px] pt-[2px] px-[4px] cursor-default border-[1px] mx-auto"
                >
                    <div v-if="training.type != 'trainer_meetup'" class="w-[40%] text-[12px] text-start font-bold leading-none truncate">{{ training.group_label }}</div>
                    <div v-if="training.type != 'trainer_meetup'" class="w-[30%] text-[12px] text-center font-light leading-none">{{ training.start_time }}</div>
                    <div v-if="training.type != 'trainer_meetup'" class="w-[30%] text-[12px] text-end font-light leading-none truncate">{{ training.location_label }}</div>
                    <div v-if="training.type == 'trainer_meetup'" class="w-[70%] text-[12px] text-start font-bold leading-none truncate">Нарада тренерів</div>
                    <div v-if="training.type == 'trainer_meetup'" class="w-[30%] text-[12px] text-center font-light leading-none">{{ training.start_time }}</div>
                </button>
            </div>
        </div>
        <div v-if="day.trainings.length >= 3" class="flex select-none justify-center h-[2px] relative top-[-18px] items-top">
            <!-- <div class="text-center align-top leading-none h-[2px] select-none">...</div> -->
        </div>
        <div class="flex justify-between absolute w-[100%] left-[50%] translate-x-[-50%] bottom-[-8px]">
            <button @click="$emit('viewFull')" class=" bottom-0 left-0 flex items-center justify-center w-6 h-6 mb-2 ml-2 text-white rounded group-hover:flex">
                <img src="@/desktopApp/assets/icons/new_open_icon.svg" class="w-10 h-5"> 
            </button>
            <button @click="$emit('addTraining', day)" class=" bottom-0 right-0 flex items-center justify-center w-6 h-6 mb-2 mr-2 text-white rounded group-hover:flex">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_36_669)"><path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00888 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667V14.6667ZM8.66667 7.33333H11.3333V8.66667H8.66667V11.3333H7.33334V8.66667H4.66667V7.33333H7.33334V4.66667H8.66667V7.33333Z" fill="#EFEFEF"/></g><defs><clipPath id="clip0_36_669"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
            </button>
        </div>
        <!-- <div class="flex justify-between absolute w-[90%] left-[50%] bg-[#252526] translate-x-[-50%] bottom-0 pt-2">
            <button @click="$emit('addTraining', day)" class=" bottom-0 right-0 flex items-center justify-center hidden w-6 h-6 mb-2 mr-2 text-white rounded group-hover:flex">
                <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
            </button>
            <button @click="$emit('viewFull')" class=" bottom-0 left-0 flex items-center justify-center hidden w-6 h-6 mb-2 ml-2 text-white rounded group-hover:flex">
                <img src="@/desktopApp/assets/icons/new_open_icon.svg" class="w-10 h-5"> 
            </button>
        </div> -->
    </div>
    <div v-else class="relative m-[2px] flex flex-col border-[1px] border-[#FFF] border-opacity-10 group rounded-[10px] w-[160px] h-[140px] bg-[#010101] blur-[1px] bg-opacity-10">
        <div 
            class="mx-[4px] my-1 text-base align-middle w-6 h-6 text-[#FFF] rounded-full text-[16px] text-center font-bold"
        >{{ day.month_day }}</div>
    </div>
</template>
<script>
    import { ref, onMounted, onUpdated } from 'vue'

    export default {
        name: 'new-day-card',

        props: {
            day: {
                type: [Object, null],
                required: true
            }
        },

        setup(props) {
            const currentDay = ref({})
            
            onMounted(() => {
                if (props.day.type != 'empty') {
                    currentDay.value = new Date(new Date(props.day.dateObj).setDate(props.day.dateObj.getDate() + 1)).toISOString().split('T')[0]
                    console.log(currentDay.value + ' === ' + new Date().toISOString().split('T')[0])
                    // currentDay.value = currentDay.value.toISOString().split('T')[0]
                }
            })

            onUpdated(() => {
                if (props.day.type != 'empty') {
                    currentDay.value = new Date(new Date(props.day.dateObj).setDate(props.day.dateObj.getDate() + 1)).toISOString().split('T')[0]
                    // currentDay.value = currentDay.value.toISOString().split('T')[0]
                }
            })

            return {
                today: new Date().toISOString().split('T')[0],
                currentDay
            }
        }

    }
</script>