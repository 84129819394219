<template>
    <div>
        <div @click="$router.push('/')" class="flex text-[#BD9135] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] border-[#fff] pb-2 text-white text-center w-[90%] mx-auto">
            {{ store.currentDayForFullView.split('-').reverse().join('.') }}
        </div>

        <div class="mt-[20px]">
            <div v-for="training in store.currentDayTrainings" @click="training.type != 'trainer_meetup' ? selectTraining(training) : ''" class="flex text-white items-center w-[90%] mx-auto mb-[20px]">
                <div class="mr-[10px]">
                    <img v-if="training.type == 'competition'" src="../assets/award_icon.svg" class="h-[35px] relative bottom-[8px]">
                    <img v-else-if="training.type == 'training'" src="../assets/training_icon.svg" class="h-[35px] relative bottom-[5px]">
                    <img v-else-if="training.type == 'trainer_meetup'" src="../assets/training_icon.svg" class="h-[35px] relative bottom-[5px]">
                    <img v-else-if="training.type == 'gym'" src="../assets/gym_icon.svg" class="h-[35px] relative bottom-[8px]">
                    <div v-else class="rounded-full border-[1px] h-[35px] w-[35px] right-[3px] relative flex justify-center items-center text-[12px]">
                        <span class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">404</span>
                    </div>
                </div>
                <div class="w-full border-b-[0.2px] border-[#fff] border-opacity-20 pb-[10px]">
                    <div v-if="training.type != 'trainer_meetup'" class="font-bold">{{ training.group_label }}</div>
                    <div v-else class="font-bold">Нарада тренерів</div>
                    <div class="flex justify-between w-full">
                        <div class="flex">
                            <div>{{ training.start_time }}</div>
                            <div class="mx-[5px]">></div>
                            <div>{{ training.end_time }}</div>
                        </div>
                        <div>{{ training.location_label }}</div>
                        <div class="flex">
                            <img v-if="training.type != 'trainer_meetup'" src="../assets/pen_icon.svg" class="h-[25px]">
                            <div 
                                class="h-[22px] w-[22px] ml-[10px] rounded-full"
                                :class="{
                                    'bg-green-500': training.status == 'completed',
                                    'bg-red-500': training.status == 'planed',
                                }"
                            ></div>
                            <!-- <img src="../assets/trash_icon.svg" class="h-[25px]"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <UpdateTrainingForm 
            class="absolute bottom-0 w-full"
            v-if="planedTrainingFlag"
            @close="planedTrainingFlag = false"
            @updateTrainings="$event => $router.push('/')"
            :selectedTraining = "planedTrainingToUpdate"
        />
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'
    import UpdateTrainingForm from '../components/NewTraining/UpdateTrainingForm.vue'

    export default {
        name: 'FullDayTrainingView',

        props: {
            date: {
                type: Date,
                required: true
            }
        },

        setup(props) {
            const store = useMainStore()
            const router = useRouter()

            const planedTrainingToUpdate = ref(null)
            const planedTrainingFlag = ref(false) 

            const selectTraining = (training) => {
                if (training.status == 'completed') {
                    store.currentCompletedTraining = training
                    router.push('/completed-training-view')
                } else if (training.status == 'planed') {
                    planedTrainingToUpdate.value = training.training_id
                    planedTrainingFlag.value = true
                }
            } 

            return {
                store,
                selectTraining,
                planedTrainingToUpdate,
                planedTrainingFlag
            }
        },

        components: {
            UpdateTrainingForm
        }
    }
</script>

<style lang="scss" scoped>

</style>