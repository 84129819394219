<template>
    <svg :width="width" :height="height">
      <rect v-for="(value, index) in data" :key="index" :x="columnX(index)" :y="columnY(value)" :width="columnWidth - columnSpacing" :height="columnHeight(value)" fill="#0ABFC2" />
      <text v-for="(label, index) in labels" :key="index" :x="labelX(index)" :y="labelY" text-anchor="middle" font-size="12px" fill="white">{{ label }}</text>
      <text v-for="(value, index) in data" :key="index" :x="columnX(index) + ((columnWidth - columnSpacing) / 2)" :y="columnY(value) - valueLabelOffset" font-size="12px" text-anchor="middle" fill="#0ABFC2">{{ value }}</text>
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      data: {
        type: Array,
        required: true,
      },
      labels: {
        type: Array,
        required: true,
      },
      width: {
        type: Number,
        default: 900,
      },
      height: {
        type: Number,
        default: 126,
      },
      margin: {
        type: Number,
        default: 30,
      },
      columnSpacing: {
        type: Number,
        default: 25,
      },
      valueLabelOffset: {
        type: Number,
        default: 10,
      },
    },
    computed: {
      columnWidth() {
        return (this.width) / this.data.length;
      },
      columnHeight() {
        const max = Math.max(...this.data);
        return value => (this.height - this.margin * 2) * (value / max) ? (this.height - this.margin * 2) * (value / max) : 1;
      },
      labelX() {
        return index => index * this.columnWidth + this.columnWidth / 2;
      },
      labelY() {
        return this.height - this.margin / 2;
      },
      columnX() {
        return index => index * this.columnWidth + this.columnSpacing / 2;
      },
      columnY() {
        return value => this.height - this.margin - this.columnHeight(value);
      },
    },
  };
  </script>
  